/* shadow */
/* sizing */
/* spacing */
.semi-divider {
  margin: 1px 0px 1px 0px;
  border-bottom: 1px solid var(--semi-color-border);
  color: var(--semi-color-text-0);
  box-sizing: border-box;
}
.semi-divider-dashed {
  border-bottom-style: dashed;
}
.semi-divider-horizontal {
  width: 100%;
  display: flex;
}
.semi-divider-vertical {
  border-bottom: 0;
  display: inline-block;
  margin: 0px 1px 0px 1px;
  border-left: 1px solid var(--semi-color-border);
  height: 20px;
  vertical-align: middle;
}
.semi-divider-with-text {
  display: flex;
  border-bottom: 0;
  white-space: nowrap;
  align-items: center;
}
.semi-divider-with-text .semi-divider_inner-text {
  font-weight: 600;
  padding: 0px 8px 0px 8px;
  display: inline-block;
}
.semi-divider-with-text::before, .semi-divider-with-text::after {
  content: "";
  width: 50%;
  border-bottom: 1px solid var(--semi-color-border);
}
.semi-divider-with-text-left::before {
  width: 40px;
}
.semi-divider-with-text-left::after {
  flex: 1;
}
.semi-divider-with-text-right::before {
  flex: 1;
}
.semi-divider-with-text-right::after {
  width: 40px;
}

.semi-divider-dashed::before, .semi-divider-dashed::after {
  border-bottom: 1px dashed var(--semi-color-border);
}

.semi-divider-vertical.semi-divider-dashed {
  border-left: 1px dashed var(--semi-color-border);
}