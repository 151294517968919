/* shadow */
/* sizing */
/* spacing */
.semi-autoComplete-option {
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  word-break: break-all;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  color: var(--semi-color-text-0);
  border-radius: 0px;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  transition: background-color var(--semi-transition_duration-none) var(--semi-transition_function-easeIn) var(--semi-transition_delay-none);
}
.semi-autoComplete-option-icon {
  width: 12px;
  color: transparent;
  visibility: hidden;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-content: center;
}
.semi-autoComplete-option-text {
  display: flex;
  flex-wrap: wrap;
  white-space: pre;
}
.semi-autoComplete-option-keyword {
  color: var(--semi-color-primary);
  background-color: inherit;
  font-weight: 600;
}
.semi-autoComplete-option:active {
  background-color: var(--semi-color-fill-1);
}
.semi-autoComplete-option-empty {
  cursor: not-allowed;
  color: var(--semi-color-disabled-text);
  justify-content: center;
}
.semi-autoComplete-option-empty:hover {
  background-color: inherit;
}
.semi-autoComplete-option-empty:active {
  background-color: inherit;
}
.semi-autoComplete-option-disabled {
  color: var(--semi-color-disabled-text);
  cursor: not-allowed;
}
.semi-autoComplete-option-disabled:hover {
  background-color: var(--semi-color-fill-0);
}
.semi-autoComplete-option-selected {
  font-weight: 600;
}
.semi-autoComplete-option-selected .semi-autoComplete-option-icon {
  visibility: visible;
  color: var(--semi-color-text-2);
}
.semi-autoComplete-option-focused {
  background-color: var(--semi-color-fill-0);
}
.semi-autoComplete-option:first-of-type {
  margin-top: 4px;
}
.semi-autoComplete-option:last-of-type {
  margin-bottom: 4px;
}

.semi-autocomplete {
  cursor: text;
  display: inline-flex;
  vertical-align: middle;
  box-sizing: border-box;
}
.semi-autocomplete-option-list {
  overflow-x: hidden;
  overflow-y: auto;
}
.semi-autocomplete-option-list-chosen .semi-autocomplete-option-icon {
  display: flex;
}
.semi-autocomplete-loading-wrapper {
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: not-allowed;
  height: 20px;
}
.semi-autocomplete-loading-wrapper .semi-spin {
  width: 100%;
}

.semi-rtl .semi-autocomplete,
.semi-portal-rtl .semi-autocomplete {
  direction: rtl;
}