/* shadow */
/* sizing */
/* spacing */
.semi-form .semi-form-field {
  box-sizing: border-box;
}
.semi-form-horizontal {
  display: flex;
  flex-wrap: wrap;
}
.semi-form-horizontal .semi-form-field {
  margin-left: 0;
  padding-right: 16px;
}
.semi-form-horizontal .semi-form-field:last-child {
  margin-right: 16px;
}
.semi-form-horizontal .semi-form-field-group {
  padding-right: 16px;
}
.semi-form-horizontal .semi-row {
  display: block;
}
.semi-form-vertical .semi-form-field {
  margin: 0;
  padding-top: 12px;
  padding-bottom: 12px;
  overflow: hidden;
}
.semi-form-vertical .semi-form-field .semi-form-col-right {
  display: flex;
  justify-content: flex-end;
}
.semi-form-vertical .semi-form-field .semi-form-col-left {
  display: flex;
  justify-content: flex-start;
}
.semi-form-vertical .semi-form-field-group .semi-form-col-right {
  display: flex;
  justify-content: flex-end;
}
.semi-form-vertical .semi-form-field-group .semi-form-col-left {
  display: flex;
  justify-content: flex-start;
}
.semi-form-field-label {
  box-sizing: border-box;
  font-weight: 600;
  color: var(--semi-color-text-0);
  margin-bottom: 4px;
  margin-top: 0px;
  padding-right: 16px;
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  flex-shrink: 0;
}
.semi-form-field-label-disabled {
  color: var(--semi-color-disabled-text);
}
.semi-form-field-label-with-extra .semi-form-field-label-text {
  display: inline-block;
}
.semi-form-field-label-with-extra .semi-form-field-label-extra {
  display: flex;
  align-items: center;
  margin-left: 4px;
}
.semi-form-field-label-required .semi-form-field-label-text::after {
  content: "*";
  margin-left: 4px;
  color: var(--semi-color-danger);
  font-weight: 600;
}
.semi-form-field-label-required-disabled {
  color: var(--semi-color-danger);
}
.semi-form-field-label-optional-text {
  color: var(--semi-color-tertiary);
}
.semi-form-field-label-left {
  text-align: left;
}
.semi-form-field-label-right {
  text-align: right;
}
.semi-form-field-error-message, .semi-form-field-help-text {
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  display: flex;
  align-items: center;
  margin-top: 4px;
}
.semi-form-field-error-message .semi-icon-alert_triangle, .semi-form-field-help-text .semi-icon-alert_triangle {
  color: var(--semi-color-warning);
}
.semi-form-field-error-message {
  color: var(--semi-color-danger);
}

.semi-form-field[x-label-pos=top] .semi-form-field-label {
  display: block;
}
.semi-form-field[x-label-pos=top] .semi-checkboxGroup,
.semi-form-field[x-label-pos=top] .semi-radioGroup {
  padding-top: 4px;
  padding-bottom: 4px;
}
.semi-form-field[x-label-pos=top] .semi-form-field-label-with-extra {
  display: flex;
  align-items: center;
}
.semi-form-field[x-label-pos=left] {
  display: flex;
}
.semi-form-field[x-label-pos=left] .semi-form-field-label {
  margin-bottom: 0;
  margin-right: 0;
  padding-top: 6px;
  padding-bottom: 6px;
}
.semi-form-field[x-label-pos=left] .semi-form-field-label-with-extra {
  display: flex;
  align-items: center;
}
.semi-form-field[x-label-pos=left] .semi-checkboxGroup,
.semi-form-field[x-label-pos=left] .semi-radioGroup {
  padding-top: 6px;
  padding-bottom: 6px;
}
.semi-form-field[x-label-pos=left] .semi-radioGroup-buttonRadio {
  padding-top: 0;
  padding-bottom: 0;
}
.semi-form-field[x-label-pos=left] .semi-switch,
.semi-form-field[x-label-pos=left] .semi-rating {
  vertical-align: middle;
  margin-top: 4px;
  margin-bottom: 4px;
}
.semi-form-field[x-extra-pos=middle] .semi-form-field-extra {
  margin-top: 4px;
  margin-bottom: 4px;
}
.semi-form-field[x-extra-pos=bottom] .semi-form-field-extra {
  margin-top: 4px;
}
.semi-form-field-main {
  width: 100%;
}
.semi-form-field-validate-status-icon {
  margin-right: 4px;
  flex-shrink: 0;
  align-self: flex-start;
  position: relative;
  top: 2px;
}
.semi-form-field-extra {
  color: var(--semi-color-tertiary);
}
.semi-form-field-extra-string {
  color: var(--semi-color-tertiary);
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.semi-form-vertical .semi-form-field-group {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 12px;
  padding-bottom: 12px;
  overflow: hidden;
}
.semi-form-vertical .semi-form-field-group .semi-form-field {
  margin-top: 0;
  margin-bottom: 0;
}

.semi-form-field-group[x-label-pos=left] {
  display: flex;
}
.semi-form-field-group[x-label-pos=left] .semi-form-field-label {
  margin-bottom: 0;
  margin-right: 0;
  padding-top: 6px;
  padding-bottom: 6px;
}

.semi-form-section {
  width: 100%;
  margin-top: 28px;
}
.semi-form-section-text {
  margin-block-start: 0;
  margin-block-end: 0;
  font-size: 18px;
  line-height: 24px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  width: 100%;
  padding-bottom: 8px;
  padding-top: 0px;
  border-bottom: 1px solid var(--semi-color-border);
  margin-bottom: 4px;
  margin-top: 0px;
  color: var(--semi-color-text-0);
}
.semi-form-section:nth-of-type(1) {
  margin-top: 0;
}

.semi-rtl .semi-form,
.semi-portal-rtl .semi-form {
  direction: rtl;
}
.semi-rtl .semi-form-horizontal .semi-form-field,
.semi-portal-rtl .semi-form-horizontal .semi-form-field {
  margin-left: 0;
  margin-right: 0;
  padding-right: 0;
  padding-left: 16px;
}
.semi-rtl .semi-form-horizontal .semi-form-field:last-child,
.semi-portal-rtl .semi-form-horizontal .semi-form-field:last-child {
  margin-left: 16px;
}
.semi-rtl .semi-form-horizontal .semi-form-field-group,
.semi-portal-rtl .semi-form-horizontal .semi-form-field-group {
  padding-right: 0;
  padding-left: 16px;
}
.semi-rtl .semi-form-field-label,
.semi-portal-rtl .semi-form-field-label {
  padding-right: 0;
  padding-left: 16px;
}
.semi-rtl .semi-form-field-label-with-extra .semi-form-field-label-extra,
.semi-portal-rtl .semi-form-field-label-with-extra .semi-form-field-label-extra {
  margin-left: 0;
  margin-right: 4px;
}
.semi-rtl .semi-form-field-label-required .semi-form-field-label-text::after,
.semi-portal-rtl .semi-form-field-label-required .semi-form-field-label-text::after {
  content: "*";
  margin-left: 0;
  margin-right: 4px;
}
.semi-rtl .semi-form-field-label-left,
.semi-portal-rtl .semi-form-field-label-left {
  text-align: right;
}
.semi-rtl .semi-form-field-label-right,
.semi-portal-rtl .semi-form-field-label-right {
  text-align: left;
}
.semi-rtl .semi-form-field,
.semi-portal-rtl .semi-form-field {
  direction: rtl;
}
.semi-rtl .semi-form-field[x-label-pos=left] .semi-form-field-label,
.semi-portal-rtl .semi-form-field[x-label-pos=left] .semi-form-field-label {
  margin-right: 0;
  margin-left: 0;
}
.semi-rtl .semi-form-field-validate-status-icon,
.semi-portal-rtl .semi-form-field-validate-status-icon {
  margin-right: 0;
  margin-left: 4px;
}
.semi-rtl .semi-form-section,
.semi-portal-rtl .semi-form-section {
  direction: rtl;
}