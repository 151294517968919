/* shadow */
/* sizing */
/* spacing */
.semi-dropdown {
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.semi-dropdown-wrapper {
  box-shadow: var(--semi-shadow-elevated);
  position: relative;
  z-index: 1050;
  border-radius: var(--semi-border-radius-medium);
  background: var(--semi-color-bg-3);
  opacity: 0;
}
.semi-dropdown-wrapper-show {
  opacity: 1;
}
.semi-dropdown-trigger {
  display: inline-block;
}
.semi-dropdown-menu {
  list-style: none;
  padding: 4px 0;
  margin: 0;
}
.semi-dropdown-title {
  color: var(--semi-color-text-2);
  padding-top: 8px;
  padding-bottom: 4px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 12px;
  line-height: 16px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  cursor: default;
}
.semi-dropdown-title-withTick {
  padding-left: 31px;
}
.semi-dropdown-item {
  padding: 8px 16px;
  color: var(--semi-color-text-0);
  max-width: 280px;
  display: flex;
  align-items: center;
  transition: background-color var(--semi-transition_duration-none) var(--semi-transition_function-easeOut) 0ms;
}
.semi-dropdown-item-hover {
  background-color: var(--semi-color-fill-0);
}
.semi-dropdown-item:not(.semi-dropdown-item-active):hover {
  background-color: var(--semi-color-fill-0);
  cursor: pointer;
}
.semi-dropdown-item:not(.semi-dropdown-item-active):active {
  background-color: var(--semi-color-fill-1);
}
.semi-dropdown-item:focus-visible {
  background-color: var(--semi-color-fill-0);
  outline: 0;
}
.semi-dropdown-item-icon {
  display: inline-flex;
  align-items: center;
  margin-right: 8px;
}
.semi-dropdown-item-danger {
  color: var(--semi-color-danger);
}
.semi-dropdown-item-secondary {
  color: var(--semi-color-secondary);
}
.semi-dropdown-item-warning {
  color: var(--semi-color-warning);
}
.semi-dropdown-item-tertiary {
  color: var(--semi-color-tertiary);
}
.semi-dropdown-item-primary {
  color: var(--semi-color-primary);
}
.semi-dropdown-item-withTick {
  padding-left: 12px;
}
.semi-dropdown-item > .semi-icon {
  flex-shrink: 0;
  margin-right: 9px;
  font-size: 12px;
}
.semi-dropdown-item-active {
  font-weight: 600;
}
.semi-dropdown-item.semi-dropdown-item-disabled {
  color: var(--semi-color-disabled-text);
  cursor: not-allowed;
}
.semi-dropdown-item.semi-dropdown-item-disabled:hover, .semi-dropdown-item.semi-dropdown-item-disabled:active {
  cursor: not-allowed;
  background-color: transparent;
}
.semi-dropdown-divider {
  display: block;
  height: 1px;
  width: 100%;
  min-width: 100%;
  clear: both;
  background: var(--semi-color-border);
  margin: 4px 0;
}

.semi-rtl .semi-dropdown-wrapper,
.semi-portal-rtl .semi-dropdown-wrapper {
  direction: rtl;
}
.semi-rtl .semi-dropdown-title-withTick,
.semi-portal-rtl .semi-dropdown-title-withTick {
  padding-left: 0;
  padding-right: 31px;
}
.semi-rtl .semi-dropdown-item-withTick,
.semi-portal-rtl .semi-dropdown-item-withTick {
  padding-left: auto;
  padding-right: 12px;
}
.semi-rtl .semi-dropdown-item > .semi-icon,
.semi-portal-rtl .semi-dropdown-item > .semi-icon {
  margin-right: 0;
  margin-left: 9px;
}