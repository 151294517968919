/* shadow */
/* sizing */
/* spacing */
.semi-spin {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
}
@keyframes semi-animation-rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.semi-spin-wrapper {
  text-align: center;
  position: absolute;
  width: 100%;
  transform: translateY(-50%);
  top: 50%;
  color: var(--semi-color-primary);
}
.semi-spin-wrapper > svg {
  animation: 600ms linear infinite semi-animation-rotate;
  animation-fill-mode: forwards;
  vertical-align: top;
  width: 20px;
  height: 20px;
}
.semi-spin-animate {
  display: inline-flex;
  animation: 1600ms linear infinite semi-animation-rotate;
  animation-fill-mode: forwards;
}
.semi-spin-children {
  opacity: 0.5;
  user-select: none;
}
.semi-spin-block {
  display: block;
}
.semi-spin-block::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.semi-spin-block .semi-spin-wrapper {
  display: block;
}
.semi-spin-block.semi-spin {
  height: auto;
  width: auto;
}
.semi-spin-hidden::after {
  content: none;
}
.semi-spin-hidden > .semi-spin-children {
  opacity: 1;
  user-select: auto;
}

.semi-spin-small {
  width: 14px;
  height: 14px;
}
.semi-spin-small > .semi-spin-wrapper svg {
  width: 14px;
  height: 14px;
}

.semi-spin-middle {
  width: 20px;
  height: 20px;
}
.semi-spin-middle > .semi-spin-wrapper svg {
  width: 20px;
  height: 20px;
}

.semi-spin-large {
  width: 32px;
  height: 32px;
}
.semi-spin-large > .semi-spin-wrapper svg {
  width: 32px;
  height: 32px;
}

.semi-spin-container {
  overflow: hidden;
}

.semi-rtl .semi-spin,
.semi-portal-rtl .semi-spin {
  direction: rtl;
}
.semi-rtl .semi-spin-container,
.semi-portal-rtl .semi-spin-container {
  direction: rtl;
}