/* shadow */
/* sizing */
/* spacing */
.semi-typography {
  color: var(--semi-color-text-0);
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.semi-typography.semi-typography-secondary {
  color: var(--semi-color-text-1);
}
.semi-typography.semi-typography-tertiary {
  color: var(--semi-color-text-2);
}
.semi-typography.semi-typography-quaternary {
  color: var(--semi-color-text-3);
}
.semi-typography.semi-typography-warning {
  color: var(--semi-color-warning);
}
.semi-typography.semi-typography-success {
  color: var(--semi-color-success);
}
.semi-typography.semi-typography-danger {
  color: var(--semi-color-danger);
}
.semi-typography.semi-typography-link {
  color: var(--semi-color-link);
  font-weight: 600;
}
.semi-typography.semi-typography-disabled {
  color: var(--semi-color-disabled-text);
  cursor: not-allowed;
  user-select: none;
}
.semi-typography.semi-typography-disabled.semi-typography-link {
  color: var(--semi-color-link);
}
.semi-typography-icon {
  margin-right: 4px;
  vertical-align: middle;
  color: inherit;
}
.semi-typography-small {
  font-size: 12px;
  line-height: 16px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.semi-typography code {
  border: 1px solid var(--semi-color-border);
  border-radius: 2px;
  color: var(--semi-color-text-2);
  background-color: var(--semi-color-fill-1);
  padding: 2px 4px;
}
.semi-typography mark {
  background-color: var(--semi-color-primary-light-default);
}
.semi-typography u {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}
.semi-typography del {
  text-decoration: line-through;
}
.semi-typography strong {
  font-weight: 600;
}
.semi-typography a {
  display: inline;
  color: var(--semi-color-link);
  cursor: pointer;
  text-decoration: none;
}
.semi-typography a:visited {
  color: var(--semi-color-link-visited);
}
.semi-typography a:hover {
  color: var(--semi-color-link-hover);
}
.semi-typography a:active {
  color: var(--semi-color-link-active);
}
.semi-typography a .semi-typography-link-underline:hover {
  border-bottom: 1px solid var(--semi-color-link-hover);
  margin-bottom: -1px;
}
.semi-typography a .semi-typography-link-underline:active {
  border-bottom: 1px solid var(--semi-color-link-active);
  margin-bottom: -1px;
}
.semi-typography-ellipsis-single-line {
  overflow: hidden;
}
.semi-typography-ellipsis-multiple-line {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.semi-typography-ellipsis-multiple-line.semi-typography-ellipsis-multiple-line-text {
  display: -webkit-inline-box;
}
.semi-typography-ellipsis-overflow-ellipsis {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.semi-typography-ellipsis-overflow-ellipsis.semi-typography-ellipsis-overflow-ellipsis-text {
  display: inline-block;
  max-width: 100%;
  vertical-align: top;
}
.semi-typography-ellipsis-expand {
  display: inline;
  margin-left: 8px;
}
.semi-typography-action-copy {
  display: inline-flex;
  vertical-align: middle;
  padding: 0;
  margin-left: 4px;
}
.semi-typography a.semi-typography-action-copy-icon {
  display: inline-flex;
}
.semi-typography-action-copied {
  display: inline-flex;
  padding: 0;
  margin-left: 4px;
  color: var(--semi-color-text-2);
}
.semi-typography-action-copied .semi-icon {
  vertical-align: middle;
  color: var(--semi-color-success);
}
.semi-typography-paragraph {
  margin: 0;
}

h1.semi-typography,
.semi-typography-h1.semi-typography {
  font-size: 32px;
  line-height: 44px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  margin: 0;
}
h1.semi-typography.semi-typography-h1-weight-light,
.semi-typography-h1.semi-typography.semi-typography-h1-weight-light {
  font-weight: 200;
}
h1.semi-typography.semi-typography-h1-weight-regular,
.semi-typography-h1.semi-typography.semi-typography-h1-weight-regular {
  font-weight: 400;
}
h1.semi-typography.semi-typography-h1-weight-medium,
.semi-typography-h1.semi-typography.semi-typography-h1-weight-medium {
  font-weight: 500;
}
h1.semi-typography.semi-typography-h1-weight-semibold,
.semi-typography-h1.semi-typography.semi-typography-h1-weight-semibold {
  font-weight: 600;
}
h1.semi-typography.semi-typography-h1-weight-bold,
.semi-typography-h1.semi-typography.semi-typography-h1-weight-bold {
  font-weight: 700;
}

h2.semi-typography,
.semi-typography-h2.semi-typography {
  font-size: 28px;
  line-height: 40px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  margin: 0;
}
h2.semi-typography.semi-typography-h2-weight-light,
.semi-typography-h2.semi-typography.semi-typography-h2-weight-light {
  font-weight: 200;
}
h2.semi-typography.semi-typography-h2-weight-regular,
.semi-typography-h2.semi-typography.semi-typography-h2-weight-regular {
  font-weight: 400;
}
h2.semi-typography.semi-typography-h2-weight-medium,
.semi-typography-h2.semi-typography.semi-typography-h2-weight-medium {
  font-weight: 500;
}
h2.semi-typography.semi-typography-h2-weight-semibold,
.semi-typography-h2.semi-typography.semi-typography-h2-weight-semibold {
  font-weight: 600;
}
h2.semi-typography.semi-typography-h2-weight-bold,
.semi-typography-h2.semi-typography.semi-typography-h2-weight-bold {
  font-weight: 700;
}

h3.semi-typography,
.semi-typography-h3.semi-typography {
  font-size: 24px;
  line-height: 32px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  margin: 0;
}
h3.semi-typography.semi-typography-h3-weight-light,
.semi-typography-h3.semi-typography.semi-typography-h3-weight-light {
  font-weight: 200;
}
h3.semi-typography.semi-typography-h3-weight-regular,
.semi-typography-h3.semi-typography.semi-typography-h3-weight-regular {
  font-weight: 400;
}
h3.semi-typography.semi-typography-h3-weight-medium,
.semi-typography-h3.semi-typography.semi-typography-h3-weight-medium {
  font-weight: 500;
}
h3.semi-typography.semi-typography-h3-weight-semibold,
.semi-typography-h3.semi-typography.semi-typography-h3-weight-semibold {
  font-weight: 600;
}
h3.semi-typography.semi-typography-h3-weight-bold,
.semi-typography-h3.semi-typography.semi-typography-h3-weight-bold {
  font-weight: 700;
}

h4.semi-typography,
.semi-typography-h4.semi-typography {
  font-size: 20px;
  line-height: 28px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  margin: 0;
}
h4.semi-typography.semi-typography-h4-weight-light,
.semi-typography-h4.semi-typography.semi-typography-h4-weight-light {
  font-weight: 200;
}
h4.semi-typography.semi-typography-h4-weight-regular,
.semi-typography-h4.semi-typography.semi-typography-h4-weight-regular {
  font-weight: 400;
}
h4.semi-typography.semi-typography-h4-weight-medium,
.semi-typography-h4.semi-typography.semi-typography-h4-weight-medium {
  font-weight: 500;
}
h4.semi-typography.semi-typography-h4-weight-semibold,
.semi-typography-h4.semi-typography.semi-typography-h4-weight-semibold {
  font-weight: 600;
}
h4.semi-typography.semi-typography-h4-weight-bold,
.semi-typography-h4.semi-typography.semi-typography-h4-weight-bold {
  font-weight: 700;
}

h5.semi-typography,
.semi-typography-h5.semi-typography {
  font-size: 18px;
  line-height: 24px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  margin: 0;
}
h5.semi-typography.semi-typography-h5-weight-light,
.semi-typography-h5.semi-typography.semi-typography-h5-weight-light {
  font-weight: 200;
}
h5.semi-typography.semi-typography-h5-weight-regular,
.semi-typography-h5.semi-typography.semi-typography-h5-weight-regular {
  font-weight: 400;
}
h5.semi-typography.semi-typography-h5-weight-medium,
.semi-typography-h5.semi-typography.semi-typography-h5-weight-medium {
  font-weight: 500;
}
h5.semi-typography.semi-typography-h5-weight-semibold,
.semi-typography-h5.semi-typography.semi-typography-h5-weight-semibold {
  font-weight: 600;
}
h5.semi-typography.semi-typography-h5-weight-bold,
.semi-typography-h5.semi-typography.semi-typography-h5-weight-bold {
  font-weight: 700;
}

h6.semi-typography,
.semi-typography-h6.semi-typography {
  font-size: 16px;
  line-height: 22px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  margin: 0;
}
h6.semi-typography.semi-typography-h6-weight-light,
.semi-typography-h6.semi-typography.semi-typography-h6-weight-light {
  font-weight: 200;
}
h6.semi-typography.semi-typography-h6-weight-regular,
.semi-typography-h6.semi-typography.semi-typography-h6-weight-regular {
  font-weight: 400;
}
h6.semi-typography.semi-typography-h6-weight-medium,
.semi-typography-h6.semi-typography.semi-typography-h6-weight-medium {
  font-weight: 500;
}
h6.semi-typography.semi-typography-h6-weight-semibold,
.semi-typography-h6.semi-typography.semi-typography-h6-weight-semibold {
  font-weight: 600;
}
h6.semi-typography.semi-typography-h6-weight-bold,
.semi-typography-h6.semi-typography.semi-typography-h6-weight-bold {
  font-weight: 700;
}

p.semi-typography-extended,
.semi-typography-paragraph.semi-typography-extended {
  line-height: 24px;
}

.semi-rtl .semi-typography,
.semi-portal-rtl .semi-typography {
  direction: rtl;
}
.semi-rtl .semi-typography-link a,
.semi-rtl .semi-typography a,
.semi-portal-rtl .semi-typography-link a,
.semi-portal-rtl .semi-typography a {
  display: inline-block;
}
.semi-rtl .semi-typography-icon,
.semi-portal-rtl .semi-typography-icon {
  margin-right: auto;
  margin-left: 4px;
}
.semi-rtl .semi-typography-ellipsis-expand,
.semi-portal-rtl .semi-typography-ellipsis-expand {
  margin-left: auto;
}
.semi-rtl .semi-typography-action-copy,
.semi-portal-rtl .semi-typography-action-copy {
  margin-left: auto;
  margin-right: 4px;
}
.semi-rtl .semi-typography-action-copied,
.semi-portal-rtl .semi-typography-action-copied {
  margin-left: auto;
  margin-right: 4px;
}