/* shadow */
/* sizing */
/* spacing */
@keyframes semi-popover-zoomIn {
  from {
    opacity: 0;
    transform: scale(0.8, 0.8);
  }
  50% {
    opacity: 1;
  }
}
@keyframes semi-popover-zoomOut {
  from {
    opacity: 1;
  }
  60% {
    opacity: 0;
    transform: scale(0.8, 0.8);
  }
  to {
    opacity: 0;
  }
}
.semi-popover-wrapper {
  position: relative;
  background-color: var(--semi-color-bg-3);
  box-shadow: var(--semi-shadow-elevated);
  z-index: 1030;
  border-radius: var(--semi-border-radius-medium);
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  opacity: 0;
}
.semi-popover-wrapper-show {
  opacity: 1;
}
.semi-popover-trigger {
  display: inline-block;
  width: auto;
  height: auto;
}
.semi-popover-title {
  padding: 8px;
  border-bottom: 1px solid var(--semi-color-border);
}
.semi-popover-confirm {
  position: absolute;
}
.semi-popover-with-arrow {
  padding: 12px;
  box-sizing: border-box;
}
.semi-popover-animation-show {
  animation: semi-popover-zoomIn 100ms cubic-bezier(0.215, 0.61, 0.355, 1);
  animation-fill-mode: forwards;
}
.semi-popover-animation-hide {
  animation: semi-popover-zoomOut 100ms cubic-bezier(0.215, 0.61, 0.355, 1);
  animation-fill-mode: forwards;
}

.semi-popover-wrapper .semi-popover-icon-arrow {
  height: 8px;
  width: 24px;
  position: absolute;
  color: unset;
}
.semi-popover-wrapper[x-placement=top] .semi-popover-icon-arrow {
  left: 50%;
  transform: translateX(-50%);
  bottom: -7px;
}
.semi-popover-wrapper[x-placement=top].semi-popover-with-arrow,
.semi-popover-wrapper[x-placement=top] .semi-popover-with-arrow {
  min-width: 36px;
}
.semi-popover-wrapper[x-placement=topLeft] .semi-popover-icon-arrow {
  bottom: -7px;
  left: 6px;
}
.semi-popover-wrapper[x-placement=topLeft].semi-popover-with-arrow,
.semi-popover-wrapper[x-placement=topLeft] .semi-popover-with-arrow {
  min-width: 36px;
}
.semi-popover-wrapper[x-placement=topRight] .semi-popover-icon-arrow {
  bottom: -7px;
  right: 6px;
}
.semi-popover-wrapper[x-placement=topRight].semi-popover-with-arrow,
.semi-popover-wrapper[x-placement=topRight] .semi-popover-with-arrow {
  min-width: 36px;
}
.semi-popover-wrapper[x-placement=leftTop] .semi-popover-icon-arrow {
  width: 8px;
  height: 24px;
  right: -7px;
  top: 6px;
}
.semi-popover-wrapper[x-placement=leftTop].semi-popover-with-arrow,
.semi-popover-wrapper[x-placement=leftTop] .semi-popover-with-arrow {
  min-height: 36px;
}
.semi-popover-wrapper[x-placement=left] .semi-popover-icon-arrow {
  width: 8px;
  height: 24px;
  right: -7px;
  top: 50%;
  transform: translateY(-50%);
}
.semi-popover-wrapper[x-placement=left].semi-popover-with-arrow,
.semi-popover-wrapper[x-placement=left] .semi-popover-with-arrow {
  min-height: 36px;
}
.semi-popover-wrapper[x-placement=leftBottom] .semi-popover-icon-arrow {
  width: 8px;
  height: 24px;
  right: -7px;
  bottom: 6px;
}
.semi-popover-wrapper[x-placement=leftBottom].semi-popover-with-arrow,
.semi-popover-wrapper[x-placement=leftBottom] .semi-popover-with-arrow {
  min-height: 36px;
}
.semi-popover-wrapper[x-placement=rightTop] .semi-popover-icon-arrow {
  width: 8px;
  height: 24px;
  left: -7px;
  top: 6px;
  transform: rotate(180deg);
}
.semi-popover-wrapper[x-placement=rightTop].semi-popover-with-arrow,
.semi-popover-wrapper[x-placement=rightTop] .semi-popover-with-arrow {
  min-height: 36px;
}
.semi-popover-wrapper[x-placement=right] .semi-popover-icon-arrow {
  width: 8px;
  height: 24px;
  left: -7px;
  top: 50%;
  transform: translateY(-50%) rotate(180deg);
}
.semi-popover-wrapper[x-placement=right].semi-popover-with-arrow,
.semi-popover-wrapper[x-placement=right] .semi-popover-with-arrow {
  min-height: 36px;
}
.semi-popover-wrapper[x-placement=rightBottom] .semi-popover-icon-arrow {
  width: 8px;
  height: 24px;
  left: -7px;
  bottom: 6px;
  transform: rotate(180deg);
}
.semi-popover-wrapper[x-placement=rightBottom].semi-popover-with-arrow,
.semi-popover-wrapper[x-placement=rightBottom] .semi-popover-with-arrow {
  min-height: 36px;
}
.semi-popover-wrapper[x-placement=bottomLeft] .semi-popover-icon-arrow {
  top: -7px;
  left: 6px;
  transform: rotate(180deg);
}
.semi-popover-wrapper[x-placement=bottomLeft].semi-popover-with-arrow,
.semi-popover-wrapper[x-placement=bottomLeft] .semi-popover-with-arrow {
  min-width: 36px;
}
.semi-popover-wrapper[x-placement=bottom] .semi-popover-icon-arrow {
  top: -7px;
  left: 50%;
  transform: translateX(-50%) rotate(180deg);
}
.semi-popover-wrapper[x-placement=bottom].semi-popover-with-arrow,
.semi-popover-wrapper[x-placement=bottom] .semi-popover-with-arrow {
  min-width: 36px;
}
.semi-popover-wrapper[x-placement=bottomRight] .semi-popover-icon-arrow {
  right: 6px;
  top: -7px;
  transform: rotate(180deg);
}
.semi-popover-wrapper[x-placement=bottomRight].semi-popover-with-arrow,
.semi-popover-wrapper[x-placement=bottomRight] .semi-popover-with-arrow {
  min-width: 36px;
}

.semi-popover.semi-popover-rtl {
  direction: rtl;
}