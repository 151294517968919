/* shadow */
/* sizing */
/* spacing */
.semi-icon {
  display: inline-block;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  text-rendering: optimizeLegibility;
  fill: currentColor;
}

.semi-icon-extra-small {
  font-size: 8px;
}

.semi-icon-small {
  font-size: 12px;
}

.semi-icon-default {
  font-size: 16px;
}

.semi-icon-large {
  font-size: 20px;
}

.semi-icon-extra-large {
  font-size: 24px;
}

.semi-icon-spinning {
  animation: 0.6s linear infinite semi-icon-animation-rotate;
  animation-fill-mode: forwards;
}

@keyframes semi-icon-animation-rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}