/* shadow */
/* sizing */
/* spacing */
body {
  --semi-transition_duration-slowest:0ms;
  --semi-transition_duration-slower:0ms;
  --semi-transition_duration-slow:0ms;
  --semi-transition_duration-normal:0ms;
  --semi-transition_duration-fast:0ms;
  --semi-transition_duration-faster:0ms;
  --semi-transition_duration-fastest:0ms;
  --semi-transition_duration-none:0ms;
  --semi-transition_function-linear:linear;
  --semi-transition_function-ease:ease;
  --semi-transition_function-easeIn:ease-in;
  --semi-transition_function-easeOut:ease-out;
  --semi-transition_function-easeInIOut:ease-in-out;
  --semi-transition_delay-none: 0ms;
  --semi-transition_delay-slowest:0ms;
  --semi-transition_delay-slower:0ms;
  --semi-transition_delay-slow:0ms;
  --semi-transition_delay-normal:0ms;
  --semi-transition_delay-fast:0ms;
  --semi-transition_delay-faster:0ms;
  --semi-transition_delay-fastest:0ms;
  --semi-transform_scale-none:scale(1,1);
  --semi-transform_scale-small:scale(1,1);
  --semi-transform_scale-medium:scale(1,1);
  --semi-transform_scale-large:scale(1,1);
  --semi-transform-rotate-none:rotate(0deg);
  --semi-transform_rotate-clockwise90deg:rotate(90deg);
  --semi-transform_rotate-clockwise180deg:rotate(180deg);
  --semi-transform_rotate-clockwise270deg:rotate(270deg);
  --semi-transform_rotate-clockwise360deg:rotate(360deg);
  --semi-transform_rotate-anticlockwise90deg:rotate(-90deg);
  --semi-transform_rotate-anticlockwise180deg:rotate(-180deg);
  --semi-transform_rotate-anticlockwise270deg:rotate(-270deg);
  --semi-transform_rotate-anticlockwise360deg:rotate(-360deg);
}

body,
body .semi-always-light {
  --semi-amber-0: 254,251,235;
  --semi-amber-1: 252,245,206;
  --semi-amber-2: 249,232,158;
  --semi-amber-3: 246,216,111;
  --semi-amber-4: 243,198,65;
  --semi-amber-5: 240,177,20;
  --semi-amber-6: 200,138,15;
  --semi-amber-7: 160,102,10;
  --semi-amber-8: 120,70,6;
  --semi-amber-9: 80,43,3;
  --semi-black: 0,0,0;
  --semi-blue-0: 234,245,255;
  --semi-blue-1: 203,231,254;
  --semi-blue-2: 152,205,253;
  --semi-blue-3: 101,178,252;
  --semi-blue-4: 50,149,251;
  --semi-blue-5: 0,100,250;
  --semi-blue-6: 0,98,214;
  --semi-blue-7: 0,79,179;
  --semi-blue-8: 0,61,143;
  --semi-blue-9: 0,44,107;
  --semi-cyan-0: 229,247,248;
  --semi-cyan-1: 194,239,240;
  --semi-cyan-2: 138,221,226;
  --semi-cyan-3: 88,203,211;
  --semi-cyan-4: 44,184,197;
  --semi-cyan-5: 5,164,182;
  --semi-cyan-6: 3,134,152;
  --semi-cyan-7: 1,105,121;
  --semi-cyan-8: 0,77,91;
  --semi-cyan-9: 0,50,61;
  --semi-green-0: 236,247,236;
  --semi-green-1: 208,240,209;
  --semi-green-2: 164,224,167;
  --semi-green-3: 125,209,130;
  --semi-green-4: 90,194,98;
  --semi-green-5: 59,179,70;
  --semi-green-6: 48,149,59;
  --semi-green-7: 37,119,47;
  --semi-green-8: 27,89,36;
  --semi-green-9: 17,60,24;
  --semi-grey-0: 249,249,249;
  --semi-grey-1: 230,232,234;
  --semi-grey-2: 198,202,205;
  --semi-grey-3: 167,171,176;
  --semi-grey-4: 136,141,146;
  --semi-grey-5: 107,112,117;
  --semi-grey-6: 85,91,97;
  --semi-grey-7: 65,70,76;
  --semi-grey-8: 46,50,56;
  --semi-grey-9: 28,31,35;
  --semi-indigo-0: 236,239,248;
  --semi-indigo-1: 209,216,240;
  --semi-indigo-2: 167,179,225;
  --semi-indigo-3: 128,144,211;
  --semi-indigo-4: 94,111,196;
  --semi-indigo-5: 63,81,181;
  --semi-indigo-6: 51,66,161;
  --semi-indigo-7: 40,52,140;
  --semi-indigo-8: 31,40,120;
  --semi-indigo-9: 23,29,99;
  --semi-light-blue-0: 233,247,253;
  --semi-light-blue-1: 201,236,252;
  --semi-light-blue-2: 149,216,248;
  --semi-light-blue-3: 98,195,245;
  --semi-light-blue-4: 48,172,241;
  --semi-light-blue-5: 0,149,238;
  --semi-light-blue-6: 0,123,202;
  --semi-light-blue-7: 0,99,167;
  --semi-light-blue-8: 0,75,131;
  --semi-light-blue-9: 0,53,95;
  --semi-light-green-0: 243,248,236;
  --semi-light-green-1: 227,240,208;
  --semi-light-green-2: 200,226,165;
  --semi-light-green-3: 173,211,126;
  --semi-light-green-4: 147,197,91;
  --semi-light-green-5: 123,182,60;
  --semi-light-green-6: 100,152,48;
  --semi-light-green-7: 78,121,38;
  --semi-light-green-8: 57,91,27;
  --semi-light-green-9: 37,61,18;
  --semi-lime-0: 242,250,230;
  --semi-lime-1: 227,246,197;
  --semi-lime-2: 203,237,142;
  --semi-lime-3: 183,227,91;
  --semi-lime-4: 167,218,44;
  --semi-lime-5: 155,209,0;
  --semi-lime-6: 126,174,0;
  --semi-lime-7: 99,139,0;
  --semi-lime-8: 72,104,0;
  --semi-lime-9: 47,70,0;
  --semi-orange-0: 255,248,234;
  --semi-orange-1: 254,238,204;
  --semi-orange-2: 254,217,152;
  --semi-orange-3: 253,193,101;
  --semi-orange-4: 253,166,51;
  --semi-orange-5: 252,136,0;
  --semi-orange-6: 210,103,0;
  --semi-orange-7: 168,74,0;
  --semi-orange-8: 126,49,0;
  --semi-orange-9: 84,29,0;
  --semi-pink-0: 253,236,239;
  --semi-pink-1: 251,207,216;
  --semi-pink-2: 246,160,181;
  --semi-pink-3: 242,115,150;
  --semi-pink-4: 237,72,123;
  --semi-pink-5: 233,30,99;
  --semi-pink-6: 197,19,86;
  --semi-pink-7: 162,11,72;
  --semi-pink-8: 126,5,58;
  --semi-pink-9: 90,1,43;
  --semi-purple-0: 247,233,247;
  --semi-purple-1: 239,202,240;
  --semi-purple-2: 221,155,224;
  --semi-purple-3: 201,111,209;
  --semi-purple-4: 180,73,194;
  --semi-purple-5: 158,40,179;
  --semi-purple-6: 135,30,158;
  --semi-purple-7: 113,22,138;
  --semi-purple-8: 92,15,117;
  --semi-purple-9: 73,10,97;
  --semi-red-0: 254,242,237;
  --semi-red-1: 254,221,210;
  --semi-red-2: 253,183,165;
  --semi-red-3: 251,144,120;
  --semi-red-4: 250,102,76;
  --semi-red-5: 249,57,32;
  --semi-red-6: 213,37,21;
  --semi-red-7: 178,20,12;
  --semi-red-8: 142,8,5;
  --semi-red-9: 106,1,3;
  --semi-teal-0: 228,247,244;
  --semi-teal-1: 192,240,232;
  --semi-teal-2: 135,224,211;
  --semi-teal-3: 84,209,193;
  --semi-teal-4: 39,194,176;
  --semi-teal-5: 0,179,161;
  --semi-teal-6: 0,149,137;
  --semi-teal-7: 0,119,111;
  --semi-teal-8: 0,89,85;
  --semi-teal-9: 0,60,58;
  --semi-violet-0: 243,237,249;
  --semi-violet-1: 226,209,244;
  --semi-violet-2: 196,167,233;
  --semi-violet-3: 166,127,221;
  --semi-violet-4: 136,91,210;
  --semi-violet-5: 106,58,199;
  --semi-violet-6: 87,47,179;
  --semi-violet-7: 70,37,158;
  --semi-violet-8: 54,28,138;
  --semi-violet-9: 40,20,117;
  --semi-white: 255,255,255;
  --semi-yellow-0: 255,253,234;
  --semi-yellow-1: 254,251,203;
  --semi-yellow-2: 253,243,152;
  --semi-yellow-3: 252,232,101;
  --semi-yellow-4: 251,218,50;
  --semi-yellow-5: 250,200,0;
  --semi-yellow-6: 208,170,0;
  --semi-yellow-7: 167,139,0;
  --semi-yellow-8: 125,106,0;
  --semi-yellow-9: 83,72,0;
}

body[theme-mode=dark],
body .semi-always-dark {
  --semi-red-0: 108,9,11;
  --semi-red-1: 144,17,16;
  --semi-red-2: 180,32,25;
  --semi-red-3: 215,51,36;
  --semi-red-4: 251,73,50;
  --semi-red-5: 252,114,90;
  --semi-red-6: 253,153,131;
  --semi-red-7: 253,190,172;
  --semi-red-8: 254,224,213;
  --semi-red-9: 255,243,239;
  --semi-pink-0: 92,7,48;
  --semi-pink-1: 128,14,65;
  --semi-pink-2: 164,23,81;
  --semi-pink-3: 199,34,97;
  --semi-pink-4: 235,47,113;
  --semi-pink-5: 239,86,134;
  --semi-pink-6: 243,126,159;
  --semi-pink-7: 247,168,188;
  --semi-pink-8: 251,211,220;
  --semi-pink-9: 253,238,241;
  --semi-purple-0: 74,16,97;
  --semi-purple-1: 94,23,118;
  --semi-purple-2: 115,31,138;
  --semi-purple-3: 137,40,159;
  --semi-purple-4: 160,51,179;
  --semi-purple-5: 181,83,194;
  --semi-purple-6: 202,120,209;
  --semi-purple-7: 221,160,225;
  --semi-purple-8: 239,206,240;
  --semi-purple-9: 247,235,247;
  --semi-violet-0: 64,27,119;
  --semi-violet-1: 76,36,140;
  --semi-violet-2: 88,46,160;
  --semi-violet-3: 100,57,181;
  --semi-violet-4: 114,70,201;
  --semi-violet-5: 136,101,212;
  --semi-violet-6: 162,136,223;
  --semi-violet-7: 190,173,233;
  --semi-violet-8: 221,212,244;
  --semi-violet-9: 241,238,250;
  --semi-indigo-0: 23,30,101;
  --semi-indigo-1: 32,41,122;
  --semi-indigo-2: 41,54,142;
  --semi-indigo-3: 52,68,163;
  --semi-indigo-4: 64,83,183;
  --semi-indigo-5: 95,113,197;
  --semi-indigo-6: 129,145,212;
  --semi-indigo-7: 167,180,226;
  --semi-indigo-8: 209,216,241;
  --semi-indigo-9: 237,239,248;
  --semi-blue-0: 5,49,112;
  --semi-blue-1: 10,70,148;
  --semi-blue-2: 19,92,184;
  --semi-blue-3: 29,117,219;
  --semi-blue-4: 41,144,255;
  --semi-blue-5: 84,169,255;
  --semi-blue-6: 127,193,255;
  --semi-blue-7: 169,215,255;
  --semi-blue-8: 212,236,255;
  --semi-blue-9: 239,248,255;
  --semi-light-blue-0: 0,55,97;
  --semi-light-blue-1: 0,77,133;
  --semi-light-blue-2: 3,102,169;
  --semi-light-blue-3: 10,129,204;
  --semi-light-blue-4: 19,159,240;
  --semi-light-blue-5: 64,180,243;
  --semi-light-blue-6: 110,200,246;
  --semi-light-blue-7: 157,220,249;
  --semi-light-blue-8: 206,238,252;
  --semi-light-blue-9: 235,248,254;
  --semi-cyan-0: 4,52,61;
  --semi-cyan-1: 7,79,92;
  --semi-cyan-2: 10,108,123;
  --semi-cyan-3: 14,137,153;
  --semi-cyan-4: 19,168,184;
  --semi-cyan-5: 56,187,198;
  --semi-cyan-6: 98,205,212;
  --semi-cyan-7: 145,223,227;
  --semi-cyan-8: 198,239,241;
  --semi-cyan-9: 231,247,248;
  --semi-teal-0: 2,60,57;
  --semi-teal-1: 4,90,85;
  --semi-teal-2: 7,119,111;
  --semi-teal-3: 10,149,136;
  --semi-teal-4: 14,179,161;
  --semi-teal-5: 51,194,176;
  --semi-teal-6: 94,209,193;
  --semi-teal-7: 142,225,211;
  --semi-teal-8: 196,240,232;
  --semi-teal-9: 230,247,244;
  --semi-green-0: 18,60,25;
  --semi-green-1: 28,90,37;
  --semi-green-2: 39,119,49;
  --semi-green-3: 50,149,61;
  --semi-green-4: 62,179,73;
  --semi-green-5: 93,194,100;
  --semi-green-6: 127,209,132;
  --semi-green-7: 166,225,168;
  --semi-green-8: 208,240,209;
  --semi-green-9: 236,247,236;
  --semi-light-green-0: 38,61,19;
  --semi-light-green-1: 59,92,29;
  --semi-light-green-2: 81,123,40;
  --semi-light-green-3: 103,153,52;
  --semi-light-green-4: 127,184,64;
  --semi-light-green-5: 151,198,95;
  --semi-light-green-6: 176,212,129;
  --semi-light-green-7: 201,227,167;
  --semi-light-green-8: 228,241,209;
  --semi-light-green-9: 243,248,237;
  --semi-lime-0: 49,70,3;
  --semi-lime-1: 75,105,5;
  --semi-lime-2: 103,141,9;
  --semi-lime-3: 132,176,12;
  --semi-lime-4: 162,211,17;
  --semi-lime-5: 174,220,58;
  --semi-lime-6: 189,229,102;
  --semi-lime-7: 207,237,150;
  --semi-lime-8: 229,246,201;
  --semi-lime-9: 243,251,233;
  --semi-yellow-0: 84,73,3;
  --semi-yellow-1: 126,108,6;
  --semi-yellow-2: 168,142,10;
  --semi-yellow-3: 210,175,15;
  --semi-yellow-4: 252,206,20;
  --semi-yellow-5: 253,222,67;
  --semi-yellow-6: 253,235,113;
  --semi-yellow-7: 254,245,160;
  --semi-yellow-8: 254,251,208;
  --semi-yellow-9: 255,254,236;
  --semi-amber-0: 81,46,9;
  --semi-amber-1: 121,75,15;
  --semi-amber-2: 161,107,22;
  --semi-amber-3: 202,143,30;
  --semi-amber-4: 242,183,38;
  --semi-amber-5: 245,202,80;
  --semi-amber-6: 247,219,122;
  --semi-amber-7: 250,234,166;
  --semi-amber-8: 252,246,210;
  --semi-amber-9: 254,251,237;
  --semi-orange-0: 85,31,3;
  --semi-orange-1: 128,53,6;
  --semi-orange-2: 170,80,10;
  --semi-orange-3: 213,111,15;
  --semi-orange-4: 255,146,20;
  --semi-orange-5: 255,174,67;
  --semi-orange-6: 255,199,114;
  --semi-orange-7: 255,221,161;
  --semi-orange-8: 255,239,208;
  --semi-orange-9: 255,249,237;
  --semi-grey-0: 28,31,35;
  --semi-grey-1: 46,50,56;
  --semi-grey-2: 65,70,76;
  --semi-grey-3: 85,91,97;
  --semi-grey-4: 107,112,117;
  --semi-grey-5: 136,141,146;
  --semi-grey-6: 167,171,176;
  --semi-grey-7: 198,202,205;
  --semi-grey-8: 230,232,234;
  --semi-grey-9: 249,249,249;
  --semi-white: 255, 255, 255;
  --semi-black: 0, 0, 0;
}

body, body[theme-mode=dark] .semi-always-light {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  --semi-color-white: rgba(var(--semi-white), 1);
  --semi-color-black: rgba(var(--semi-black), 1);
  --semi-color-primary: rgba(var(--semi-blue-5), 1);
  --semi-color-primary-hover: rgba(var(--semi-blue-6), 1);
  --semi-color-primary-active: rgba(var(--semi-blue-7), 1);
  --semi-color-primary-disabled: rgba(var(--semi-blue-2), 1);
  --semi-color-primary-light-default: rgba(var(--semi-blue-0), 1);
  --semi-color-primary-light-hover: rgba(var(--semi-blue-1), 1);
  --semi-color-primary-light-active: rgba(var(--semi-blue-2), 1);
  --semi-color-secondary: rgba(var(--semi-light-blue-5), 1);
  --semi-color-secondary-hover: rgba(var(--semi-light-blue-6), 1);
  --semi-color-secondary-active: rgba(var(--semi-light-blue-7), 1);
  --semi-color-secondary-disabled: rgba(var(--semi-light-blue-2), 1);
  --semi-color-secondary-light-default: rgba(var(--semi-light-blue-0), 1);
  --semi-color-secondary-light-hover: rgba(var(--semi-light-blue-1), 1);
  --semi-color-secondary-light-active: rgba(var(--semi-light-blue-2), 1);
  --semi-color-tertiary: rgba(var(--semi-grey-5), 1);
  --semi-color-tertiary-hover: rgba(var(--semi-grey-6), 1);
  --semi-color-tertiary-active: rgba(var(--semi-grey-7), 1);
  --semi-color-tertiary-light-default: rgba(var(--semi-grey-0), 1);
  --semi-color-tertiary-light-hover: rgba(var(--semi-grey-1), 1);
  --semi-color-tertiary-light-active: rgba(var(--semi-grey-2), 1);
  --semi-color-default: rgba(var(--semi-grey-0), 1);
  --semi-color-default-hover: rgba(var(--semi-grey-1), 1);
  --semi-color-default-active: rgba(var(--semi-grey-2), 1);
  --semi-color-info: rgba(var(--semi-blue-5), 1);
  --semi-color-info-hover: rgba(var(--semi-blue-6), 1);
  --semi-color-info-active: rgba(var(--semi-blue-7), 1);
  --semi-color-info-disabled: rgba(var(--semi-blue-2), 1);
  --semi-color-info-light-default: rgba(var(--semi-blue-0), 1);
  --semi-color-info-light-hover: rgba(var(--semi-blue-1), 1);
  --semi-color-info-light-active: rgba(var(--semi-blue-2), 1);
  --semi-color-success: rgba(var(--semi-green-5), 1);
  --semi-color-success-hover: rgba(var(--semi-green-6), 1);
  --semi-color-success-active: rgba(var(--semi-green-7), 1);
  --semi-color-success-disabled: rgba(var(--semi-green-2), 1);
  --semi-color-success-light-default: rgba(var(--semi-green-0), 1);
  --semi-color-success-light-hover: rgba(var(--semi-green-1), 1);
  --semi-color-success-light-active: rgba(var(--semi-green-2), 1);
  --semi-color-danger: rgba(var(--semi-red-5), 1);
  --semi-color-danger-hover: rgba(var(--semi-red-6), 1);
  --semi-color-danger-active: rgba(var(--semi-red-7), 1);
  --semi-color-danger-light-default: rgba(var(--semi-red-0), 1);
  --semi-color-danger-light-hover: rgba(var(--semi-red-1), 1);
  --semi-color-danger-light-active: rgba(var(--semi-red-2), 1);
  --semi-color-warning: rgba(var(--semi-orange-5), 1);
  --semi-color-warning-hover: rgba(var(--semi-orange-6), 1);
  --semi-color-warning-active: rgba(var(--semi-orange-7), 1);
  --semi-color-warning-light-default: rgba(var(--semi-orange-0), 1);
  --semi-color-warning-light-hover: rgba(var(--semi-orange-1), 1);
  --semi-color-warning-light-active: rgba(var(--semi-orange-2), 1);
  --semi-color-focus-border: rgba(var(--semi-blue-5), 1);
  --semi-color-disabled-text: rgba(var(--semi-grey-9), .35);
  --semi-color-disabled-border: rgba(var(--semi-grey-1), 1);
  --semi-color-disabled-bg: rgba(var(--semi-grey-1), 1);
  --semi-color-disabled-fill: rgba(var(--semi-grey-8), .04);
  --semi-color-shadow: rgba(var(--semi-black), .04);
  --semi-color-link: rgba(var(--semi-blue-5), 1);
  --semi-color-link-hover: rgba(var(--semi-blue-6), 1);
  --semi-color-link-active: rgba(var(--semi-blue-7), 1);
  --semi-color-link-visited: rgba(var(--semi-blue-5), 1);
  --semi-color-border: rgba(var(--semi-grey-9), .08);
  --semi-color-nav-bg: rgba(var(--semi-white), 1);
  --semi-color-overlay-bg: rgba(22, 22, 26, .6);
  --semi-color-fill-0: rgba(var(--semi-grey-8), .05);
  --semi-color-fill-1: rgba(var(--semi-grey-8), .09);
  --semi-color-fill-2: rgba(var(--semi-grey-8), .13);
  --semi-color-bg-0: rgba(var(--semi-white), 1);
  --semi-color-bg-1: rgba(var(--semi-white), 1);
  --semi-color-bg-2: rgba(var(--semi-white), 1);
  --semi-color-bg-3: rgba(var(--semi-white), 1);
  --semi-color-bg-4: rgba(var(--semi-white), 1);
  --semi-color-text-0: rgba(var(--semi-grey-9), 1);
  --semi-color-text-1: rgba(var(--semi-grey-9), .8);
  --semi-color-text-2: rgba(var(--semi-grey-9), .62);
  --semi-color-text-3: rgba(var(--semi-grey-9), .35);
  --semi-shadow-elevated: 0 0 1px rgba(0, 0, 0, .3), 0 4px 14px rgba(0, 0, 0, .1);
  --semi-border-radius-extra-small: 3px;
  --semi-border-radius-small: 3px;
  --semi-border-radius-medium: 6px;
  --semi-border-radius-large: 12px;
  --semi-border-radius-circle: 50%;
  --semi-border-radius-full: 9999px;
  --semi-color-highlight-bg: rgba(var(--semi-yellow-4), 1);
  --semi-color-highlight: rgba(var(--semi-black), 1);
}

body[theme-mode=dark], body .semi-always-dark {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  --semi-color-white: rgba(228, 231, 245, 1);
  --semi-color-black: rgba(var(--semi-black), 1);
  --semi-color-primary: rgba(var(--semi-blue-5), 1);
  --semi-color-primary-hover: rgba(var(--semi-blue-6), 1);
  --semi-color-primary-active: rgba(var(--semi-blue-7), 1);
  --semi-color-primary-disabled: rgba(var(--semi-blue-2), 1);
  --semi-color-primary-light-default: rgba(var(--semi-blue-5), .2);
  --semi-color-primary-light-hover: rgba(var(--semi-blue-5), .3);
  --semi-color-primary-light-active: rgba(var(--semi-blue-5), .4);
  --semi-color-secondary: rgba(var(--semi-light-blue-5), 1);
  --semi-color-secondary-hover: rgba(var(--semi-light-blue-6), 1);
  --semi-color-secondary-active: rgba(var(--semi-light-blue-7), 1);
  --semi-color-secondary-disabled: rgba(var(--semi-light-blue-2), 1);
  --semi-color-secondary-light-default: rgba(var(--semi-light-blue-5), .2);
  --semi-color-secondary-light-hover: rgba(var(--semi-light-blue-5), .3);
  --semi-color-secondary-light-active: rgba(var(--semi-light-blue-5), .4);
  --semi-color-tertiary: rgba(var(--semi-grey-5), 1);
  --semi-color-tertiary-hover: rgba(var(--semi-grey-6), 1);
  --semi-color-tertiary-active: rgba(var(--semi-grey-7), 1);
  --semi-color-tertiary-light-default: rgba(var(--semi-grey-5), .2);
  --semi-color-tertiary-light-hover: rgba(var(--semi-grey-5), .3);
  --semi-color-tertiary-light-active: rgba(var(--semi-grey-5), .4);
  --semi-color-default: rgba(var(--semi-grey-0), 1);
  --semi-color-default-hover: rgba(var(--semi-grey-1), 1);
  --semi-color-default-active: rgba(var(--semi-grey-2), 1);
  --semi-color-info: rgba(var(--semi-blue-5), 1);
  --semi-color-info-hover: rgba(var(--semi-blue-6), 1);
  --semi-color-info-active: rgba(var(--semi-blue-7), 1);
  --semi-color-info-disabled: rgba(var(--semi-blue-2), 1);
  --semi-color-info-light-default: rgba(var(--semi-blue-5), .2);
  --semi-color-info-light-hover: rgba(var(--semi-blue-5), .3);
  --semi-color-info-light-active: rgba(var(--semi-blue-5), .4);
  --semi-color-success: rgba(var(--semi-green-5), 1);
  --semi-color-success-hover: rgba(var(--semi-green-6), 1);
  --semi-color-success-active: rgba(var(--semi-green-7), 1);
  --semi-color-success-disabled: rgba(var(--semi-green-2), 1);
  --semi-color-success-light-default: rgba(var(--semi-green-5), .2);
  --semi-color-success-light-hover: rgba(var(--semi-green-5), .3);
  --semi-color-success-light-active: rgba(var(--semi-green-5), .4);
  --semi-color-danger: rgba(var(--semi-red-5), 1);
  --semi-color-danger-hover: rgba(var(--semi-red-6), 1);
  --semi-color-danger-active: rgba(var(--semi-red-7), 1);
  --semi-color-danger-light-default: rgba(var(--semi-red-5), .2);
  --semi-color-danger-light-hover: rgba(var(--semi-red-5), .3);
  --semi-color-danger-light-active: rgba(var(--semi-red-5), .4);
  --semi-color-warning: rgba(var(--semi-orange-5), 1);
  --semi-color-warning-hover: rgba(var(--semi-orange-6), 1);
  --semi-color-warning-active: rgba(var(--semi-orange-7), 1);
  --semi-color-warning-light-default: rgba(var(--semi-orange-5), .2);
  --semi-color-warning-light-hover: rgba(var(--semi-orange-5), .3);
  --semi-color-warning-light-active: rgba(var(--semi-orange-5), .4);
  --semi-color-focus-border: rgba(var(--semi-blue-5), 1);
  --semi-color-disabled-text: rgba(var(--semi-grey-9), .35);
  --semi-color-disabled-border: rgba(var(--semi-grey-1), 1);
  --semi-color-disabled-bg: rgba(var(--semi-grey-1), 1);
  --semi-color-disabled-fill: rgba(var(--semi-grey-8), .04);
  --semi-color-link: rgba(var(--semi-blue-5), 1);
  --semi-color-link-hover: rgba(var(--semi-blue-6), 1);
  --semi-color-link-active: rgba(var(--semi-blue-7), 1);
  --semi-color-link-visited: rgba(var(--semi-blue-5), 1);
  --semi-color-nav-bg: rgba(35, 36, 41, 1);
  --semi-shadow-elevated: inset 0 0 0 1px rgba(255, 255, 255, .1), 0 4px 14px rgba(0, 0, 0, .25);
  --semi-color-overlay-bg: rgba(22, 22, 26, .6);
  --semi-color-fill-0: rgba(var(--semi-white), .12);
  --semi-color-fill-1: rgba(var(--semi-white), .16);
  --semi-color-fill-2: rgba(var(--semi-white), .20);
  --semi-color-border: rgba(var(--semi-white), .08);
  --semi-color-shadow: rgba(var(--semi-black), .04);
  --semi-color-bg-0: rgba(22, 22, 26, 1);
  --semi-color-bg-1: rgba(35, 36, 41, 1);
  --semi-color-bg-2: rgba(53, 54, 60, 1);
  --semi-color-bg-3: rgba(67, 68, 74, 1);
  --semi-color-bg-4: rgba(79, 81, 89, 1);
  --semi-color-text-0: rgba(var(--semi-grey-9), 1);
  --semi-color-text-1: rgba(var(--semi-grey-9), .8);
  --semi-color-text-2: rgba(var(--semi-grey-9), .6);
  --semi-color-text-3: rgba(var(--semi-grey-9), .35);
  --semi-border-radius-extra-small: 3px;
  --semi-border-radius-small: 3px;
  --semi-border-radius-medium: 6px;
  --semi-border-radius-large: 12px;
  --semi-border-radius-circle: 50%;
  --semi-border-radius-full: 9999px;
  --semi-color-highlight-bg: rgba(var(--semi-yellow-2), 1);
  --semi-color-highlight: rgba(var(--semi-white), 1);
}

.semi-light-scrollbar::-webkit-scrollbar, .semi-light-scrollbar *::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.semi-light-scrollbar::-webkit-scrollbar-track, .semi-light-scrollbar *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.semi-light-scrollbar::-webkit-scrollbar-corner, .semi-light-scrollbar *::-webkit-scrollbar-corner {
  background-color: rgba(0, 0, 0, 0);
}
.semi-light-scrollbar::-webkit-scrollbar-thumb, .semi-light-scrollbar *::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: transparent;
  transition: all 1s;
}
.semi-light-scrollbar:hover::-webkit-scrollbar-thumb, .semi-light-scrollbar *:hover::-webkit-scrollbar-thumb {
  background: var(--semi-color-fill-2);
}
.semi-light-scrollbar::-webkit-scrollbar-thumb:hover, .semi-light-scrollbar *::-webkit-scrollbar-thumb:hover {
  background: var(--semi-color-fill-1);
}