/* shadow */
/* sizing */
/* spacing */
.semi-skeleton-avatar, .semi-skeleton-image, .semi-skeleton-title, .semi-skeleton-button {
  background: var(--semi-color-fill-0);
  border-radius: 4px;
}
.semi-skeleton-avatar-circle {
  border-radius: 50%;
}
.semi-skeleton-avatar-extra-extra-small {
  width: 20px;
  height: 20px;
}
.semi-skeleton-avatar-extra-small {
  width: 24px;
  height: 24px;
}
.semi-skeleton-avatar-medium {
  width: 48px;
  height: 48px;
}
.semi-skeleton-avatar-small {
  width: 32px;
  height: 32px;
}
.semi-skeleton-avatar-large {
  width: 72px;
  height: 72px;
}
.semi-skeleton-avatar-extra-large {
  width: 128px;
  height: 128px;
}
.semi-skeleton-paragraph {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.semi-skeleton-paragraph li {
  background: var(--semi-color-fill-0);
  border-radius: 4px;
  width: 100%;
  height: 16px;
  margin-bottom: 10px;
}
.semi-skeleton-paragraph li:last-child {
  width: 60%;
  margin-bottom: 0;
}
.semi-skeleton-paragraph li:first-child {
  width: 100%;
}
.semi-skeleton-title {
  width: 100%;
  height: 24px;
}
.semi-skeleton-button {
  width: 115px;
  height: 32px;
}
.semi-skeleton-image {
  width: 100%;
  height: 100%;
}
.semi-skeleton-active .semi-skeleton-avatar,
.semi-skeleton-active .semi-skeleton-image,
.semi-skeleton-active .semi-skeleton-title,
.semi-skeleton-active .semi-skeleton-button,
.semi-skeleton-active .semi-skeleton-paragraph li {
  background: linear-gradient(90deg, var(--semi-color-fill-0) 25%, var(--semi-color-fill-1) 44%, var(--semi-color-fill-0) 88%);
  background-size: 400% 100%;
  animation: 1400ms skeleton-loading ease infinite;
  animation-fill-mode: forwards;
}
@keyframes skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

.semi-rtl .semi-skeleton,
.semi-portal-rtl .semi-skeleton {
  direction: rtl;
}