/* shadow */
/* sizing */
/* spacing */
.semi-upload {
  display: flex;
  flex-wrap: wrap;
}
.semi-upload-disabled {
  cursor: not-allowed;
}
.semi-upload-disabled .semi-upload-file-card {
  cursor: not-allowed;
}
.semi-upload-disabled .semi-upload-file-card-close {
  cursor: not-allowed;
}
.semi-upload-disabled .semi-upload-drag-area {
  border: none;
  cursor: not-allowed;
}
.semi-upload-disabled .semi-upload-drag-area:hover {
  background-color: var(--semi-color-tertiary-light-default);
}
.semi-upload-disabled .semi-upload-drag-area-main-text, .semi-upload-disabled .semi-upload-drag-area-sub-text, .semi-upload-disabled .semi-upload-drag-area-icon {
  cursor: not-allowed;
  color: var(--semi-color-disabled-text);
}
.semi-upload-hidden-input {
  display: none;
}
.semi-upload-hidden-input-replace {
  display: none;
}
.semi-upload-add {
  display: flex;
  align-items: center;
}
.semi-upload[x-prompt-pos=right] .semi-upload-add {
  display: inline-flex;
}
.semi-upload[x-prompt-pos=right] .semi-upload-prompt {
  display: inline-flex;
}
.semi-upload[x-prompt-pos=bottom] .semi-upload-add {
  display: flex;
}
.semi-upload[x-prompt-pos=bottom] .semi-upload-prompt {
  display: flex;
  flex-basis: 100%;
}
.semi-upload[x-prompt-pos=bottom] .semi-upload-file-list {
  flex-basis: 100%;
}
.semi-upload[x-prompt-pos=left] .semi-upload-add {
  display: inline-flex;
}
.semi-upload[x-prompt-pos=left] .semi-upload-prompt {
  display: inline-flex;
  order: -1;
}
.semi-upload[x-prompt-pos=left] .semi-upload-file-list {
  order: 2;
}
.semi-upload-file-list {
  flex-basis: 100%;
  flex-shrink: 0;
  display: block;
  margin-top: 8px;
  margin-bottom: 8px;
}
.semi-upload-file-list-main {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  gap: 8px;
  margin-bottom: 8px;
}
.semi-upload-file-list-main p {
  display: flex;
  align-items: center;
}
.semi-upload-file-list-title {
  font-size: 12px;
  line-height: 16px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: var(--semi-color-text-2);
  margin-bottom: 4px;
}
.semi-upload-file-list-title-choosen {
  margin-right: 8px;
}
.semi-upload-file-list-title-clear {
  cursor: pointer;
  color: var(--semi-color-primary);
}
.semi-upload-file-card {
  border-radius: var(--semi-border-radius-medium);
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  height: 52px;
  width: 250px;
  background-color: var(--semi-color-fill-0);
  cursor: pointer;
}
.semi-upload-file-card:hover {
  background-color: var(--semi-color-fill-1);
}
.semi-upload-file-card a {
  text-decoration: none;
}
.semi-upload-file-card-preview {
  height: 36px;
  width: 36px;
  color: var(--semi-color-text-2);
  margin: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--semi-border-radius-small);
  flex-shrink: 0;
}
.semi-upload-file-card-preview img {
  width: 36px;
  height: 36px;
  object-fit: cover;
}
.semi-upload-file-card-preview-placeholder {
  background-color: rgba(var(--semi-grey-3), 1);
  color: rgba(var(--semi-white), 1);
}
.semi-upload-file-card-show-pointer {
  cursor: pointer;
}
.semi-upload-file-card-info {
  display: flex;
  align-items: center;
}
.semi-upload-file-card-info-main {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
.semi-upload-file-card-info-main-text {
  display: flex;
  align-items: center;
  flex-basis: 100%;
}
.semi-upload-file-card-info-main-control {
  display: flex;
}
.semi-upload-file-card-info-name {
  width: 0;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  color: var(--semi-color-text-0);
}
.semi-upload-file-card-info-size {
  font-size: 12px;
  line-height: 16px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  margin-left: 8px;
  color: var(--semi-color-text-2);
  margin-top: 1px;
}
.semi-upload-file-card-info-replace {
  display: inline-flex;
  margin-left: 8px;
  color: var(--semi-color-text-2);
}
.semi-upload-file-card-info-validate-message {
  font-size: 12px;
  line-height: 16px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  display: flex;
  align-items: center;
  color: var(--semi-color-text-0);
}
.semi-upload-file-card-info-retry {
  font-size: 12px;
  line-height: 16px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: var(--semi-color-primary);
  cursor: pointer;
  margin-left: 8px;
}
.semi-upload-file-card-info .semi-progress {
  flex-basis: 100%;
  margin-top: 4px;
}
.semi-upload-file-card-close {
  margin-left: 8px;
  margin-right: 8px;
  flex-shrink: 0;
}
.semi-upload-file-card-replace {
  margin-left: 8px;
  width: 24px;
  flex-shrink: 0;
}
.semi-upload-file-card-icon-loading, .semi-upload-file-card-icon-error {
  font-size: 11px;
  margin-right: 2px;
}
.semi-upload-file-card-icon-error {
  position: relative;
  top: -1px;
}
.semi-upload-file-card-icon-loading {
  line-height: 0;
}
.semi-upload-file-card-icon-loading .semi-spin-wrapper svg {
  height: 11px;
  width: 11px;
}
.semi-upload-file-card-fail {
  background-color: var(--semi-color-danger-light-default);
}
.semi-upload-file-card-fail:hover {
  background-color: var(--semi-color-danger-light-hover);
}
.semi-upload-file-card-fail .semi-upload-file-card-info-validate-message {
  color: var(--semi-color-danger);
}
.semi-upload-picture {
  display: flex;
}
.semi-upload-picture[x-prompt-pos=bottom] {
  flex-direction: column;
}
.semi-upload-picture[x-prompt-pos=bottom] .semi-upload-prompt {
  order: 1;
}
.semi-upload-picture[x-prompt-pos=right] .semi-upload-prompt {
  order: 1;
}
.semi-upload-picture-add {
  background-color: var(--semi-color-fill-0);
  height: 96px;
  width: 96px;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed var(--semi-color-border);
  color: var(--semi-color-tertiary);
  border-radius: var(--semi-border-radius-small);
  cursor: pointer;
}
.semi-upload-picture-add:hover {
  background-color: var(--semi-color-fill-1);
}
.semi-upload-picture-add:active {
  background-color: var(--semi-color-fill-2);
}
.semi-upload-picture-add-disabled {
  cursor: not-allowed;
}
.semi-upload-picture-add-disabled:hover {
  background-color: var(--semi-color-fill-0);
}
.semi-upload-picture-add-disabled:active {
  background-color: var(--semi-color-fill-0);
}
.semi-upload-picture-file-list {
  flex-basis: inherit;
  margin-top: 0;
  margin-bottom: 0;
}
.semi-upload-picture-file-card {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 96px;
  width: 96px;
  border-radius: var(--semi-border-radius-small);
  position: relative;
  overflow: hidden;
}
.semi-upload-picture-file-card img {
  height: 96px;
  width: 96px;
  object-fit: cover;
  border-radius: var(--semi-border-radius-small);
}
.semi-upload-picture-file-card-close {
  visibility: hidden;
  display: inline-flex;
  position: absolute;
  top: 8px;
  right: 8px;
  border-radius: var(--semi-border-radius-circle);
  cursor: pointer;
  transition: all 0s;
}
.semi-upload-picture-file-card-icon-close {
  font-size: 16px;
  color: var(--semi-color-white);
}
.semi-upload-picture-file-card::before {
  visibility: hidden;
  background-color: var(--semi-color-overlay-bg);
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.semi-upload-picture-file-card-retry {
  visibility: hidden;
  background-color: var(--semi-color-white);
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--semi-color-primary);
  border-radius: var(--semi-border-radius-circle);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.semi-upload-picture-file-card-icon-retry {
  transform: scale(-1, 1);
  font-size: 13px;
}
.semi-upload-picture-file-card-replace {
  visibility: hidden;
  display: inline-flex;
  position: absolute;
  cursor: pointer;
  top: 50%;
  left: 50%;
  color: var(--semi-color-white);
  transform: translate3D(-50%, -50%, 0);
}
.semi-upload-picture-file-card-preview {
  visibility: hidden;
  display: inline-flex;
  position: absolute;
  cursor: pointer;
  top: 50%;
  left: 50%;
  transform: translate3D(-50%, -50%, 0);
}
.semi-upload-picture-file-card-pic-info {
  display: inline-flex;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 24px;
  padding: 0 10px;
  bottom: 0;
  left: 0;
  color: var(--semi-color-white);
  font-size: 12px;
  font-weight: 600;
  background: linear-gradient(0deg, rgba(22, 22, 26, 0.3) 0%, rgba(22, 22, 26, 0) 77.08%);
}
.semi-upload-picture-file-card-icon-loading, .semi-upload-picture-file-card-icon-error {
  position: absolute;
  bottom: 6px;
  right: 6px;
  color: var(--semi-color-danger);
}
.semi-upload-picture-file-card-icon-loading {
  font-size: 14px;
}
.semi-upload-picture-file-card-show-pointer {
  cursor: pointer;
}
.semi-upload-picture-file-card-error {
  outline: 1px solid var(--semi-color-danger);
}
.semi-upload-picture-file-card:hover::before {
  visibility: visible;
}
.semi-upload-picture-file-card:hover .semi-upload-picture-file-card-close {
  visibility: visible;
}
.semi-upload-picture-file-card:hover .semi-upload-picture-file-card-replace {
  visibility: visible;
}
.semi-upload-picture-file-card:hover .semi-upload-picture-file-card-retry {
  visibility: visible;
}
.semi-upload-picture-file-card:hover .semi-upload-picture-file-card-preview {
  visibility: visible;
}
.semi-upload-picture-file-card-uploading::before {
  visibility: visible;
}
.semi-upload-picture-file-card .semi-progress-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.semi-upload-drag-area {
  border-radius: var(--semi-border-radius-small);
  border: 2px dashed var(--semi-color-border);
  width: 100%;
  padding: 12px;
  background-color: var(--semi-color-tertiary-light-default);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}
.semi-upload-drag-area:hover {
  background-color: var(--semi-color-primary-light-default);
  border-color: var(--semi-color-primary);
}
.semi-upload-drag-area-custom {
  border: none;
  padding: 0;
  background-color: inherit;
}
.semi-upload-drag-area-custom:hover {
  background-color: inherit;
}
.semi-upload-drag-area-legal {
  background-color: var(--semi-color-primary-light-default);
  border-color: var(--semi-color-primary);
}
.semi-upload-drag-area-legal .semi-upload-drag-area-sub-text {
  display: none;
}
.semi-upload-drag-area-icon {
  color: var(--semi-color-primary);
}
.semi-upload-drag-area-text {
  text-align: center;
}
.semi-upload-drag-area-main-text {
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin-bottom: 4px;
  color: var(--semi-color-text-0);
}
.semi-upload-drag-area-main-text:hover {
  color: var(--semi-color-text-0);
}
.semi-upload-drag-area-main-text:active {
  color: var(--semi-color-text-0);
}
.semi-upload-drag-area-sub-text {
  cursor: pointer;
  font-size: 12px;
  line-height: 16px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: var(--semi-color-text-0);
}
.semi-upload-drag-area-sub-text:hover {
  color: var(--semi-color-text-0);
}
.semi-upload-drag-area-sub-text:active {
  color: var(--semi-color-text-0);
}
.semi-upload-drag-area-tips {
  font-size: 12px;
  line-height: 16px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
}
.semi-upload-drag-area-tips-legal {
  color: var(--semi-color-primary);
}
.semi-upload-validate-message {
  flex-basis: 100%;
  flex-shrink: 0;
  display: block;
  color: var(--semi-color-danger);
}

.semi-rtl .semi-upload,
.semi-portal-rtl .semi-upload {
  direction: rtl;
}
.semi-rtl .semi-upload-file-list-title-choosen,
.semi-portal-rtl .semi-upload-file-list-title-choosen {
  margin-right: 0;
  margin-left: 8px;
}
.semi-rtl .semi-upload-file-list-title-choosen, .semi-rtl .semi-upload-file-list-title-clear,
.semi-portal-rtl .semi-upload-file-list-title-choosen,
.semi-portal-rtl .semi-upload-file-list-title-clear {
  display: inline-block;
}
.semi-rtl .semi-upload-file-card-info-size,
.semi-portal-rtl .semi-upload-file-card-info-size {
  margin-left: 0;
  margin-right: 8px;
}
.semi-rtl .semi-upload-file-card-info-retry,
.semi-portal-rtl .semi-upload-file-card-info-retry {
  margin-left: 0;
  margin-right: 8px;
}
.semi-rtl .semi-upload-file-card-icon-loading, .semi-rtl .semi-upload-file-card-icon-error,
.semi-portal-rtl .semi-upload-file-card-icon-loading,
.semi-portal-rtl .semi-upload-file-card-icon-error {
  margin-right: 0;
  margin-left: 2px;
}
.semi-rtl .semi-upload-picture-file-card-close,
.semi-portal-rtl .semi-upload-picture-file-card-close {
  right: auto;
  left: 8px;
}
.semi-rtl .semi-upload-picture-file-card .semi-progress-circle,
.semi-portal-rtl .semi-upload-picture-file-card .semi-progress-circle {
  left: auto;
  right: 50%;
  transform: translate(50%, -50%);
}
.semi-rtl .semi-upload-picture-file-card-retry,
.semi-portal-rtl .semi-upload-picture-file-card-retry {
  left: auto;
  right: 50%;
  transform: translate(50%, -50%);
}
.semi-rtl .semi-upload-picture-file-card-icon-loading, .semi-rtl .semi-upload-picture-file-card-icon-error,
.semi-portal-rtl .semi-upload-picture-file-card-icon-loading,
.semi-portal-rtl .semi-upload-picture-file-card-icon-error {
  right: 0;
  left: 6px;
}