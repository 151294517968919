/* shadow */
/* sizing */
/* spacing */
.semi-toast {
  pointer-events: none;
}
.semi-toast-wrapper {
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
  text-align: center;
  z-index: 1010;
}
.semi-toast-content {
  pointer-events: all;
  box-shadow: var(--semi-shadow-elevated);
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: var(--semi-color-bg-3);
  border-radius: var(--semi-border-radius-medium);
  padding: 12px 8px 12px 8px;
  display: inline-flex;
  align-items: flex-start;
  justify-content: center;
  margin: 12px;
  font-weight: 600;
  color: var(--semi-color-text-0);
}
.semi-toast-content .semi-toast-close-button {
  margin-top: -2px;
  height: 20px;
}
.semi-toast-content .semi-toast-content-text {
  margin-left: 12px;
  margin-right: 12px;
  text-align: left;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.semi-toast-light.semi-toast-warning .semi-toast-content {
  background-color: var(--semi-color-warning-light-default);
  border: 1px solid var(--semi-color-warning);
}
.semi-toast-light.semi-toast-warning .semi-toast-icon-warning {
  color: var(--semi-color-warning);
}
.semi-toast-light.semi-toast-success .semi-toast-content {
  background-color: var(--semi-color-success-light-default);
  border: 1px solid var(--semi-color-success);
}
.semi-toast-light.semi-toast-success .semi-toast-icon-success {
  color: var(--semi-color-success);
}
.semi-toast-light.semi-toast-info .semi-toast-content {
  background-color: var(--semi-color-info-light-default);
  border: 1px solid var(--semi-color-info);
}
.semi-toast-light.semi-toast-info .semi-toast-icon-info {
  color: var(--semi-color-info);
}
.semi-toast-light.semi-toast-error .semi-toast-content {
  background-color: var(--semi-color-danger-light-default);
  border: 1px solid var(--semi-color-danger);
}
.semi-toast-light.semi-toast-error .semi-toast-icon-error {
  color: var(--semi-color-danger);
}
.semi-toast .semi-toast-icon-warning {
  color: var(--semi-color-warning);
}
.semi-toast .semi-toast-icon-success {
  color: var(--semi-color-success);
}
.semi-toast .semi-toast-icon-info {
  color: var(--semi-color-info);
}
.semi-toast .semi-toast-icon-error {
  color: var(--semi-color-danger);
}
.semi-toast-animation-show {
  animation: 300ms semi-toast-keyframe-toast-show cubic-bezier(0.22, 0.57, 0.02, 1.2) 0s;
  animation-fill-mode: forwards;
}
.semi-toast-animation-hide {
  animation: 300ms semi-toast-keyframe-toast-hide cubic-bezier(0.22, 0.57, 0.02, 1.2) 0s;
  animation-fill-mode: forwards;
}
@keyframes semi-toast-keyframe-toast-show {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
  }
}
@keyframes semi-toast-keyframe-toast-hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}

.semi-toast-rtl {
  direction: rtl;
}
.semi-toast-rtl .semi-toast-content .semi-toast-content-text {
  text-align: right;
  margin-left: 12px;
  margin-right: 12px;
}