/* shadow */
/* sizing */
/* spacing */
.semi-navigation {
  box-sizing: border-box;
  display: inline-flex;
  width: 240px;
  outline: none;
  overflow: hidden;
  margin: 0;
  padding-left: 8px;
  padding-right: 8px;
  user-select: none;
  transition: padding-left 100ms ease-out, width 200ms cubic-bezier(0.62, 0.05, 0.36, 0.95);
  border-right: 1px solid var(--semi-color-border);
  background-color: var(--semi-color-nav-bg);
}
.semi-navigation-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.semi-navigation-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.semi-navigation-list > .semi-navigation-item-normal {
  height: 36px;
}
.semi-navigation-list > .semi-navigation-item {
  font-weight: 600;
}
.semi-navigation-list > .semi-navigation-item > .semi-navigation-sub-title {
  font-weight: 600;
}
.semi-navigation-collapsed {
  width: 60px;
  padding-left: 8px;
  padding-right: 8px;
  transition: padding-left 100ms ease-out, width 200ms cubic-bezier(0.62, 0.05, 0.36, 0.95);
}
.semi-navigation-collapsed .semi-navigation-item-text {
  transition: opacity 0.2s cubic-bezier(0.5, -0.1, 1, 0.4);
  opacity: 0;
}
.semi-navigation-collapsed .semi-navigation-item-icon:last-child {
  transition: opacity 0.2s cubic-bezier(0.5, -0.1, 1, 0.4);
  opacity: 0;
}
.semi-navigation-sub-wrap .semi-navigation-sub-title, .semi-navigation-item {
  cursor: pointer;
  display: flex;
  border-radius: var(--semi-border-radius-small);
  padding: 8px 12px;
  box-sizing: border-box;
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: var(--semi-color-text-0);
  width: 100%;
  transition: background-color var(--semi-transition_duration-none) var(--semi-transition_function-easeIn) var(--semi-transition_delay-none);
}
.semi-navigation-sub-wrap .semi-navigation-sub-title-text, .semi-navigation-item-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: opacity 0.2s cubic-bezier(0.5, -0.1, 1, 0.4);
  opacity: 1;
}
.semi-navigation-sub-wrap .semi-navigation-sub-title-indent, .semi-navigation-item-indent {
  width: 32px;
}
.semi-navigation-sub-wrap .semi-navigation-sub-title:focus-visible, .semi-navigation-item:focus-visible {
  outline: 2px solid var(--semi-color-primary-light-active);
  outline-offset: -2px;
}
.semi-navigation-header-link, .semi-navigation-item-link {
  display: flex;
  width: 100%;
  color: inherit;
  text-decoration: none;
  align-items: center;
  justify-content: flex-start;
}
.semi-navigation-item-has-link {
  padding: 0;
}
.semi-navigation-item-has-link .semi-navigation-item-link {
  padding: 8px 12px;
}
.semi-navigation-item-sub {
  padding: 0;
}
.semi-navigation-sub-wrap > .semi-navigation-item-inner {
  width: 100%;
}
.semi-navigation-sub-wrap .semi-navigation-sub-title > .semi-navigation-item-inner {
  display: flex;
}
.semi-navigation-item-inner {
  display: flex;
  align-items: center;
  width: 100%;
  flex: 0 0 auto;
}
.semi-navigation-item-title {
  opacity: 1;
  transition: opacity 100ms 100s ease-out;
}
.semi-navigation .semi-navigation-sub-title {
  margin-bottom: 0;
}
.semi-navigation-item-icon-info {
  display: inline-flex;
  color: var(--semi-color-text-2);
  margin-right: 12px;
  min-width: 20px;
  margin-left: 0;
}
.semi-navigation-item-icon-toggle-left {
  display: inline-flex;
  color: var(--semi-color-text-2);
  margin-right: 12px;
  min-width: 20px;
}
.semi-navigation-item-icon-toggle-right {
  display: inline-flex;
  color: var(--semi-color-text-2);
  margin-left: auto;
  transition: opacity 0.2s cubic-bezier(0.5, -0.1, 1, 0.4);
  opacity: 1;
}
.semi-navigation-item-selected {
  background-color: var(--semi-color-primary-light-default);
  color: var(--semi-color-text-0);
}
.semi-navigation-item-selected .semi-navigation-item-icon:first-child {
  color: var(--semi-color-primary);
}
.semi-navigation-item-selected.semi-navigation-item-disabled {
  background-color: transparent;
  color: var(--semi-color-primary-disabled);
  cursor: not-allowed;
}
.semi-navigation-item-selected.semi-navigation-item-disabled .semi-navigation-item-icon:first-child {
  color: var(--semi-color-primary-disabled);
}
.semi-navigation-item-disabled {
  background-color: transparent;
  color: var(--semi-color-disabled-text);
  cursor: not-allowed;
}
.semi-navigation-item-disabled .semi-navigation-item-icon,
.semi-navigation-item-disabled .semi-navigation-item-icon:first-child {
  color: var(--semi-color-disabled-text);
}
.semi-navigation-inner > .semi-navigation-item {
  color: var(--semi-color-text-0);
}
.semi-navigation-item-normal:hover:not(.semi-navigation-item-selected) {
  background-color: var(--semi-color-fill-0);
  color: var(--semi-color-text-0);
}
.semi-navigation-item-normal:hover:not(.semi-navigation-item-selected) .semi-navigation-item-icon:first-child {
  color: var(--semi-color-text-2);
}
.semi-navigation-item-normal:hover.semi-navigation-item-selected {
  color: var(--semi-color-text-0);
  background-color: var(--semi-color-fill-0);
}
.semi-navigation-item-normal:hover.semi-navigation-item-selected .semi-navigation-item-icon:first-child {
  color: var(--semi-color-primary);
}
.semi-navigation-item-normal:hover.semi-navigation-item-disabled {
  background-color: transparent;
  color: var(--semi-color-disabled-text);
  cursor: not-allowed;
}
.semi-navigation-item-normal:hover.semi-navigation-item-disabled .semi-navigation-item-icon,
.semi-navigation-item-normal:hover.semi-navigation-item-disabled .semi-navigation-item-icon:first-child {
  color: var(--semi-color-disabled-text);
}
.semi-navigation-item-normal:hover.semi-navigation-item-selected.semi-navigation-item-disabled {
  background-color: transparent;
  color: var(--semi-color-primary-disabled);
  cursor: not-allowed;
}
.semi-navigation-item-normal:hover.semi-navigation-item-selected.semi-navigation-item-disabled .semi-navigation-item-icon:first-child {
  color: var(--semi-color-primary-disabled);
}
.semi-navigation-item-normal:active:not(.semi-navigation-item-selected), .semi-navigation-inner > .semi-navigation-item-normal:active:not(.semi-navigation-item-selected) {
  background-color: var(--semi-color-fill-1);
  color: var(--semi-color-text-0);
}
.semi-navigation-item-normal:active:not(.semi-navigation-item-selected) .semi-navigation-item-icon:first-child, .semi-navigation-inner > .semi-navigation-item-normal:active:not(.semi-navigation-item-selected) .semi-navigation-item-icon:first-child {
  color: var(--semi-color-text-2);
}
.semi-navigation-item-normal:active.semi-navigation-item-selected, .semi-navigation-inner > .semi-navigation-item-normal:active.semi-navigation-item-selected {
  color: var(--semi-color-text-0);
  background-color: var(--semi-color-fill-1);
}
.semi-navigation-item-normal:active.semi-navigation-item-selected .semi-navigation-item-icon:first-child, .semi-navigation-inner > .semi-navigation-item-normal:active.semi-navigation-item-selected .semi-navigation-item-icon:first-child {
  color: var(--semi-color-primary);
}
.semi-navigation-item-normal:active.semi-navigation-item-disabled, .semi-navigation-inner > .semi-navigation-item-normal:active.semi-navigation-item-disabled {
  background-color: transparent;
  color: var(--semi-color-disabled-text);
  cursor: not-allowed;
}
.semi-navigation-item-normal:active.semi-navigation-item-disabled .semi-navigation-item-icon,
.semi-navigation-item-normal:active.semi-navigation-item-disabled .semi-navigation-item-icon:first-child, .semi-navigation-inner > .semi-navigation-item-normal:active.semi-navigation-item-disabled .semi-navigation-item-icon,
.semi-navigation-inner > .semi-navigation-item-normal:active.semi-navigation-item-disabled .semi-navigation-item-icon:first-child {
  color: var(--semi-color-disabled-text);
}
.semi-navigation-sub-wrap .semi-navigation-item-inner {
  display: block;
}
.semi-navigation-sub-wrap {
  display: block;
  padding: 0;
  margin-top: 0;
  height: inherit;
}
.semi-navigation-sub-wrap .semi-navigation-sub-title {
  display: flex;
  justify-content: flex-start;
  height: 36px;
  align-items: center;
}
.semi-navigation-sub {
  font-weight: 400;
  font-size: 14px;
  list-style: none;
  outline: none;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.semi-navigation-sub .semi-navigation-item {
  color: var(--semi-color-text-0);
  background-color: transparent;
  height: 36px;
  font-weight: 400;
  width: 100%;
}
.semi-navigation-sub .semi-navigation-item:first-child {
  margin-top: 8px;
}
.semi-navigation-sub .semi-navigation-item > .semi-navigation-sub .semi-navigation-item-text:first-child {
  margin-left: 44px;
}
.semi-navigation-sub .semi-navigation-item:hover:not(.semi-navigation-sub-wrap):not(.semi-navigation-item-selected):not(.semi-navigation-item-disabled) {
  background-color: var(--semi-color-fill-0);
  color: var(--semi-color-text-0);
}
.semi-navigation-sub .semi-navigation-item:hover:not(.semi-navigation-sub-wrap):not(.semi-navigation-item-selected):not(.semi-navigation-item-disabled) .semi-navigation-item-icon:first-child {
  color: var(--semi-color-text-2);
}
.semi-navigation-sub .semi-navigation-item:hover.semi-navigation-item-disabled {
  background-color: transparent;
  color: var(--semi-color-disabled-text);
  cursor: not-allowed;
}
.semi-navigation-sub .semi-navigation-item:hover.semi-navigation-item-disabled .semi-navigation-item-icon,
.semi-navigation-sub .semi-navigation-item:hover.semi-navigation-item-disabled .semi-navigation-item-icon:first-child {
  color: var(--semi-color-disabled-text);
}
.semi-navigation-sub .semi-navigation-item:hover.semi-navigation-item-selected {
  background-color: var(--semi-color-primary-light-default);
  color: var(--semi-color-text-0);
}
.semi-navigation-sub .semi-navigation-item:hover.semi-navigation-item-selected .semi-navigation-item-icon:first-child {
  color: var(--semi-color-primary);
}
.semi-navigation-sub .semi-navigation-item:active:not(.semi-navigation-sub-wrap):not(.semi-navigation-item-selected):not(.semi-navigation-item-disabled) {
  background-color: var(--semi-color-fill-1);
  color: var(--semi-color-text-0);
}
.semi-navigation-sub .semi-navigation-item:active:not(.semi-navigation-sub-wrap):not(.semi-navigation-item-selected):not(.semi-navigation-item-disabled) .semi-navigation-item-icon:first-child {
  color: var(--semi-color-text-2);
}
.semi-navigation-sub .semi-navigation-item:active.semi-navigation-item-disabled {
  background-color: transparent;
  color: var(--semi-color-disabled-text);
  cursor: not-allowed;
}
.semi-navigation-sub .semi-navigation-item:active.semi-navigation-item-disabled .semi-navigation-item-icon,
.semi-navigation-sub .semi-navigation-item:active.semi-navigation-item-disabled .semi-navigation-item-icon:first-child {
  color: var(--semi-color-disabled-text);
}
.semi-navigation-sub .semi-navigation-item:active.semi-navigation-item-selected {
  background-color: var(--semi-color-primary-light-default);
  color: var(--semi-color-text-0);
}
.semi-navigation-sub .semi-navigation-item:active.semi-navigation-item-selected .semi-navigation-item-icon:first-child {
  color: var(--semi-color-primary);
}
.semi-navigation-sub .semi-navigation-item-selected {
  background-color: var(--semi-color-primary-light-default);
  color: var(--semi-color-text-0);
}
.semi-navigation-sub .semi-navigation-item-selected .semi-navigation-item-icon:first-child {
  color: var(--semi-color-primary);
}
.semi-navigation-sub .semi-navigation-item-selected.semi-navigation-item-disabled {
  cursor: not-allowed;
  background-color: var(--semi-color-primary-light-default);
  color: var(--semi-color-disabled-text);
}
.semi-navigation-sub .semi-navigation-item-disabled {
  background-color: transparent;
  color: var(--semi-color-disabled-text);
  cursor: not-allowed;
}
.semi-navigation-sub .semi-navigation-item-disabled .semi-navigation-item-icon,
.semi-navigation-sub .semi-navigation-item-disabled .semi-navigation-item-icon:first-child {
  color: var(--semi-color-disabled-text);
}
.semi-navigation-sub .semi-navigation-sub-wrap {
  height: inherit;
}
.semi-navigation-icon-rotate-0 {
  transition: transform 200ms ease-in-out;
  transform: rotate(0);
}
.semi-navigation-icon-rotate-180 {
  transition: transform 200ms ease-in-out;
  transform: rotate(-180deg);
}

/* Header、Footer-Common */
.semi-navigation-header {
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
}
.semi-navigation-header-logo {
  margin-left: 0;
  margin-right: 8px;
  display: inline-flex;
}
.semi-navigation-header-logo > .semi-icon, .semi-navigation-header-logo > img {
  width: 36px;
  height: 36px;
  object-fit: scale-down;
}
.semi-navigation-header-text {
  font-size: 18px;
  line-height: 24px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  display: inline-flex;
  color: var(--semi-color-text-0);
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: opacity 0.2s cubic-bezier(0.5, -0.1, 1, 0.4);
  opacity: 1;
}
.semi-navigation-footer {
  box-sizing: border-box;
  padding: 16px 24px;
  display: inline-flex;
  align-items: center;
}
.semi-navigation-footer .semi-navigation-collapse-btn {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.semi-navigation-collapsed .semi-navigation-header {
  justify-content: center;
}
.semi-navigation-collapsed .semi-navigation-header .semi-navigation-header-logo {
  margin-right: 0;
  width: 100%;
}
.semi-navigation-collapsed .semi-navigation-header .semi-navigation-header-logo > .semi-icon, .semi-navigation-collapsed .semi-navigation-header .semi-navigation-header-logo > img {
  width: 36px;
  max-width: 100%;
  max-height: 100%;
}
.semi-navigation-collapsed .semi-navigation-header .semi-navigation-header-text {
  transition: opacity 0.2s cubic-bezier(0.5, -0.1, 1, 0.4);
  opacity: 0;
}

.semi-navigation-vertical .semi-navigation-list > .semi-navigation-item-selected:not(.semi-navigation-item-disabled).semi-navigation-item-normal:hover .semi-navigation-item-icon:first-child {
  color: var(--semi-color-primary);
}
.semi-navigation-vertical .semi-navigation-list > .semi-navigation-sub-wrap > .semi-navigation-sub-title {
  color: var(--semi-color-text-0);
  background-color: transparent;
}
.semi-navigation-vertical .semi-navigation-list > .semi-navigation-sub-wrap > .semi-navigation-sub-title-selected {
  font-weight: 600;
  background-color: var(--semi-color-primary-light-default);
  color: var(--semi-color-text-0);
  background-color: transparent;
}
.semi-navigation-vertical .semi-navigation-list > .semi-navigation-sub-wrap > .semi-navigation-sub-title-selected .semi-navigation-item-icon:first-child {
  color: var(--semi-color-primary);
}
.semi-navigation-vertical .semi-navigation-list > .semi-navigation-sub-wrap > .semi-navigation-sub-title-selected.semi-navigation-sub-title-disabled {
  background-color: transparent;
  color: var(--semi-color-primary-disabled);
  cursor: not-allowed;
}
.semi-navigation-vertical .semi-navigation-list > .semi-navigation-sub-wrap > .semi-navigation-sub-title-selected.semi-navigation-sub-title-disabled .semi-navigation-item-icon:first-child {
  color: var(--semi-color-primary-disabled);
}
.semi-navigation-vertical .semi-navigation-list > .semi-navigation-sub-wrap > .semi-navigation-sub-title-disabled {
  font-weight: 600;
  background-color: transparent;
  color: var(--semi-color-disabled-text);
  cursor: not-allowed;
}
.semi-navigation-vertical .semi-navigation-list > .semi-navigation-sub-wrap > .semi-navigation-sub-title-disabled .semi-navigation-item-icon,
.semi-navigation-vertical .semi-navigation-list > .semi-navigation-sub-wrap > .semi-navigation-sub-title-disabled .semi-navigation-item-icon:first-child {
  color: var(--semi-color-disabled-text);
}
.semi-navigation-vertical .semi-navigation-list > .semi-navigation-sub-wrap > .semi-navigation-sub-title:hover:not(.semi-navigation-sub-title-selected) {
  background-color: var(--semi-color-fill-0);
  color: var(--semi-color-text-0);
}
.semi-navigation-vertical .semi-navigation-list > .semi-navigation-sub-wrap > .semi-navigation-sub-title:hover:not(.semi-navigation-sub-title-selected) .semi-navigation-item-icon:first-child {
  color: var(--semi-color-text-2);
}
.semi-navigation-vertical .semi-navigation-list > .semi-navigation-sub-wrap > .semi-navigation-sub-title:hover.semi-navigation-sub-title-selected {
  color: var(--semi-color-text-0);
  background-color: var(--semi-color-fill-0);
}
.semi-navigation-vertical .semi-navigation-list > .semi-navigation-sub-wrap > .semi-navigation-sub-title:hover.semi-navigation-sub-title-selected .semi-navigation-item-icon:first-child {
  color: var(--semi-color-primary);
}
.semi-navigation-vertical .semi-navigation-list > .semi-navigation-sub-wrap > .semi-navigation-sub-title:active:not(.semi-navigation-sub-title-selected) {
  background-color: var(--semi-color-fill-1);
  color: var(--semi-color-text-0);
}
.semi-navigation-vertical .semi-navigation-list > .semi-navigation-sub-wrap > .semi-navigation-sub-title:active:not(.semi-navigation-sub-title-selected) .semi-navigation-item-icon:first-child {
  color: var(--semi-color-text-2);
}
.semi-navigation-vertical .semi-navigation-list > .semi-navigation-sub-wrap > .semi-navigation-sub-title:active.semi-navigation-sub-title-selected {
  color: var(--semi-color-text-0);
  background-color: var(--semi-color-fill-1);
}
.semi-navigation-vertical .semi-navigation-list > .semi-navigation-sub-wrap > .semi-navigation-sub-title:active.semi-navigation-sub-title-selected .semi-navigation-item-icon:first-child {
  color: var(--semi-color-primary);
}
.semi-navigation-vertical .semi-navigation-list > .semi-navigation-sub-wrap > .semi-navigation-sub-title:hover.semi-navigation-sub-title-disabled:not(.semi-navigation-sub-title-selected), .semi-navigation-vertical .semi-navigation-list > .semi-navigation-sub-wrap > .semi-navigation-sub-title:active.semi-navigation-sub-title-disabled:not(.semi-navigation-sub-title-selected) {
  background-color: transparent;
  color: var(--semi-color-disabled-text);
  cursor: not-allowed;
}
.semi-navigation-vertical .semi-navigation-list > .semi-navigation-sub-wrap > .semi-navigation-sub-title:hover.semi-navigation-sub-title-disabled:not(.semi-navigation-sub-title-selected) .semi-navigation-item-icon,
.semi-navigation-vertical .semi-navigation-list > .semi-navigation-sub-wrap > .semi-navigation-sub-title:hover.semi-navigation-sub-title-disabled:not(.semi-navigation-sub-title-selected) .semi-navigation-item-icon:first-child, .semi-navigation-vertical .semi-navigation-list > .semi-navigation-sub-wrap > .semi-navigation-sub-title:active.semi-navigation-sub-title-disabled:not(.semi-navigation-sub-title-selected) .semi-navigation-item-icon,
.semi-navigation-vertical .semi-navigation-list > .semi-navigation-sub-wrap > .semi-navigation-sub-title:active.semi-navigation-sub-title-disabled:not(.semi-navigation-sub-title-selected) .semi-navigation-item-icon:first-child {
  color: var(--semi-color-disabled-text);
}
.semi-navigation-vertical .semi-navigation-list > .semi-navigation-sub-wrap > .semi-navigation-sub-title:hover.semi-navigation-sub-title-disabled.semi-navigation-sub-title-selected, .semi-navigation-vertical .semi-navigation-list > .semi-navigation-sub-wrap > .semi-navigation-sub-title:active.semi-navigation-sub-title-disabled.semi-navigation-sub-title-selected {
  background-color: transparent;
  color: var(--semi-color-primary-disabled);
  cursor: not-allowed;
}
.semi-navigation-vertical .semi-navigation-list > .semi-navigation-sub-wrap > .semi-navigation-sub-title:hover.semi-navigation-sub-title-disabled.semi-navigation-sub-title-selected .semi-navigation-item-icon:first-child, .semi-navigation-vertical .semi-navigation-list > .semi-navigation-sub-wrap > .semi-navigation-sub-title:active.semi-navigation-sub-title-disabled.semi-navigation-sub-title-selected .semi-navigation-item-icon:first-child {
  color: var(--semi-color-primary-disabled);
}
.semi-navigation-vertical .semi-navigation-item:last-of-type {
  margin-bottom: 0;
}
.semi-navigation-vertical .semi-navigation-inner {
  flex-direction: column;
}
.semi-navigation-vertical .semi-navigation-header-list-outer {
  height: 100%;
}
.semi-navigation-vertical .semi-navigation-list-wrapper {
  padding-top: 12px;
  overflow-y: auto;
  overflow-x: hidden;
}
.semi-navigation-vertical .semi-navigation-header {
  padding-top: 32px;
  padding-bottom: 36px;
  padding-left: 5.5px;
  padding-right: 8px;
  width: 100%;
}
.semi-navigation-vertical .semi-navigation-header-collapsed {
  padding-left: 5.5px;
  padding-right: 0;
  transition: padding-left 100ms ease-out, width 200ms cubic-bezier(0.62, 0.05, 0.36, 0.95);
}
.semi-navigation-vertical .semi-navigation-footer {
  color: var(--semi-color-text-2);
  padding-left: 8px;
  padding-right: 8px;
}
.semi-navigation-vertical .semi-navigation-footer .semi-navigation-collapse-btn .semi-button-content-right {
  margin-left: 12px;
  opacity: 1;
  transition: opacity 0.2s cubic-bezier(0.5, -0.1, 1, 0.4);
}
.semi-navigation-vertical .semi-navigation-footer .semi-navigation-collapse-btn > .semi-button {
  padding-left: 8px;
  padding-right: 8px;
}
.semi-navigation-vertical .semi-navigation-footer-collapsed {
  justify-content: center;
}
.semi-navigation-vertical .semi-navigation-footer-collapsed .semi-navigation-collapse-btn {
  width: 100%;
}
.semi-navigation-vertical .semi-navigation-footer-collapsed .semi-navigation-collapse-btn .semi-button-content-right {
  opacity: 0;
  transition: opacity 0.2s cubic-bezier(0.5, -0.1, 1, 0.4);
}

.semi-navigation-horizontal {
  width: 100%;
  height: 60px;
  border-right: none;
  border-bottom: 1px solid var(--semi-color-border);
  padding-left: 24px;
  padding-right: 24px;
}
.semi-navigation-horizontal .semi-navigation-inner {
  flex-direction: row;
}
.semi-navigation-horizontal .semi-navigation-header-list-outer {
  display: inline-flex;
  align-items: center;
}
.semi-navigation-horizontal .semi-navigation-header-list-outer-collapsed {
  align-items: baseline;
}
.semi-navigation-horizontal .semi-navigation-header {
  width: inherit;
  margin-right: 24px;
}
.semi-navigation-horizontal .semi-navigation-list {
  display: inline-flex;
  align-items: center;
}
.semi-navigation-horizontal .semi-navigation-list .semi-navigation-item {
  margin-bottom: 0;
  color: var(--semi-color-text-2);
  background-color: transparent;
}
.semi-navigation-horizontal .semi-navigation-list .semi-navigation-item .semi-navigation-item-icon:first-child {
  color: var(--semi-color-text-2);
}
.semi-navigation-horizontal .semi-navigation-list .semi-navigation-item-selected {
  color: var(--semi-color-text-0);
  background-color: transparent;
}
.semi-navigation-horizontal .semi-navigation-list .semi-navigation-item-selected .semi-navigation-item-icon:first-child {
  color: var(--semi-color-text-0);
}
.semi-navigation-horizontal .semi-navigation-list .semi-navigation-item-disabled {
  color: var(--semi-color-disabled-text);
  background-color: transparent;
  cursor: not-allowed;
}
.semi-navigation-horizontal .semi-navigation-list .semi-navigation-item-disabled .semi-navigation-item-icon,
.semi-navigation-horizontal .semi-navigation-list .semi-navigation-item-disabled .semi-navigation-item-icon:first-child {
  color: var(--semi-color-disabled-text);
}
.semi-navigation-horizontal .semi-navigation-list .semi-navigation-item-normal:hover:not(.semi-navigation-item-selected) {
  color: var(--semi-color-text-1);
  background-color: transparent;
}
.semi-navigation-horizontal .semi-navigation-list .semi-navigation-item-normal:hover:not(.semi-navigation-item-selected) .semi-navigation-item-icon:first-child {
  color: var(--semi-color-text-1);
}
.semi-navigation-horizontal .semi-navigation-list .semi-navigation-item-normal:hover:not(.semi-navigation-item-selected) .semi-navigation-item-text {
  color: var(--semi-color-text-1);
  background-color: transparent;
}
.semi-navigation-horizontal .semi-navigation-list .semi-navigation-item-normal:active:not(.semi-navigation-item-selected) {
  color: var(--semi-color-text-0);
  background-color: transparent;
}
.semi-navigation-horizontal .semi-navigation-list .semi-navigation-item-normal:active:not(.semi-navigation-item-selected) .semi-navigation-item-icon:first-child {
  color: var(--semi-color-text-0);
}
.semi-navigation-horizontal .semi-navigation-list .semi-navigation-item-normal:hover.semi-navigation-item-disabled, .semi-navigation-horizontal .semi-navigation-list .semi-navigation-item-normal:active.semi-navigation-item-disabled {
  color: var(--semi-color-disabled-text);
  background-color: transparent;
  cursor: not-allowed;
}
.semi-navigation-horizontal .semi-navigation-list .semi-navigation-item-normal:hover.semi-navigation-item-disabled .semi-navigation-item-icon,
.semi-navigation-horizontal .semi-navigation-list .semi-navigation-item-normal:hover.semi-navigation-item-disabled .semi-navigation-item-icon:first-child, .semi-navigation-horizontal .semi-navigation-list .semi-navigation-item-normal:active.semi-navigation-item-disabled .semi-navigation-item-icon,
.semi-navigation-horizontal .semi-navigation-list .semi-navigation-item-normal:active.semi-navigation-item-disabled .semi-navigation-item-icon:first-child {
  color: var(--semi-color-disabled-text);
}
.semi-navigation-horizontal .semi-navigation-list .semi-navigation-item-normal:hover.semi-navigation-item-disabled .semi-navigation-item-text, .semi-navigation-horizontal .semi-navigation-list .semi-navigation-item-normal:active.semi-navigation-item-disabled .semi-navigation-item-text {
  color: var(--semi-color-disabled-text);
  background-color: transparent;
}
.semi-navigation-horizontal .semi-navigation-list .semi-navigation-item:not(:last-of-type) {
  margin-right: 8px;
}
.semi-navigation-horizontal .semi-navigation-list .semi-navigation-sub-title .semi-navigation-item-text {
  color: var(--semi-color-text-2);
  background-color: transparent;
}
.semi-navigation-horizontal .semi-navigation-list .semi-navigation-sub-title-selected .semi-navigation-item-icon:first-child,
.semi-navigation-horizontal .semi-navigation-list .semi-navigation-sub-title-selected .semi-navigation-item-text {
  color: var(--semi-color-text-0);
  background-color: transparent;
}
.semi-navigation-horizontal .semi-navigation-list .semi-navigation-sub-title-disabled {
  cursor: not-allowed;
}
.semi-navigation-horizontal .semi-navigation-list .semi-navigation-sub-title-disabled .semi-navigation-item-icon:first-child,
.semi-navigation-horizontal .semi-navigation-list .semi-navigation-sub-title-disabled .semi-navigation-item-text {
  color: var(--semi-color-disabled-text);
  background-color: transparent;
}
.semi-navigation-horizontal .semi-navigation-item-inner {
  width: auto;
}
.semi-navigation-horizontal .semi-navigation-item-icon:last-child {
  margin-left: 8px;
}
.semi-navigation-horizontal .semi-navigation-item-icon:first-child {
  margin-right: 8px;
}
.semi-navigation-horizontal .semi-navigation-item {
  width: auto;
}
.semi-navigation-horizontal .semi-navigation-item-collapsed {
  word-wrap: none;
  text-overflow: ellipsis;
}
.semi-navigation-horizontal .semi-navigation-footer {
  border-top: none;
  padding-right: 0;
}
.semi-navigation-horizontal .semi-navigation-footer-collapsed {
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

.semi-navigation-popover .semi-navigation-sub-title {
  width: 100%;
}
.semi-navigation-popover .semi-navigation-item-selected {
  font-weight: normal;
}

.semi-dropdown-item .semi-navigation-sub-title {
  box-sizing: border-box;
  padding: 8px 12px;
  width: 100%;
}
.semi-dropdown-item.semi-navigation-item {
  margin-top: 0;
  margin-bottom: 0;
  min-width: 150px;
}

.semi-dropdown-menu .semi-navigation-item-sub {
  padding: 0;
}

.semi-rtl .semi-navigation,
.semi-portal-rtl .semi-navigation {
  direction: rtl;
  border-right: 0;
  border-left: 1px solid var(--semi-color-border);
  transition: padding-right 100ms ease-out, width 200ms cubic-bezier(0.62, 0.05, 0.36, 0.95);
}
.semi-rtl .semi-navigation-collapsed,
.semi-portal-rtl .semi-navigation-collapsed {
  transition: padding-right 100ms ease-out, width 200ms cubic-bezier(0.62, 0.05, 0.36, 0.95);
}
.semi-rtl .semi-navigation-item-icon:first-child,
.semi-portal-rtl .semi-navigation-item-icon:first-child {
  margin-right: 0;
  margin-left: 12px;
}
.semi-rtl .semi-navigation-item-icon:last-child,
.semi-portal-rtl .semi-navigation-item-icon:last-child {
  margin-left: 0;
  margin-right: auto;
}
.semi-rtl .semi-navigation-sub .semi-navigation-item > .semi-rtl .semi-navigation-sub .semi-navigation-item-text:first-child,
.semi-rtl .semi-navigation-sub .semi-navigation-item > .semi-portal-rtl .semi-navigation-sub .semi-navigation-item-text:first-child,
.semi-portal-rtl .semi-navigation-sub .semi-navigation-item > .semi-rtl .semi-navigation-sub .semi-navigation-item-text:first-child,
.semi-portal-rtl .semi-navigation-sub .semi-navigation-item > .semi-portal-rtl .semi-navigation-sub .semi-navigation-item-text:first-child {
  margin-left: auto;
  margin-right: 44px;
}
.semi-rtl .semi-navigation-sub .semi-navigation-item > .semi-navigation-item-icon:first-child,
.semi-portal-rtl .semi-navigation-sub .semi-navigation-item > .semi-navigation-item-icon:first-child {
  margin-right: 12px;
}
.semi-rtl .semi-navigation-header,
.semi-portal-rtl .semi-navigation-header {
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
}
.semi-rtl .semi-navigation-header-logo,
.semi-portal-rtl .semi-navigation-header-logo {
  margin-left: 8px;
  margin-right: 0;
  display: inline-flex;
}
.semi-rtl .semi-navigation-collapsed,
.semi-portal-rtl .semi-navigation-collapsed {
  direction: rtl;
}
.semi-rtl .semi-navigation-collapsed .semi-navigation-header .semi-navigation-header-logo,
.semi-portal-rtl .semi-navigation-collapsed .semi-navigation-header .semi-navigation-header-logo {
  margin-right: auto;
  margin-left: 0;
}
.semi-rtl .semi-navigation-vertical,
.semi-portal-rtl .semi-navigation-vertical {
  direction: rtl;
}
.semi-rtl .semi-navigation-vertical .semi-navigation-header,
.semi-portal-rtl .semi-navigation-vertical .semi-navigation-header {
  padding-right: 5.5px;
  padding-left: 8px;
}
.semi-rtl .semi-navigation-vertical .semi-navigation-header-collapsed,
.semi-portal-rtl .semi-navigation-vertical .semi-navigation-header-collapsed {
  padding-right: 5.5px;
  padding-left: 0;
  transition: padding-right 100ms ease-out, width 200ms cubic-bezier(0.62, 0.05, 0.36, 0.95);
}
.semi-rtl .semi-navigation-vertical .semi-navigation-footer .semi-navigation-collapse-btn .semi-button-content-right,
.semi-portal-rtl .semi-navigation-vertical .semi-navigation-footer .semi-navigation-collapse-btn .semi-button-content-right {
  margin-left: auto;
  margin-right: 12px;
  transition: opacity 0.2s cubic-bezier(0.5, -0.1, 1, 0.4);
}
.semi-rtl .semi-navigation-horizontal,
.semi-portal-rtl .semi-navigation-horizontal {
  direction: rtl;
  border-right: auto;
  border-left: none;
  padding-left: 24px;
  padding-right: 24px;
}
.semi-rtl .semi-navigation-horizontal .semi-navigation-header,
.semi-portal-rtl .semi-navigation-horizontal .semi-navigation-header {
  margin-right: auto;
  margin-left: 24px;
}
.semi-rtl .semi-navigation-horizontal .semi-navigation-list .semi-navigation-item:not(:last-of-type),
.semi-portal-rtl .semi-navigation-horizontal .semi-navigation-list .semi-navigation-item:not(:last-of-type) {
  margin-right: auto;
  margin-left: 8px;
}
.semi-rtl .semi-navigation-horizontal .semi-navigation-item-icon:last-child,
.semi-portal-rtl .semi-navigation-horizontal .semi-navigation-item-icon:last-child {
  margin-left: auto;
  margin-right: 8px;
}
.semi-rtl .semi-navigation-horizontal .semi-navigation-item-icon:first-child,
.semi-portal-rtl .semi-navigation-horizontal .semi-navigation-item-icon:first-child {
  margin-right: auto;
  margin-left: 8px;
}
.semi-rtl .semi-navigation-horizontal .semi-navigation-footer,
.semi-portal-rtl .semi-navigation-horizontal .semi-navigation-footer {
  padding-right: auto;
  padding-left: 0;
}