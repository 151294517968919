/* shadow */
/* sizing */
/* spacing */
.semi-slider {
  padding: 0px 13px;
  margin: 0px;
}
.semi-slider-wrapper {
  box-sizing: border-box;
  position: relative;
  height: 32px;
  width: 100%;
  display: inline-block;
  vertical-align: bottom;
}
.semi-slider-rail {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  position: absolute;
  height: 4px;
  cursor: pointer;
  touch-action: none;
  background-color: var(--semi-color-fill-0);
  width: 100%;
  border-radius: var(--semi-border-radius-small);
  top: 14px;
}
.semi-slider-handle {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 0 1px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  touch-action: none;
  position: absolute;
  width: 24px;
  height: 24px;
  margin-top: 4px;
  background-color: var(--semi-color-white);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color var(--semi-transition_duration-none) var(--semi-transition_function-easeIn) var(--semi-transition_delay-none);
  transform: var(--semi-transform_scale-small) translateX(-50%) translateY(0px);
}
.semi-slider-handle:focus-visible {
  outline: 2px solid var(--semi-color-primary-light-active);
}
.semi-slider-handle:hover {
  background-color: var(--semi-color-white);
}
.semi-slider-handle-clicked {
  border: solid 1px var(--semi-color-focus-border);
  cursor: grabbing;
}
.semi-slider-track {
  height: 4px;
  background: var(--semi-color-primary);
  border-radius: var(--semi-border-radius-small);
  position: absolute;
  cursor: pointer;
  top: 14px;
}
.semi-slider-tooltip {
  position: absolute;
  top: -40px;
}
.semi-slider-dots {
  width: 100%;
  background: transparent;
}
.semi-slider-dot {
  position: absolute;
  top: 14px;
  width: 4px;
  height: 4px;
  background-color: var(--semi-color-white);
  border-radius: 50%;
  cursor: pointer;
  transform: translateX(0px);
}
.semi-slider-dot-active {
  background-color: var(--semi-color-white);
}
.semi-slider-marks {
  position: absolute;
  top: 23px;
  left: 0px;
  width: 100%;
  font-size: 14px;
}
.semi-slider-mark {
  position: absolute;
  display: inline-block;
  color: var(--semi-color-text-2);
  text-align: center;
  cursor: pointer;
  transform: translate(-50%, 0);
}
.semi-slider-marks-reverse {
  position: absolute;
  top: 23px;
  left: 0px;
  width: 100%;
  font-size: 14px;
}
.semi-slider-mark-reverse {
  position: absolute;
  display: inline-block;
  color: var(--semi-color-text-2);
  text-align: center;
  cursor: pointer;
  transform: translate(-50%, 0) rotate(-180deg);
}
.semi-slider-boundary {
  position: relative;
  font-size: 12px;
  color: var(--semi-color-text-0);
  visibility: hidden;
  top: 30px;
}
.semi-slider-boundary span {
  position: absolute;
  display: inline-block;
}
.semi-slider-boundary-min {
  left: 0px;
}
.semi-slider-boundary-max {
  right: 0px;
}
.semi-slider-boundary-show {
  visibility: visible;
}

.semi-slider-vertical-wrapper {
  width: 4px;
  height: 100%;
}
.semi-slider-vertical-wrapper .semi-slider-track {
  width: 4px;
}
.semi-slider-vertical-wrapper .semi-slider-marks {
  height: 100%;
  margin-top: -30px;
  margin-left: 29px;
}
.semi-slider-vertical-wrapper .semi-slider-marks-reverse {
  height: 100%;
  margin-top: -30px;
  margin-left: -26px;
}
.semi-slider-vertical-wrapper .semi-slider-rail {
  width: 4px;
  height: 100%;
  top: 0px;
}
.semi-slider-vertical-wrapper .semi-slider-handle {
  margin-top: 0px;
  margin-left: -10px;
  transform: var(--semi-transform_scale-small) translateY(-50%) translateX(0px);
}
.semi-slider-vertical-wrapper .semi-slider-dot {
  transform: translateY(0px);
}

.semi-slider-disabled {
  cursor: not-allowed;
}
.semi-slider-disabled .semi-slider-handle {
  cursor: not-allowed;
  box-shadow: none;
  border: 1px var(--semi-color-border) solid;
}
.semi-slider-disabled .semi-slider-handle:hover {
  background-color: var(--semi-color-white);
}
.semi-slider-disabled .semi-slider-rail {
  cursor: not-allowed;
}
.semi-slider-disabled .semi-slider-track {
  cursor: not-allowed;
  background-color: var(--semi-color-primary-disabled);
}
.semi-slider-disabled .semi-slider-dot {
  cursor: not-allowed;
  background-color: var(--semi-color-white);
  border-color: var(--semi-color-white);
  box-shadow: none;
}

.semi-slider-handle-tooltip {
  text-align: center;
}

.semi-slider-reverse {
  transform: rotate(180deg);
}