/* shadow */
/* sizing */
/* spacing */
.semi-tree-search-wrapper {
  padding: 8px 12px;
}

.semi-tree-wrapper {
  display: flex;
  flex-direction: column;
}

.semi-tree-option-list {
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  flex: 1;
  padding: 8px 0;
}
.semi-tree-option-list ul,
.semi-tree-option-list li {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.semi-tree-option-list li.semi-tree-option {
  box-sizing: border-box;
  padding-top: 4px;
  padding-bottom: 4px;
}
.semi-tree-option-list li > .semi-tree-option-label {
  list-style-type: none;
  padding: 0;
}
.semi-tree-option-list .semi-tree-option-expand-icon,
.semi-tree-option-list .semi-tree-option-empty-icon {
  box-sizing: border-box;
  width: 12px;
  color: var(--semi-color-text-2);
  margin-right: 8px;
  display: flex;
  flex-shrink: 0;
}
.semi-tree-option-list .semi-tree-option {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color var(--semi-transition_duration-none) var(--semi-transition_function-easeIn) var(--semi-transition_delay-none);
  transform: scale(var(--semi-transform_scale-none));
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  word-break: break-word;
  color: var(--semi-color-text-0);
  position: relative;
}
.semi-tree-option-list .semi-tree-option-label {
  display: flex;
  align-items: center;
}
.semi-tree-option-list .semi-tree-option-label > .semi-icon {
  margin-right: 8px;
}
.semi-tree-option-list .semi-tree-option-label .semi-checkbox {
  margin-right: 8px;
}
.semi-tree-option-list .semi-tree-option-label-text {
  display: block;
  flex: 1;
}
.semi-tree-option-list .semi-tree-option-ellipsis .semi-tree-option-label-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.semi-tree-option-list .semi-tree-option-label-text,
.semi-tree-option-list .semi-tree-option .semi-checkbox-addon {
  border-radius: var(--semi-border-radius-small);
}
.semi-tree-option-list .semi-tree-option-label-text:hover,
.semi-tree-option-list .semi-tree-option .semi-checkbox-addon:hover {
  background-color: var(--semi-color-fill-0);
}
.semi-tree-option-list .semi-tree-option-label-text:active,
.semi-tree-option-list .semi-tree-option .semi-checkbox-addon:active {
  background-color: var(--semi-color-fill-1);
}
.semi-tree-option-list .semi-tree-option-item-icon {
  color: var(--semi-color-text-2);
}
.semi-tree-option-list .semi-tree-option-active .semi-tree-option-label-text {
  background-color: var(--semi-color-primary-light-default);
}
.semi-tree-option-list .semi-tree-option-active:hover, .semi-tree-option-list .semi-tree-option-active:active {
  background-color: transparent;
}
.semi-tree-option-list .semi-tree-option-selected .semi-tree-option-label {
  background-color: var(--semi-color-primary-light-default);
}
.semi-tree-option-list .semi-tree-option-selected .semi-tree-option-label:hover, .semi-tree-option-list .semi-tree-option-selected .semi-tree-option-label:active {
  background-color: var(--semi-color-primary-light-default);
}
.semi-tree-option-list .semi-tree-option-collapsed .semi-tree-option-expand-icon {
  transform: rotate(270deg);
}
.semi-tree-option-list .semi-tree-option-highlight,
.semi-tree-option-list .semi-tree-option-highlight .semi-checkbox-addon, .semi-tree-option-list .semi-tree-option-filtered,
.semi-tree-option-list .semi-tree-option-filtered .semi-checkbox-addon {
  font-weight: 600;
  color: var(--semi-color-primary);
}
.semi-tree-option-list .semi-tree-option-hidden {
  display: none;
}
.semi-tree-option-list .semi-tree-option-disabled .semi-tree-option-label {
  color: var(--semi-color-disabled-text);
}
.semi-tree-option-list .semi-tree-option-fullLabel-draggable, .semi-tree-option-list .semi-tree-option-draggable {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  /* Required to make elements draggable in old WebKit */
  -khtml-user-drag: element;
  -webkit-user-drag: element;
}
.semi-tree-option-list .semi-tree-option-draggable {
  box-sizing: border-box;
  border-left: 2px solid transparent;
  margin-top: -2px;
}
.semi-tree-option-list .semi-tree-option-draggable .semi-tree-option-label {
  border-top: 2px transparent solid;
  border-bottom: 2px transparent solid;
}
.semi-tree-option-list .semi-tree-option-draggable .semi-tree-option-drag-over-gap-top {
  border-top: 2px var(--semi-color-primary) solid;
}
.semi-tree-option-list .semi-tree-option-draggable .semi-tree-option-drag-over-gap-bottom {
  border-bottom: 2px var(--semi-color-primary) solid;
}
.semi-tree-option-list .semi-tree-option-fullLabel-draggable.semi-tree-option-fullLabel-drag-over-gap-top {
  border-top: 2px var(--semi-color-primary) solid;
}
.semi-tree-option-list .semi-tree-option-fullLabel-draggable.semi-tree-option-fullLabel-drag-over-gap-bottom {
  border-bottom: 2px var(--semi-color-primary) solid;
}
.semi-tree-option-list .semi-tree-option-drag-over.semi-tree-option-draggable, .semi-tree-option-list .semi-tree-option-drag-over.semi-tree-option-fullLabel-draggable {
  border: 2px solid var(--semi-color-primary);
}
.semi-tree-option-list .semi-tree-option-drag-over.semi-tree-option-draggable .semi-tree-option-label, .semi-tree-option-list .semi-tree-option-drag-over.semi-tree-option-fullLabel-draggable .semi-tree-option-label {
  border-top: 0;
  border-bottom: 0;
}
.semi-tree-option-list .semi-tree-option-drag-over.semi-tree-option-draggable + .semi-tree-option-selected::after, .semi-tree-option-list .semi-tree-option-drag-over.semi-tree-option-fullLabel-draggable + .semi-tree-option-selected::after {
  content: "";
  position: absolute;
  top: 0;
  left: -2px;
  bottom: 0;
  right: -1px;
  border-top: 2px solid var(--semi-color-primary);
}
.semi-tree-option-list li.semi-tree-option-draggable.semi-tree-option {
  padding-top: 0px;
  padding-bottom: 0px;
}
.semi-tree-option-list li.semi-tree-option-draggable.semi-tree-option .semi-tree-option-label {
  padding: 3px 0;
}
.semi-tree-option-list li.semi-tree-option-draggable.semi-tree-option .semi-tree-option-selected {
  background-color: transparent;
}
.semi-tree-option-list li.semi-tree-option-draggable.semi-tree-option .semi-tree-option-selected:hover, .semi-tree-option-list li.semi-tree-option-draggable.semi-tree-option .semi-tree-option-selected:active {
  background-color: transparent;
}
.semi-tree-option-list .semi-tree-option-level-1 {
  padding-left: 8px;
}
.semi-tree-option-list .semi-tree-option-level-2 {
  padding-left: 28px;
}
.semi-tree-option-list .semi-tree-option-level-3 {
  padding-left: 48px;
}
.semi-tree-option-list .semi-tree-option-level-4 {
  padding-left: 68px;
}
.semi-tree-option-list .semi-tree-option-level-5 {
  padding-left: 88px;
}
.semi-tree-option-list .semi-tree-option-level-6 {
  padding-left: 108px;
}
.semi-tree-option-list .semi-tree-option-level-7 {
  padding-left: 128px;
}
.semi-tree-option-list .semi-tree-option-level-8 {
  padding-left: 148px;
}
.semi-tree-option-list .semi-tree-option-level-9 {
  padding-left: 168px;
}
.semi-tree-option-list .semi-tree-option-level-10 {
  padding-left: 188px;
}
.semi-tree-option-list .semi-tree-option-level-11 {
  padding-left: 208px;
}
.semi-tree-option-list .semi-tree-option-level-12 {
  padding-left: 228px;
}
.semi-tree-option-list .semi-tree-option-level-13 {
  padding-left: 248px;
}
.semi-tree-option-list .semi-tree-option-level-14 {
  padding-left: 268px;
}
.semi-tree-option-list .semi-tree-option-level-15 {
  padding-left: 288px;
}
.semi-tree-option-list .semi-tree-option-level-16 {
  padding-left: 308px;
}
.semi-tree-option-list .semi-tree-option-level-17 {
  padding-left: 328px;
}
.semi-tree-option-list .semi-tree-option-level-18 {
  padding-left: 348px;
}
.semi-tree-option-list .semi-tree-option-level-19 {
  padding-left: 368px;
}
.semi-tree-option-list .semi-tree-option-level-20 {
  padding-left: 388px;
}
.semi-tree-option-list .semi-tree-option-empty:hover, .semi-tree-option-list .semi-tree-option-empty:active {
  background-color: transparent;
}
.semi-tree-option-list .semi-tree-option-label-empty {
  padding-left: 0;
  justify-content: center;
  color: var(--semi-color-disabled-text);
  user-select: none;
  cursor: not-allowed;
}
.semi-tree-option-list .semi-checkboxGroup-vertical {
  row-gap: 0;
}

.semi-tree-option-list-block .semi-tree-option:hover {
  background-color: var(--semi-color-fill-0);
}
.semi-tree-option-list-block .semi-tree-option:active {
  background-color: var(--semi-color-fill-1);
}
.semi-tree-option-list-block .semi-tree-option-label {
  flex: 1;
}
.semi-tree-option-list-block .semi-tree-option-active {
  background-color: var(--semi-color-primary-light-default);
}
.semi-tree-option-list-block .semi-tree-option-active:hover, .semi-tree-option-list-block .semi-tree-option-active:active {
  background-color: var(--semi-color-primary-light-default);
}
.semi-tree-option-list-block .semi-tree-option-active .semi-tree-option-label-text {
  background-color: transparent;
}
.semi-tree-option-list-block .semi-tree-option-expand-icon {
  flex-shrink: 0;
  box-sizing: content-box;
}
.semi-tree-option-list-block .semi-tree-option-expand-icon:hover {
  color: var(--semi-color-text-0);
}
.semi-tree-option-list-block .semi-tree-option-expand-icon:active {
  color: var(--semi-color-black);
}
.semi-tree-option-list-block .semi-tree-option-spin-icon {
  display: flex;
  line-height: 0;
  color: var(--semi-color-primary);
}
.semi-tree-option-list-block .semi-tree-option-spin-icon svg {
  width: 12px;
  height: 12px;
}
.semi-tree-option-list-block .semi-tree-option-selected {
  background-color: var(--semi-color-primary-light-default);
}
.semi-tree-option-list-block .semi-tree-option-selected .semi-tree-option-label {
  background-color: transparent;
}
.semi-tree-option-list-block .semi-tree-option-selected .semi-tree-option-label:hover, .semi-tree-option-list-block .semi-tree-option-selected .semi-tree-option-label:active {
  background-color: transparent;
}
.semi-tree-option-list-block .semi-tree-option-selected .semi-checkbox-addon {
  background-color: transparent;
}
.semi-tree-option-list-block .semi-tree-option-selected:hover, .semi-tree-option-list-block .semi-tree-option-selected:active {
  background-color: var(--semi-color-primary-light-default);
}
.semi-tree-option-list-block .semi-tree-option-label-text,
.semi-tree-option-list-block .semi-tree-option .semi-checkbox-addon {
  padding: 0;
  border-radius: var(--semi-border-radius-small);
}
.semi-tree-option-list-block .semi-tree-option-label-text:hover,
.semi-tree-option-list-block .semi-tree-option .semi-checkbox-addon:hover {
  background-color: transparent;
}
.semi-tree-option-list-block .semi-tree-option-label-text:active,
.semi-tree-option-list-block .semi-tree-option .semi-checkbox-addon:active {
  background-color: transparent;
}
.semi-tree-option-list-block .semi-tree-option-label-text {
  width: 0;
}
.semi-tree-option-list-block .semi-tree-option-empty:hover, .semi-tree-option-list-block .semi-tree-option-empty:active {
  background-color: transparent;
}

.semi-rtl .semi-tree,
.semi-popover-rtl .semi-tree {
  direction: rtl;
}
.semi-rtl .semi-tree-wrapper,
.semi-popover-rtl .semi-tree-wrapper {
  direction: rtl;
}
.semi-rtl .semi-tree-option-list,
.semi-popover-rtl .semi-tree-option-list {
  direction: rtl;
}
.semi-rtl .semi-tree-option-list .semi-tree-option-expand-icon,
.semi-rtl .semi-tree-option-list .semi-tree-option-empty-icon,
.semi-popover-rtl .semi-tree-option-list .semi-tree-option-expand-icon,
.semi-popover-rtl .semi-tree-option-list .semi-tree-option-empty-icon {
  margin-right: 0;
  margin-left: 8px;
}
.semi-rtl .semi-tree-option-list .semi-tree-option-label > .semi-icon,
.semi-popover-rtl .semi-tree-option-list .semi-tree-option-label > .semi-icon {
  margin-right: 0;
  margin-left: 8px;
}
.semi-rtl .semi-tree-option-list .semi-tree-option-label .semi-checkbox,
.semi-popover-rtl .semi-tree-option-list .semi-tree-option-label .semi-checkbox {
  margin-right: 0;
  margin-left: 8px;
}
.semi-rtl .semi-tree-option-list .semi-tree-option-collapsed .semi-tree-option-expand-icon,
.semi-popover-rtl .semi-tree-option-list .semi-tree-option-collapsed .semi-tree-option-expand-icon {
  transform: rotate(90deg);
}
.semi-rtl .semi-tree-option-list .semi-tree-option-level-1,
.semi-popover-rtl .semi-tree-option-list .semi-tree-option-level-1 {
  padding-left: 0;
  padding-right: 8px;
}
.semi-rtl .semi-tree-option-list .semi-tree-option-level-2,
.semi-popover-rtl .semi-tree-option-list .semi-tree-option-level-2 {
  padding-left: 0;
  padding-right: 28px;
}
.semi-rtl .semi-tree-option-list .semi-tree-option-level-3,
.semi-popover-rtl .semi-tree-option-list .semi-tree-option-level-3 {
  padding-left: 0;
  padding-right: 48px;
}
.semi-rtl .semi-tree-option-list .semi-tree-option-level-4,
.semi-popover-rtl .semi-tree-option-list .semi-tree-option-level-4 {
  padding-left: 0;
  padding-right: 68px;
}
.semi-rtl .semi-tree-option-list .semi-tree-option-level-5,
.semi-popover-rtl .semi-tree-option-list .semi-tree-option-level-5 {
  padding-left: 0;
  padding-right: 88px;
}
.semi-rtl .semi-tree-option-list .semi-tree-option-level-6,
.semi-popover-rtl .semi-tree-option-list .semi-tree-option-level-6 {
  padding-left: 0;
  padding-right: 108px;
}
.semi-rtl .semi-tree-option-list .semi-tree-option-level-7,
.semi-popover-rtl .semi-tree-option-list .semi-tree-option-level-7 {
  padding-left: 0;
  padding-right: 128px;
}
.semi-rtl .semi-tree-option-list .semi-tree-option-level-8,
.semi-popover-rtl .semi-tree-option-list .semi-tree-option-level-8 {
  padding-left: 0;
  padding-right: 148px;
}
.semi-rtl .semi-tree-option-list .semi-tree-option-level-9,
.semi-popover-rtl .semi-tree-option-list .semi-tree-option-level-9 {
  padding-left: 0;
  padding-right: 168px;
}
.semi-rtl .semi-tree-option-list .semi-tree-option-level-10,
.semi-popover-rtl .semi-tree-option-list .semi-tree-option-level-10 {
  padding-left: 0;
  padding-right: 188px;
}
.semi-rtl .semi-tree-option-list .semi-tree-option-level-11,
.semi-popover-rtl .semi-tree-option-list .semi-tree-option-level-11 {
  padding-left: 0;
  padding-right: 208px;
}
.semi-rtl .semi-tree-option-list .semi-tree-option-level-12,
.semi-popover-rtl .semi-tree-option-list .semi-tree-option-level-12 {
  padding-left: 0;
  padding-right: 228px;
}
.semi-rtl .semi-tree-option-list .semi-tree-option-level-13,
.semi-popover-rtl .semi-tree-option-list .semi-tree-option-level-13 {
  padding-left: 0;
  padding-right: 248px;
}
.semi-rtl .semi-tree-option-list .semi-tree-option-level-14,
.semi-popover-rtl .semi-tree-option-list .semi-tree-option-level-14 {
  padding-left: 0;
  padding-right: 268px;
}
.semi-rtl .semi-tree-option-list .semi-tree-option-level-15,
.semi-popover-rtl .semi-tree-option-list .semi-tree-option-level-15 {
  padding-left: 0;
  padding-right: 288px;
}
.semi-rtl .semi-tree-option-list .semi-tree-option-level-16,
.semi-popover-rtl .semi-tree-option-list .semi-tree-option-level-16 {
  padding-left: 0;
  padding-right: 308px;
}
.semi-rtl .semi-tree-option-list .semi-tree-option-level-17,
.semi-popover-rtl .semi-tree-option-list .semi-tree-option-level-17 {
  padding-left: 0;
  padding-right: 328px;
}
.semi-rtl .semi-tree-option-list .semi-tree-option-level-18,
.semi-popover-rtl .semi-tree-option-list .semi-tree-option-level-18 {
  padding-left: 0;
  padding-right: 348px;
}
.semi-rtl .semi-tree-option-list .semi-tree-option-level-19,
.semi-popover-rtl .semi-tree-option-list .semi-tree-option-level-19 {
  padding-left: 0;
  padding-right: 368px;
}
.semi-rtl .semi-tree-option-list .semi-tree-option-level-20,
.semi-popover-rtl .semi-tree-option-list .semi-tree-option-level-20 {
  padding-left: 0;
  padding-right: 388px;
}
.semi-rtl .semi-tree-option-list .semi-tree-option-label-empty,
.semi-popover-rtl .semi-tree-option-list .semi-tree-option-label-empty {
  padding-left: auto;
  padding-right: 0;
}
.semi-rtl .semi-tree-option-list-block,
.semi-popover-rtl .semi-tree-option-list-block {
  direction: rtl;
}