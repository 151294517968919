/* shadow */
/* sizing */
/* spacing */
.semi-portal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}
.semi-portal-inner {
  position: absolute;
  background-color: transparent;
  min-width: max-content;
}