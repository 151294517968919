/* shadow */
/* sizing */
/* spacing */
.semi-tag {
  box-sizing: border-box;
  border-radius: var(--semi-border-radius-small);
  background-color: transparent;
  position: relative;
  user-select: none;
  overflow: hidden;
  white-space: nowrap;
  vertical-align: bottom;
  display: flex;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}
.semi-tag-default, .semi-tag-small {
  font-size: 12px;
  line-height: 16px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  height: 20px;
  padding: 2px 8px;
}
.semi-tag-default:focus-visible, .semi-tag-small:focus-visible {
  outline: 2px solid var(--semi-color-primary-light-active);
}
.semi-tag-square {
  border-radius: var(--semi-border-radius-small);
}
.semi-tag-circle {
  border-radius: var(--semi-border-radius-full);
}
.semi-tag-large {
  font-size: 12px;
  line-height: 16px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 4px 8px;
  height: 24px;
}
.semi-tag-large:focus-visible {
  outline: 2px solid var(--semi-color-primary-light-active);
}
.semi-tag-invisible {
  display: none;
}
.semi-tag-content {
  flex: 1;
}
.semi-tag-content-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.semi-tag-content-center {
  display: flex;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-width: 0;
}
.semi-tag-close {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--semi-color-text-2);
  padding-left: 4px;
  cursor: pointer;
}
.semi-tag-close:hover {
  color: var(--semi-color-text-1);
}
.semi-tag-close:active {
  color: var(--semi-color-text-0);
}
.semi-tag-closable {
  padding: 4px 4px 4px 8px;
}
.semi-tag-avatar-square .semi-avatar, .semi-tag-avatar-circle .semi-avatar {
  margin-right: 4px;
}
.semi-tag-avatar-square {
  padding: 0 4px 0 0;
}
.semi-tag-avatar-square .semi-avatar > img {
  background-color: var(--semi-color-default);
}
.semi-tag-avatar-circle {
  padding: 2px 4px 2px 2px;
}
.semi-tag-avatar-square.semi-tag-default .semi-avatar, .semi-tag-avatar-square.semi-tag-small .semi-avatar {
  width: 20px;
  height: 20px;
}
.semi-tag-avatar-square.semi-tag-large .semi-avatar {
  width: 24px;
  height: 24px;
}
.semi-tag-avatar-circle.semi-tag-small, .semi-tag-avatar-circle.semi-tag-default {
  border-radius: 11px;
}
.semi-tag-avatar-circle.semi-tag-small .semi-avatar, .semi-tag-avatar-circle.semi-tag-default .semi-avatar {
  width: 16px;
  height: 16px;
}
.semi-tag-avatar-circle.semi-tag-large {
  border-radius: 13px;
}
.semi-tag-avatar-circle.semi-tag-large .semi-avatar {
  width: 20px;
  height: 20px;
}

.semi-tag-group {
  display: block;
  height: auto;
}
.semi-tag-group .semi-tag {
  margin-bottom: 0;
  margin-right: 8px;
}
.semi-tag-group-max.semi-tag-group-small {
  height: 22px;
}
.semi-tag-group-max.semi-tag-group-large {
  height: 26px;
}

.semi-tag-rest-group-popover .semi-tag {
  margin-right: 8px;
  margin-bottom: 0;
}
.semi-tag-rest-group-popover .semi-tag:last-of-type {
  margin-right: 0;
}

.semi-tag-amber-ghost {
  background-color: transparent;
  border: 1px solid rgba(var(--semi-amber-4), 1);
  color: rgba(var(--semi-amber-5), 1);
}

.semi-tag-amber-solid {
  background-color: rgba(var(--semi-amber-5), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-tag-amber-light {
  background-color: rgba(var(--semi-amber-5), 0.15);
  color: rgba(var(--semi-amber-8), 1);
}

.semi-tag-blue-ghost {
  background-color: transparent;
  border: 1px solid rgba(var(--semi-blue-4), 1);
  color: rgba(var(--semi-blue-5), 1);
}

.semi-tag-blue-solid {
  background-color: rgba(var(--semi-blue-5), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-tag-blue-light {
  background-color: rgba(var(--semi-blue-5), 0.15);
  color: rgba(var(--semi-blue-8), 1);
}

.semi-tag-cyan-ghost {
  background-color: transparent;
  border: 1px solid rgba(var(--semi-cyan-4), 1);
  color: rgba(var(--semi-cyan-5), 1);
}

.semi-tag-cyan-solid {
  background-color: rgba(var(--semi-cyan-5), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-tag-cyan-light {
  background-color: rgba(var(--semi-cyan-5), 0.15);
  color: rgba(var(--semi-cyan-8), 1);
}

.semi-tag-green-ghost {
  background-color: transparent;
  border: 1px solid rgba(var(--semi-green-4), 1);
  color: rgba(var(--semi-green-5), 1);
}

.semi-tag-green-solid {
  background-color: rgba(var(--semi-green-5), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-tag-green-light {
  background-color: rgba(var(--semi-green-5), 0.15);
  color: rgba(var(--semi-green-8), 1);
}

.semi-tag-grey-ghost {
  background-color: transparent;
  border: 1px solid rgba(var(--semi-grey-4), 1);
  color: rgba(var(--semi-grey-5), 1);
}

.semi-tag-grey-solid {
  background-color: rgba(var(--semi-grey-5), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-tag-grey-light {
  background-color: rgba(var(--semi-grey-5), 0.15);
  color: rgba(var(--semi-grey-8), 1);
}

.semi-tag-indigo-ghost {
  background-color: transparent;
  border: 1px solid rgba(var(--semi-indigo-4), 1);
  color: rgba(var(--semi-indigo-5), 1);
}

.semi-tag-indigo-solid {
  background-color: rgba(var(--semi-indigo-5), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-tag-indigo-light {
  background-color: rgba(var(--semi-indigo-5), 0.15);
  color: rgba(var(--semi-indigo-8), 1);
}

.semi-tag-light-blue-ghost {
  background-color: transparent;
  border: 1px solid rgba(var(--semi-light-blue-4), 1);
  color: rgba(var(--semi-light-blue-5), 1);
}

.semi-tag-light-blue-solid {
  background-color: rgba(var(--semi-light-blue-5), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-tag-light-blue-light {
  background-color: rgba(var(--semi-light-blue-5), 0.15);
  color: rgba(var(--semi-light-blue-8), 1);
}

.semi-tag-light-green-ghost {
  background-color: transparent;
  border: 1px solid rgba(var(--semi-light-green-4), 1);
  color: rgba(var(--semi-light-green-5), 1);
}

.semi-tag-light-green-solid {
  background-color: rgba(var(--semi-light-green-5), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-tag-light-green-light {
  background-color: rgba(var(--semi-light-green-5), 0.15);
  color: rgba(var(--semi-light-green-8), 1);
}

.semi-tag-lime-ghost {
  background-color: transparent;
  border: 1px solid rgba(var(--semi-lime-4), 1);
  color: rgba(var(--semi-lime-5), 1);
}

.semi-tag-lime-solid {
  background-color: rgba(var(--semi-lime-5), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-tag-lime-light {
  background-color: rgba(var(--semi-lime-5), 0.15);
  color: rgba(var(--semi-lime-8), 1);
}

.semi-tag-orange-ghost {
  background-color: transparent;
  border: 1px solid rgba(var(--semi-orange-4), 1);
  color: rgba(var(--semi-orange-5), 1);
}

.semi-tag-orange-solid {
  background-color: rgba(var(--semi-orange-5), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-tag-orange-light {
  background-color: rgba(var(--semi-orange-5), 0.15);
  color: rgba(var(--semi-orange-8), 1);
}

.semi-tag-pink-ghost {
  background-color: transparent;
  border: 1px solid rgba(var(--semi-pink-4), 1);
  color: rgba(var(--semi-pink-5), 1);
}

.semi-tag-pink-solid {
  background-color: rgba(var(--semi-pink-5), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-tag-pink-light {
  background-color: rgba(var(--semi-pink-5), 0.15);
  color: rgba(var(--semi-pink-8), 1);
}

.semi-tag-purple-ghost {
  background-color: transparent;
  border: 1px solid rgba(var(--semi-purple-4), 1);
  color: rgba(var(--semi-purple-5), 1);
}

.semi-tag-purple-solid {
  background-color: rgba(var(--semi-purple-5), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-tag-purple-light {
  background-color: rgba(var(--semi-purple-5), 0.15);
  color: rgba(var(--semi-purple-8), 1);
}

.semi-tag-red-ghost {
  background-color: transparent;
  border: 1px solid rgba(var(--semi-red-4), 1);
  color: rgba(var(--semi-red-5), 1);
}

.semi-tag-red-solid {
  background-color: rgba(var(--semi-red-5), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-tag-red-light {
  background-color: rgba(var(--semi-red-5), 0.15);
  color: rgba(var(--semi-red-8), 1);
}

.semi-tag-teal-ghost {
  background-color: transparent;
  border: 1px solid rgba(var(--semi-teal-4), 1);
  color: rgba(var(--semi-teal-5), 1);
}

.semi-tag-teal-solid {
  background-color: rgba(var(--semi-teal-5), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-tag-teal-light {
  background-color: rgba(var(--semi-teal-5), 0.15);
  color: rgba(var(--semi-teal-8), 1);
}

.semi-tag-violet-ghost {
  background-color: transparent;
  border: 1px solid rgba(var(--semi-violet-4), 1);
  color: rgba(var(--semi-violet-5), 1);
}

.semi-tag-violet-solid {
  background-color: rgba(var(--semi-violet-5), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-tag-violet-light {
  background-color: rgba(var(--semi-violet-5), 0.15);
  color: rgba(var(--semi-violet-8), 1);
}

.semi-tag-yellow-ghost {
  background-color: transparent;
  border: 1px solid rgba(var(--semi-yellow-4), 1);
  color: rgba(var(--semi-yellow-5), 1);
}

.semi-tag-yellow-solid {
  background-color: rgba(var(--semi-yellow-5), 1);
  color: rgba(var(--semi-white), 1);
}

.semi-tag-yellow-light {
  background-color: rgba(var(--semi-yellow-5), 0.15);
  color: rgba(var(--semi-yellow-8), 1);
}

.semi-tag-white-ghost {
  background-color: var(--semi-color-bg-4);
  border: 1px solid rgba(var(--semi-grey-2), 0.7);
  color: var(--semi-color-text-0);
}

.semi-tag-white-solid {
  background-color: var(--semi-color-bg-4);
  border: 1px solid rgba(var(--semi-grey-2), 0.7);
  color: var(--semi-color-text-0);
}

.semi-tag-white-light {
  background-color: var(--semi-color-bg-4);
  border: 1px solid rgba(var(--semi-grey-2), 0.7);
  color: var(--semi-color-text-0);
}

.semi-tag-white-ghost .semi-tag-close,
.semi-tag-white-light .semi-tag-close,
.semi-tag-white-solid .semi-tag-close {
  color: var(--semi-color-text-2);
}

.semi-tag-avatar-square,
.semi-tag-avatar-circle {
  background-color: var(--semi-color-bg-4);
  border: 1px solid var(--semi-color-border);
  color: var(--semi-color-text-0);
}

.semi-tag-solid .semi-tag-close {
  color: var(--semi-color-white);
  opacity: 0.8;
}
.semi-tag-solid .semi-tag-close:hover {
  opacity: 1;
}
.semi-tag-solid .semi-tag-close:active {
  opacity: 0.9;
}

.semi-rtl .semi-tag,
.semi-portal-rtl .semi-tag {
  direction: rtl;
}
.semi-rtl .semi-tag-close,
.semi-portal-rtl .semi-tag-close {
  padding-left: auto;
  padding-right: 4px;
}
.semi-rtl .semi-tag-closable,
.semi-portal-rtl .semi-tag-closable {
  padding: 4px 8px 4px 4px;
}
.semi-rtl .semi-tag-avatar-square .semi-avatar, .semi-rtl .semi-tag-avatar-circle .semi-avatar,
.semi-portal-rtl .semi-tag-avatar-square .semi-avatar,
.semi-portal-rtl .semi-tag-avatar-circle .semi-avatar {
  margin-right: auto;
  margin-left: 4px;
}
.semi-rtl .semi-tag-avatar-square,
.semi-portal-rtl .semi-tag-avatar-square {
  padding-right: auto;
  padding-left: 4px;
}
.semi-rtl .semi-tag-avatar-circle,
.semi-portal-rtl .semi-tag-avatar-circle {
  padding: 2px 2px 2px 4px;
}
.semi-rtl .semi-tag-group,
.semi-portal-rtl .semi-tag-group {
  direction: rtl;
}
.semi-rtl .semi-tag-group .semi-tag,
.semi-portal-rtl .semi-tag-group .semi-tag {
  margin-right: auto;
  margin-left: 8px;
}
.semi-rtl .semi-tag-rest-group-popover,
.semi-portal-rtl .semi-tag-rest-group-popover {
  direction: rtl;
}
.semi-rtl .semi-tag-rest-group-popover .semi-tag,
.semi-portal-rtl .semi-tag-rest-group-popover .semi-tag {
  margin-right: 0;
  margin-left: 8px;
}
.semi-rtl .semi-tag-rest-group-popover .semi-tag:last-of-type,
.semi-portal-rtl .semi-tag-rest-group-popover .semi-tag:last-of-type {
  margin-right: auto;
  margin-left: 0;
}