/* shadow */
/* sizing */
/* spacing */
@keyframes semi-input-active {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.97);
  }
}
@keyframes semi-input-inactive {
  from {
    transform: scale(0.97);
  }
  to {
    transform: scale(1);
  }
}
.semi-input {
  transition: background-color var(--semi-transition_duration-none) var(--semi-transition_function-easeIn) var(--semi-transition_delay-none), border var(--semi-transition_duration-none) var(--semi-transition_function-easeIn) var(--semi-transition_delay-none);
  transform: scale(var(--semi-transform_scale-none));
}

.semi-input-wrapper {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  box-shadow: none;
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: var(--semi-color-fill-0);
  border: 1px transparent solid;
  border-radius: var(--semi-border-radius-small);
  width: 100%;
  outline: none;
  cursor: text;
  box-sizing: border-box;
  color: var(--semi-color-text-0);
  transition: background-color var(--semi-transition_duration-none) var(--semi-transition_function-easeIn) var(--semi-transition_delay-none), border var(--semi-transition_duration-none) var(--semi-transition_function-easeIn) var(--semi-transition_delay-none);
  transform: scale(var(--semi-transform_scale-none));
}
.semi-input-wrapper-default {
  height: 32px;
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 30px;
}
.semi-input-wrapper-small {
  height: 24px;
  font-size: 16px;
  line-height: 22px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 22px;
}
.semi-input-wrapper-large {
  height: 40px;
  font-size: 16px;
  line-height: 22px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 38px;
}
.semi-input-wrapper:hover {
  background-color: var(--semi-color-fill-1);
  border-color: transparent;
}
.semi-input-wrapper-focus {
  background-color: var(--semi-color-fill-0);
  border: var(--semi-color-focus-border) solid 1px;
}
.semi-input-wrapper-focus:hover {
  background-color: var(--semi-color-fill-0);
  border-color: var(--semi-color-focus-border);
}
.semi-input-wrapper-focus:active {
  background-color: var(--semi-color-fill-2);
  border-color: var(--semi-color-focus-border);
}
.semi-input-wrapper.semi-input-readonly {
  cursor: default;
}
.semi-input-wrapper-error {
  background-color: var(--semi-color-danger-light-default);
  border-color: var(--semi-color-danger-light-default);
}
.semi-input-wrapper-error:hover {
  background-color: var(--semi-color-danger-light-hover);
  border-color: var(--semi-color-danger-light-hover);
}
.semi-input-wrapper-error.semi-input-wrapper-focus {
  background-color: var(--semi-color-danger-light-default);
  border-color: var(--semi-color-danger);
}
.semi-input-wrapper-error:active {
  background-color: var(--semi-color-danger-light-active);
  border-color: var(--semi-color-danger);
}
.semi-input-wrapper-warning {
  background-color: var(--semi-color-warning-light-default);
  border-color: var(--semi-color-warning-light-default);
}
.semi-input-wrapper-warning:hover {
  background-color: var(--semi-color-warning-light-hover);
  border-color: var(--semi-color-warning-light-hover);
}
.semi-input-wrapper-warning.semi-input-wrapper-focus {
  background-color: var(--semi-color-warning-light-default);
  border-color: var(--semi-color-warning);
}
.semi-input-wrapper-warning:active {
  background-color: var(--semi-color-warning-light-active);
  border-color: var(--semi-color-warning);
}
.semi-input-wrapper__with-prefix {
  display: inline-flex;
  align-items: center;
}
.semi-input-wrapper__with-prefix .semi-input {
  padding-left: 0;
}
.semi-input-wrapper__with-suffix {
  display: inline-flex;
  align-items: center;
}
.semi-input-wrapper__with-suffix .semi-input {
  padding-right: 0;
}
.semi-input-wrapper-clearable, .semi-input-wrapper-modebtn {
  display: inline-flex;
  align-items: center;
}
.semi-input-wrapper-hidden {
  border: none;
}
.semi-input-wrapper .semi-icon {
  color: var(--semi-color-text-2);
}
.semi-input-wrapper .semi-input-clearbtn,
.semi-input-wrapper .semi-input-modebtn {
  color: var(--semi-color-primary-hover);
}
.semi-input-wrapper .semi-input-clearbtn > svg,
.semi-input-wrapper .semi-input-modebtn > svg {
  pointer-events: none;
}
.semi-input-wrapper .semi-input-clearbtn:hover,
.semi-input-wrapper .semi-input-modebtn:hover {
  cursor: pointer;
}
.semi-input-wrapper .semi-input-clearbtn:hover .semi-icon,
.semi-input-wrapper .semi-input-modebtn:hover .semi-icon {
  color: var(--semi-color-primary-hover);
}
.semi-input-wrapper .semi-input-clearbtn:focus-visible,
.semi-input-wrapper .semi-input-modebtn:focus-visible {
  border-radius: var(--semi-border-radius-small);
  outline: 2px solid var(--semi-color-primary-light-active);
  outline-offset: -1px;
}
.semi-input-wrapper__with-suffix-icon.semi-input-wrapper-clearable:not(.semi-input-wrapper__with-suffix-hidden) .semi-input-clearbtn {
  min-width: 24px;
  justify-content: flex-end;
}
.semi-input-wrapper-modebtn.semi-input-wrapper-clearable .semi-input-clearbtn {
  min-width: 16px;
  justify-content: center;
}
.semi-input-wrapper.semi-input-wrapper__with-append-only .semi-input {
  border-radius: 0 var(--semi-border-radius-small) var(--semi-border-radius-small) 0;
}
.semi-input-wrapper.semi-input-wrapper__with-append-only .semi-input:not(:last-child) {
  border-right-style: none;
  border-radius: 0;
}
.semi-input-wrapper.semi-input-wrapper__with-prepend-only .semi-input {
  border-radius: var(--semi-border-radius-small) 0 0 var(--semi-border-radius-small);
}
.semi-input-wrapper.semi-input-wrapper__with-prepend-only .semi-input:not(:last-child) {
  border-right-style: none;
  border-radius: 0;
}
.semi-input-wrapper.semi-input-wrapper__with-prepend, .semi-input-wrapper.semi-input-wrapper__with-append {
  display: inline-flex;
  align-items: center;
  background-color: transparent;
}
.semi-input-wrapper.semi-input-wrapper__with-prepend:hover, .semi-input-wrapper.semi-input-wrapper__with-append:hover {
  background-color: transparent;
}
.semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-focus, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-focus {
  border: 1px transparent solid;
  background-color: transparent;
}
.semi-input-wrapper.semi-input-wrapper__with-prepend .semi-input, .semi-input-wrapper.semi-input-wrapper__with-append .semi-input {
  background-color: var(--semi-color-fill-0);
  border: 1px transparent solid;
}
.semi-input-wrapper.semi-input-wrapper__with-prepend .semi-input:hover, .semi-input-wrapper.semi-input-wrapper__with-append .semi-input:hover {
  background-color: var(--semi-color-fill-1);
}
.semi-input-wrapper.semi-input-wrapper__with-prepend .semi-input:hover + .semi-input-clearbtn, .semi-input-wrapper.semi-input-wrapper__with-prepend .semi-input:hover ~ .semi-input-modebtn, .semi-input-wrapper.semi-input-wrapper__with-append .semi-input:hover + .semi-input-clearbtn, .semi-input-wrapper.semi-input-wrapper__with-append .semi-input:hover ~ .semi-input-modebtn {
  background-color: var(--semi-color-fill-1);
}
.semi-input-wrapper.semi-input-wrapper__with-prepend .semi-input:focus, .semi-input-wrapper.semi-input-wrapper__with-append .semi-input:focus {
  border: 1px var(--semi-color-focus-border) solid;
  background-color: var(--semi-color-fill-0);
}
.semi-input-wrapper.semi-input-wrapper__with-prepend .semi-input:focus.semi-input-sibling-clearbtn, .semi-input-wrapper.semi-input-wrapper__with-prepend .semi-input:focus.semi-input-sibling-modebtn, .semi-input-wrapper.semi-input-wrapper__with-append .semi-input:focus.semi-input-sibling-clearbtn, .semi-input-wrapper.semi-input-wrapper__with-append .semi-input:focus.semi-input-sibling-modebtn {
  border-right-style: none;
}
.semi-input-wrapper.semi-input-wrapper__with-prepend .semi-input:focus.semi-input-sibling-modebtn + .semi-input-clearbtn, .semi-input-wrapper.semi-input-wrapper__with-append .semi-input:focus.semi-input-sibling-modebtn + .semi-input-clearbtn {
  border-right-style: none;
}
.semi-input-wrapper.semi-input-wrapper__with-prepend .semi-input:focus + .semi-input-clearbtn, .semi-input-wrapper.semi-input-wrapper__with-prepend .semi-input:focus ~ .semi-input-modebtn, .semi-input-wrapper.semi-input-wrapper__with-append .semi-input:focus + .semi-input-clearbtn, .semi-input-wrapper.semi-input-wrapper__with-append .semi-input:focus ~ .semi-input-modebtn {
  box-sizing: border-box;
  background-color: var(--semi-color-fill-0);
}
.semi-input-wrapper.semi-input-wrapper__with-prepend .semi-input:focus + .semi-input-clearbtn, .semi-input-wrapper.semi-input-wrapper__with-append .semi-input:focus + .semi-input-clearbtn {
  border: 1px var(--semi-color-focus-border) solid;
  border-radius: 0 var(--semi-border-radius-small) var(--semi-border-radius-small) 0;
  border-left-style: none;
}
.semi-input-wrapper.semi-input-wrapper__with-prepend .semi-input:focus + .semi-input-clearbtn:not(:last-child), .semi-input-wrapper.semi-input-wrapper__with-append .semi-input:focus + .semi-input-clearbtn:not(:last-child) {
  border-radius: 0;
}
.semi-input-wrapper.semi-input-wrapper__with-prepend .semi-input:focus ~ .semi-input-modebtn, .semi-input-wrapper.semi-input-wrapper__with-append .semi-input:focus ~ .semi-input-modebtn {
  border: 1px var(--semi-color-focus-border) solid;
  border-radius: 0 var(--semi-border-radius-small) var(--semi-border-radius-small) 0;
  border-left-style: none;
}
.semi-input-wrapper.semi-input-wrapper__with-prepend .semi-input:focus ~ .semi-input-modebtn:not(:last-child), .semi-input-wrapper.semi-input-wrapper__with-append .semi-input:focus ~ .semi-input-modebtn:not(:last-child) {
  border-radius: 0;
}
.semi-input-wrapper.semi-input-wrapper__with-prepend .semi-input:active, .semi-input-wrapper.semi-input-wrapper__with-append .semi-input:active {
  background-color: var(--semi-color-fill-2);
}
.semi-input-wrapper.semi-input-wrapper__with-prepend .semi-input:active + .semi-input-clearbtn, .semi-input-wrapper.semi-input-wrapper__with-prepend .semi-input:active ~ .semi-input-modebtn, .semi-input-wrapper.semi-input-wrapper__with-append .semi-input:active + .semi-input-clearbtn, .semi-input-wrapper.semi-input-wrapper__with-append .semi-input:active ~ .semi-input-modebtn {
  background-color: var(--semi-color-fill-2);
}
.semi-input-wrapper.semi-input-wrapper__with-prepend .semi-input-clearbtn, .semi-input-wrapper.semi-input-wrapper__with-prepend .semi-input-modebtn, .semi-input-wrapper.semi-input-wrapper__with-prepend .semi-input-clearbtn:hover, .semi-input-wrapper.semi-input-wrapper__with-prepend .semi-input-modebtn:hover, .semi-input-wrapper.semi-input-wrapper__with-append .semi-input-clearbtn, .semi-input-wrapper.semi-input-wrapper__with-append .semi-input-modebtn, .semi-input-wrapper.semi-input-wrapper__with-append .semi-input-clearbtn:hover, .semi-input-wrapper.semi-input-wrapper__with-append .semi-input-modebtn:hover {
  background-color: var(--semi-color-fill-0);
}
.semi-input-wrapper.semi-input-wrapper__with-prepend .semi-input-clearbtn:last-child, .semi-input-wrapper.semi-input-wrapper__with-prepend .semi-input-modebtn:last-child, .semi-input-wrapper.semi-input-wrapper__with-prepend .semi-input-clearbtn:hover:last-child, .semi-input-wrapper.semi-input-wrapper__with-prepend .semi-input-modebtn:hover:last-child, .semi-input-wrapper.semi-input-wrapper__with-append .semi-input-clearbtn:last-child, .semi-input-wrapper.semi-input-wrapper__with-append .semi-input-modebtn:last-child, .semi-input-wrapper.semi-input-wrapper__with-append .semi-input-clearbtn:hover:last-child, .semi-input-wrapper.semi-input-wrapper__with-append .semi-input-modebtn:hover:last-child {
  border-radius: 0 var(--semi-border-radius-small) var(--semi-border-radius-small) 0;
}
.semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-error, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-error {
  border-color: transparent;
}
.semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-error .semi-input, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-error .semi-input {
  background-color: var(--semi-color-danger-light-default);
  border-color: var(--semi-color-danger-light-default);
}
.semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-error .semi-input:hover, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-error .semi-input:hover {
  background-color: var(--semi-color-danger-light-hover);
  border-color: var(--semi-color-danger-light-hover);
}
.semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-error .semi-input:hover + .semi-input-clearbtn, .semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-error .semi-input:hover + .semi-input-modebtn, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-error .semi-input:hover + .semi-input-clearbtn, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-error .semi-input:hover + .semi-input-modebtn {
  background-color: var(--semi-color-danger-light-hover);
}
.semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-error .semi-input:focus, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-error .semi-input:focus {
  background-color: var(--semi-color-danger-light-default);
  border-color: var(--semi-color-danger);
}
.semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-error .semi-input:focus + .semi-input-clearbtn, .semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-error .semi-input:focus + .semi-input-modebtn, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-error .semi-input:focus + .semi-input-clearbtn, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-error .semi-input:focus + .semi-input-modebtn {
  background-color: var(--semi-color-danger-light-default);
  border-color: var(--semi-color-danger);
}
.semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-error .semi-input:active, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-error .semi-input:active {
  background-color: var(--semi-color-danger-light-active);
}
.semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-error .semi-input:active + .semi-input-clearbtn, .semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-error .semi-input:active + .semi-input-modebtn, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-error .semi-input:active + .semi-input-clearbtn, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-error .semi-input:active + .semi-input-modebtn {
  background-color: var(--semi-color-danger-light-active);
  border-color: var(--semi-color-danger);
}
.semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-error .semi-input-clearbtn, .semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-error .semi-input-modebtn, .semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-error .semi-input-clearbtn:hover, .semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-error .semi-input-modebtn:hover, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-error .semi-input-clearbtn, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-error .semi-input-modebtn, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-error .semi-input-clearbtn:hover, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-error .semi-input-modebtn:hover {
  background-color: var(--semi-color-danger-light-default);
}
.semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-error .semi-input-clearbtn:last-child, .semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-error .semi-input-modebtn:last-child, .semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-error .semi-input-clearbtn:hover:last-child, .semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-error .semi-input-modebtn:hover:last-child, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-error .semi-input-clearbtn:last-child, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-error .semi-input-modebtn:last-child, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-error .semi-input-clearbtn:hover:last-child, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-error .semi-input-modebtn:hover:last-child {
  border-radius: 0 var(--semi-border-radius-small) var(--semi-border-radius-small) 0;
}
.semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-warning, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-warning {
  border-color: transparent;
}
.semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-warning .semi-input, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-warning .semi-input {
  background-color: var(--semi-color-warning-light-default);
  border-color: var(--semi-color-warning-light-default);
}
.semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-warning .semi-input:hover, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-warning .semi-input:hover {
  background-color: var(--semi-color-warning-light-hover);
  border-color: var(--semi-color-warning-light-hover);
}
.semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-warning .semi-input:hover + .semi-input-clearbtn, .semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-warning .semi-input:hover + .semi-input-modebtn, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-warning .semi-input:hover + .semi-input-clearbtn, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-warning .semi-input:hover + .semi-input-modebtn {
  background-color: var(--semi-color-warning-light-hover);
}
.semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-warning .semi-input:focus, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-warning .semi-input:focus {
  background-color: var(--semi-color-warning-light-default);
  border-color: var(--semi-color-warning);
}
.semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-warning .semi-input:focus + .semi-input-clearbtn, .semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-warning .semi-input:focus + .semi-input-modebtn, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-warning .semi-input:focus + .semi-input-clearbtn, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-warning .semi-input:focus + .semi-input-modebtn {
  background-color: var(--semi-color-warning-light-default);
  border-color: var(--semi-color-warning);
}
.semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-warning .semi-input:active, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-warning .semi-input:active {
  background-color: var(--semi-color-warning-light-active);
}
.semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-warning .semi-input:active + .semi-input-clearbtn, .semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-warning .semi-input:active + .semi-input-modebtn, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-warning .semi-input:active + .semi-input-clearbtn, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-warning .semi-input:active + .semi-input-modebtn {
  background-color: var(--semi-color-warning-light-active);
  border-color: var(--semi-color-warning);
}
.semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-warning .semi-input-clearbtn, .semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-warning .semi-input-modebtn, .semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-warning .semi-input-clearbtn:hover, .semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-warning .semi-input-modebtn:hover, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-warning .semi-input-clearbtn, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-warning .semi-input-modebtn, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-warning .semi-input-clearbtn:hover, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-warning .semi-input-modebtn:hover {
  background-color: var(--semi-color-warning-light-default);
}
.semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-warning .semi-input-clearbtn:last-child, .semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-warning .semi-input-modebtn:last-child, .semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-warning .semi-input-clearbtn:hover:last-child, .semi-input-wrapper.semi-input-wrapper__with-prepend.semi-input-wrapper-warning .semi-input-modebtn:hover:last-child, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-warning .semi-input-clearbtn:last-child, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-warning .semi-input-modebtn:last-child, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-warning .semi-input-clearbtn:hover:last-child, .semi-input-wrapper.semi-input-wrapper__with-append.semi-input-wrapper-warning .semi-input-modebtn:hover:last-child {
  border-radius: 0 var(--semi-border-radius-small) var(--semi-border-radius-small) 0;
}
.semi-input-wrapper-disabled {
  cursor: not-allowed;
  color: var(--semi-color-disabled-text);
  background-color: var(--semi-color-disabled-fill);
  -webkit-text-fill-color: var(--semi-color-disabled-text);
}
.semi-input-wrapper-disabled:hover {
  background-color: var(--semi-color-disabled-fill);
}
.semi-input-wrapper-disabled .semi-input-append,
.semi-input-wrapper-disabled .semi-input-prepend,
.semi-input-wrapper-disabled .semi-input-suffix,
.semi-input-wrapper-disabled .semi-input-prefix,
.semi-input-wrapper-disabled .semi-icon {
  color: var(--semi-color-disabled-text);
}

.semi-input {
  border: none;
  outline: none;
  width: 100%;
  color: inherit;
  padding-left: 12px;
  padding-right: 12px;
  background-color: transparent;
  box-sizing: border-box;
}
.semi-input[type=password]::-ms-reveal, .semi-input[type=password]::-ms-clear {
  display: none;
}
.semi-input[type=search]::-webkit-search-cancel-button {
  display: none;
}
.semi-input::placeholder {
  color: var(--semi-color-text-2);
}
.semi-input-large {
  height: 38px;
  font-size: 16px;
  line-height: 22px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 38px;
}
.semi-input-small {
  height: 22px;
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 22px;
}
.semi-input-default {
  height: 30px;
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 30px;
}
.semi-input-disabled {
  cursor: not-allowed;
  color: inherit;
}
.semi-input-inset-label {
  margin-right: 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: var(--semi-color-text-2);
  flex-shrink: 0;
  white-space: nowrap;
}
.semi-input-prefix, .semi-input-suffix {
  display: flex;
  justify-content: center;
  align-items: center;
}
.semi-input-prefix-text, .semi-input-suffix-text {
  margin: 0 12px;
  color: var(--semi-color-text-2);
  font-weight: 600;
  white-space: nowrap;
}
.semi-input-prefix-icon, .semi-input-suffix-icon {
  color: var(--semi-color-text-2);
  margin: 0 8px;
}
.semi-input-suffix {
  display: flex;
  justify-content: center;
  align-items: center;
}
.semi-input-clearbtn, .semi-input-modebtn {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  min-width: 32px;
}
.semi-input-clearbtn + .semi-input-suffix + .semi-input-suffix-text {
  margin-left: 0;
}
.semi-input-clearbtn + .semi-input-suffix + .semi-input-suffix-icon {
  margin-left: 0;
}
.semi-input-suffix-hidden {
  display: none;
}
.semi-input-prepend, .semi-input-append {
  height: 100%;
  display: flex;
  align-items: center;
  background-color: var(--semi-color-fill-0);
  color: var(--semi-color-text-2);
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  flex-shrink: 0;
}
.semi-input-prepend-icon, .semi-input-prepend-text, .semi-input-append-icon, .semi-input-append-text {
  padding: 0 12px;
}
.semi-input-append {
  border-radius: 0 var(--semi-border-radius-small) var(--semi-border-radius-small) 0;
  border-left: 1px transparent solid;
}
.semi-input-prepend {
  border-radius: var(--semi-border-radius-small) 0 0 var(--semi-border-radius-small);
  border-right: 1px transparent solid;
}
.semi-input-disabled::placeholder {
  color: var(--semi-color-disabled-text);
}
.semi-input-group {
  display: inline-flex;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}
.semi-input-group .semi-select,
.semi-input-group .semi-tagInput,
.semi-input-group .semi-cascader,
.semi-input-group .semi-tree-select, .semi-input-group > .semi-input-wrapper {
  border-radius: 0;
}
.semi-input-group .semi-select:first-child,
.semi-input-group .semi-tagInput:first-child,
.semi-input-group .semi-cascader:first-child,
.semi-input-group .semi-tree-select:first-child, .semi-input-group > .semi-input-wrapper:first-child {
  border-radius: var(--semi-border-radius-small) 0 0 var(--semi-border-radius-small);
}
.semi-input-group .semi-select:last-child,
.semi-input-group .semi-tagInput:last-child,
.semi-input-group .semi-cascader:last-child,
.semi-input-group .semi-tree-select:last-child, .semi-input-group > .semi-input-wrapper:last-child {
  border-radius: 0 var(--semi-border-radius-small) var(--semi-border-radius-small) 0;
}
.semi-input-group .semi-select:not(:last-child),
.semi-input-group .semi-tagInput:not(:last-child),
.semi-input-group .semi-cascader:not(:last-child),
.semi-input-group .semi-tree-select:not(:last-child), .semi-input-group > .semi-input-wrapper:not(:last-child) {
  position: relative;
}
.semi-input-group .semi-select:not(:last-child)::after,
.semi-input-group .semi-tagInput:not(:last-child)::after,
.semi-input-group .semi-cascader:not(:last-child)::after,
.semi-input-group .semi-tree-select:not(:last-child)::after, .semi-input-group > .semi-input-wrapper:not(:last-child)::after {
  content: "";
  background-color: var(--semi-color-border);
  width: 1px;
  position: absolute;
  right: -1px;
  top: 1px;
  bottom: 1px;
}
.semi-input-group .semi-select {
  overflow-y: visible;
}
.semi-input-group .semi-input-number,
.semi-input-group .semi-datepicker,
.semi-input-group .semi-timepicker,
.semi-input-group .semi-autocomplete {
  border-radius: 0;
}
.semi-input-group .semi-input-number .semi-datepicker-range-input,
.semi-input-group .semi-datepicker .semi-datepicker-range-input,
.semi-input-group .semi-timepicker .semi-datepicker-range-input,
.semi-input-group .semi-autocomplete .semi-datepicker-range-input {
  border-radius: 0;
}
.semi-input-group .semi-input-number:first-child .semi-input-wrapper,
.semi-input-group .semi-input-number:first-child .semi-datepicker-range-input,
.semi-input-group .semi-datepicker:first-child .semi-input-wrapper,
.semi-input-group .semi-datepicker:first-child .semi-datepicker-range-input,
.semi-input-group .semi-timepicker:first-child .semi-input-wrapper,
.semi-input-group .semi-timepicker:first-child .semi-datepicker-range-input,
.semi-input-group .semi-autocomplete:first-child .semi-input-wrapper,
.semi-input-group .semi-autocomplete:first-child .semi-datepicker-range-input {
  border-radius: var(--semi-border-radius-small) 0 0 var(--semi-border-radius-small);
}
.semi-input-group .semi-input-number:last-child .semi-input-wrapper,
.semi-input-group .semi-input-number:last-child .semi-datepicker-range-input,
.semi-input-group .semi-datepicker:last-child .semi-input-wrapper,
.semi-input-group .semi-datepicker:last-child .semi-datepicker-range-input,
.semi-input-group .semi-timepicker:last-child .semi-input-wrapper,
.semi-input-group .semi-timepicker:last-child .semi-datepicker-range-input,
.semi-input-group .semi-autocomplete:last-child .semi-input-wrapper,
.semi-input-group .semi-autocomplete:last-child .semi-datepicker-range-input {
  border-radius: 0 var(--semi-border-radius-small) var(--semi-border-radius-small) 0;
}
.semi-input-group .semi-input-number:not(:last-child),
.semi-input-group .semi-datepicker:not(:last-child),
.semi-input-group .semi-timepicker:not(:last-child),
.semi-input-group .semi-autocomplete:not(:last-child) {
  position: relative;
}
.semi-input-group .semi-input-number:not(:last-child)::after,
.semi-input-group .semi-datepicker:not(:last-child)::after,
.semi-input-group .semi-timepicker:not(:last-child)::after,
.semi-input-group .semi-autocomplete:not(:last-child)::after {
  content: "";
  background-color: var(--semi-color-border);
  width: 1px;
  position: absolute;
  right: -1px;
  top: 1px;
  bottom: 1px;
}
.semi-input-group-wrapper-with-top-label {
  margin-top: 16px;
  margin-bottom: 16px;
}
.semi-input-group-wrapper-with-top-label .semi-input-group {
  display: flex;
}
.semi-input-group-wrapper-with-top-label .semi-input-group .semi-form-field {
  margin-top: 0;
  margin-bottom: 0;
}

.semi-input-borderless:not(:focus-within):not(:hover) {
  background-color: transparent;
  border-color: transparent;
}
.semi-input-borderless:focus-within:not(:active) {
  background-color: transparent;
}
.semi-input-borderless.semi-input-wrapper-error:not(:focus-within) {
  border-color: var(--semi-color-danger);
}
.semi-input-borderless.semi-input-wrapper-warning:not(:focus-within) {
  border-color: var(--semi-color-warning);
}

.semi-rtl .semi-input-wrapper,
.semi-portal-rtl .semi-input-wrapper {
  direction: rtl;
}
.semi-rtl .semi-input-wrapper__with-prefix .semi-input,
.semi-portal-rtl .semi-input-wrapper__with-prefix .semi-input {
  padding-left: auto;
  padding-right: 0;
}
.semi-rtl .semi-input-wrapper__with-suffix .semi-input,
.semi-portal-rtl .semi-input-wrapper__with-suffix .semi-input {
  padding-right: auto;
  padding-left: 0;
}
.semi-rtl .semi-input,
.semi-portal-rtl .semi-input {
  padding-left: 12px;
  padding-right: 12px;
}
.semi-rtl .semi-input-inset-label,
.semi-portal-rtl .semi-input-inset-label {
  margin-right: auto;
  margin-left: 12px;
}
.semi-rtl .semi-input-clearbtn + .semi-rtl .semi-input-suffix + .semi-input-suffix-text,
.semi-rtl .semi-input-clearbtn + .semi-portal-rtl .semi-input-suffix + .semi-input-suffix-text,
.semi-portal-rtl .semi-input-clearbtn + .semi-rtl .semi-input-suffix + .semi-input-suffix-text,
.semi-portal-rtl .semi-input-clearbtn + .semi-portal-rtl .semi-input-suffix + .semi-input-suffix-text {
  margin-left: auto;
  margin-right: 0;
}
.semi-rtl .semi-input-clearbtn + .semi-rtl .semi-input-suffix + .semi-input-suffix-icon,
.semi-rtl .semi-input-clearbtn + .semi-portal-rtl .semi-input-suffix + .semi-input-suffix-icon,
.semi-portal-rtl .semi-input-clearbtn + .semi-rtl .semi-input-suffix + .semi-input-suffix-icon,
.semi-portal-rtl .semi-input-clearbtn + .semi-portal-rtl .semi-input-suffix + .semi-input-suffix-icon {
  margin-left: auto;
  margin-right: 0;
}
.semi-rtl .semi-input-append,
.semi-portal-rtl .semi-input-append {
  border-left: 0;
  border-right: 1px transparent solid;
}
.semi-rtl .semi-input-prepend,
.semi-portal-rtl .semi-input-prepend {
  border-right: 0;
  border-left: 1px transparent solid;
}
.semi-rtl .semi-input-group .semi-select:not(:last-child)::after,
.semi-rtl .semi-input-group .semi-cascader:not(:last-child)::after,
.semi-rtl .semi-input-group .semi-tree-select:not(:last-child)::after, .semi-rtl .semi-input-group > .semi-input-wrapper:not(:last-child)::after,
.semi-portal-rtl .semi-input-group .semi-select:not(:last-child)::after,
.semi-portal-rtl .semi-input-group .semi-cascader:not(:last-child)::after,
.semi-portal-rtl .semi-input-group .semi-tree-select:not(:last-child)::after,
.semi-portal-rtl .semi-input-group > .semi-input-wrapper:not(:last-child)::after {
  right: auto;
  left: -1px;
}
.semi-rtl .semi-input-group .semi-input-number:not(:last-child)::after,
.semi-portal-rtl .semi-input-group .semi-input-number:not(:last-child)::after {
  right: auto;
  left: -1px;
}
.semi-rtl .semi-input-textarea-wrapper,
.semi-portal-rtl .semi-input-textarea-wrapper {
  direction: rtl;
}
.semi-rtl .semi-input-textarea-counter,
.semi-portal-rtl .semi-input-textarea-counter {
  text-align: left;
}
.semi-rtl .semi-input-textarea-showClear,
.semi-portal-rtl .semi-input-textarea-showClear {
  padding-right: 0;
  padding-left: 36px;
}