/* shadow */
/* sizing */
/* spacing */
.semi-cascader {
  box-sizing: border-box;
  border-radius: var(--semi-border-radius-small);
  border: 1px solid transparent;
  min-width: 80px;
  min-height: 32px;
  height: auto;
  line-height: 32px;
  font-weight: 400;
  background-color: var(--semi-color-fill-0);
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
}
.semi-cascader:hover {
  background-color: var(--semi-color-fill-1);
  border: 1px transparent solid;
}
.semi-cascader:focus:not(.semi-cascader-disabled) {
  border: 1px solid var(--semi-color-focus-border);
  background-color: var(--semi-color-fill-0);
  outline: 0;
}
.semi-cascader:active {
  background-color: var(--semi-color-fill-2);
}
.semi-cascader-small {
  min-height: 24px;
  line-height: 24px;
}
.semi-cascader-large {
  min-height: 40px;
  line-height: 40px;
}
.semi-cascader-large .semi-cascader-selection {
  font-size: 16px;
  line-height: 22px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.semi-cascader-focus {
  border: 1px solid var(--semi-color-focus-border);
  outline: 0;
}
.semi-cascader-focus:hover {
  background-color: var(--semi-color-fill-0);
  border: 1px solid var(--semi-color-focus-border);
}
.semi-cascader-focus:active {
  background-color: var(--semi-color-fill-2);
}
.semi-cascader-warning {
  background-color: var(--semi-color-warning-light-default);
  border-color: var(--semi-color-warning-light-default);
}
.semi-cascader-warning:hover {
  background-color: var(--semi-color-warning-light-hover);
  border-color: var(--semi-color-warning-light-hover);
}
.semi-cascader-warning.semi-cascader-focus {
  background-color: var(--semi-color-warning-light-default);
  border-color: var(--semi-color-warning);
}
.semi-cascader-warning:active {
  background-color: var(--semi-color-warning-light-active);
  border-color: var(--semi-color-warning-light-active);
}
.semi-cascader-error {
  background-color: var(--semi-color-danger-light-default);
  border-color: var(--semi-color-danger-light-default);
}
.semi-cascader-error:hover {
  background-color: var(--semi-color-danger-light-hover);
  border-color: var(--semi-color-danger-light-hover);
}
.semi-cascader-error.semi-cascader-focus {
  background-color: var(--semi-color-danger-light-default);
  border-color: var(--semi-color-danger);
}
.semi-cascader-error:active {
  background-color: var(--semi-color-danger-light-active);
  border-color: var(--semi-color-danger-light-active);
}
.semi-cascader-selection {
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  height: 100%;
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  flex-grow: 1;
  overflow: hidden;
  padding-left: 12px;
  padding-right: 12px;
  cursor: pointer;
  color: var(--semi-color-text-0);
}
.semi-cascader-selection-multiple {
  padding-left: 4px;
  padding-right: 4px;
}
.semi-cascader-selection-placeholder {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--semi-color-text-2);
}
.semi-cascader-selection-tag {
  margin: 1px 2px 1px 0;
}
.semi-cascader-selection-tag:first-child {
  margin-left: 0;
}
.semi-cascader-selection-tag-disabled.semi-tag {
  color: var(--semi-color-disabled-text);
  background-color: transparent;
  cursor: not-allowed;
}
.semi-cascader-selection-tag-disabled.semi-tag .semi-tag-close {
  color: var(--semi-color-disabled-text);
  cursor: not-allowed;
  pointer-events: none;
}
.semi-cascader-selection-n {
  cursor: pointer;
  font-size: 12px;
  margin-right: 4px;
  color: var(--semi-color-text-0);
  padding-left: 8px;
  padding-right: 8px;
}
.semi-cascader-selection-n-disabled {
  cursor: not-allowed;
  color: var(--semi-color-disabled-text);
}
.semi-cascader-selection span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.semi-cascader-selection .semi-cascader-tagInput-wrapper {
  border: hidden;
  background: transparent;
  margin-left: -4px;
}
.semi-cascader-selection .semi-tagInput {
  min-height: 30px;
}
.semi-cascader-selection .semi-tagInput-small {
  min-height: 22px;
}
.semi-cascader-selection .semi-tagInput-large {
  min-height: 38px;
}
.semi-cascader-selection .semi-tagInput .semi-input-wrapper {
  margin-left: 4px;
}
.semi-cascader-selection .semi-tagInput .semi-input-wrapper .semi-input {
  padding-left: 0;
}
.semi-cascader-selection .semi-tagInput .semi-input-wrapper-default {
  margin-top: 1px;
  margin-bottom: 1px;
}
.semi-cascader-selection .semi-tagInput .semi-input-wrapper-large {
  margin-top: 1px;
  margin-bottom: 1px;
}
.semi-cascader-selection-text-inactive {
  color: var(--semi-color-text-2);
}
.semi-cascader-selection-text-hide {
  display: none;
}
.semi-cascader-arrow, .semi-cascader-clearbtn {
  display: inline-flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  width: 16px;
  color: var(--semi-color-text-2);
  margin-right: 12px;
}
.semi-cascader-clearbtn:hover {
  color: var(--semi-color-primary-hover);
}
.semi-cascader-clearbtn:active {
  color: var(--semi-color-primary-active);
}
.semi-cascader-prefix, .semi-cascader-suffix {
  display: inline;
  display: flex;
  justify-content: center;
  align-items: center;
}
.semi-cascader-prefix-text, .semi-cascader-suffix-text {
  margin: 0 12px;
}
.semi-cascader-prefix-icon, .semi-cascader-suffix-icon {
  color: var(--semi-color-text-2);
  margin: 0 8px;
}
.semi-cascader-inset-label {
  display: inline;
  margin-right: 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: var(--semi-color-text-2);
  flex-shrink: 0;
  white-space: nowrap;
}
.semi-cascader.semi-cascader-with-prefix {
  display: inline-flex;
  align-items: center;
}
.semi-cascader.semi-cascader-with-prefix .semi-cascader-selection {
  padding-left: 0;
}
.semi-cascader.semi-cascader-with-suffix .semi-cascader-selection {
  padding-right: 0;
}
.semi-cascader-disabled {
  cursor: not-allowed;
  user-select: none;
  background-color: var(--semi-color-disabled-fill);
}
.semi-cascader-disabled .semi-cascader-selection {
  cursor: not-allowed;
}
.semi-cascader-disabled:hover {
  background-color: var(--semi-color-disabled-fill);
}
.semi-cascader-disabled .semi-cascader-selection,
.semi-cascader-disabled .semi-cascader-selection-placeholder,
.semi-cascader-disabled .semi-cascader-prefix,
.semi-cascader-disabled .semi-cascader-suffix {
  color: var(--semi-color-disabled-text);
  cursor: not-allowed;
}
.semi-cascader-disabled .semi-cascader-arrow {
  color: var(--semi-color-disabled-text);
}

.semi-cascader-popover .semi-cascader-search-wrapper {
  padding: 8px 12px;
  border-bottom: 1px solid var(--semi-color-fill-0);
}
.semi-cascader-popover .semi-cascader-option-empty {
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  min-width: 150px;
  color: var(--semi-color-disabled-text);
  margin: 0;
  padding: 8px 12px;
  user-select: none;
  text-align: center;
  cursor: not-allowed;
}

.semi-cascader-single.semi-cascader-filterable {
  display: inline-flex;
}
.semi-cascader-single.semi-cascader-filterable .semi-cascader-selection .semi-cascader-search-wrapper {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  position: relative;
}
.semi-cascader-single.semi-cascader-filterable .semi-cascader-selection .semi-cascader-search-wrapper-small {
  height: 22px;
}
.semi-cascader-single.semi-cascader-filterable .semi-cascader-selection .semi-cascader-search-wrapper-large {
  height: 38px;
}
.semi-cascader-single.semi-cascader-filterable .semi-cascader-selection .semi-cascader-search-wrapper .semi-input-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  border: none;
  background-color: transparent;
  height: 100%;
  width: 100%;
  border: none;
  background-color: transparent;
}
.semi-cascader-single.semi-cascader-filterable .semi-cascader-selection .semi-cascader-search-wrapper .semi-input-wrapper-focus {
  border: none;
}
.semi-cascader-single.semi-cascader-filterable .semi-cascader-selection .semi-cascader-search-wrapper .semi-input {
  padding-left: 0;
  padding-right: 0;
}

.semi-cascader-option-lists {
  display: flex;
  overflow: hidden;
  padding: 0;
  margin: 0;
  height: 180px;
}
.semi-cascader-option-lists-empty {
  height: auto;
}
.semi-cascader-option-lists ul,
.semi-cascader-option-lists li {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.semi-cascader-option-lists ul > li {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 12px;
  padding-right: 16px;
}
.semi-cascader-option-lists .semi-cascader-option-list {
  box-sizing: border-box;
  display: inline-block;
  min-width: 150px;
  height: 100%;
  margin: 0;
  padding: 4px 0px;
  overflow: auto;
  list-style: none;
  border-left: 1px solid var(--semi-color-fill-0);
}
.semi-cascader-option-lists .semi-cascader-option-list:first-child {
  border-left: none;
}
.semi-cascader-option-lists .semi-cascader-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: background-color var(--semi-transition_duration-none) var(--semi-transition_function-easeIn) var(--semi-transition_delay-none);
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  min-width: min-content;
  word-break: break-all;
  color: var(--semi-color-text-0);
  position: relative;
}
.semi-cascader-option-lists .semi-cascader-option:hover {
  background-color: var(--semi-color-fill-0);
}
.semi-cascader-option-lists .semi-cascader-option:active {
  background-color: var(--semi-color-fill-1);
}
.semi-cascader-option-lists .semi-cascader-option-icon {
  display: inline-flex;
  flex-shrink: 0;
  width: 16px;
  color: var(--semi-color-text-2);
}
.semi-cascader-option-lists .semi-cascader-option-icon-active, .semi-cascader-option-lists .semi-cascader-option-icon-empty {
  margin-right: 8px;
}
.semi-cascader-option-lists .semi-cascader-option-icon-left {
  margin-left: 8px;
}
.semi-cascader-option-lists .semi-cascader-option-spin-icon {
  width: 16px;
  height: 16px;
  line-height: 0;
}
.semi-cascader-option-lists .semi-cascader-option-spin-icon svg {
  width: 16px;
  height: 16px;
}
.semi-cascader-option-lists .semi-cascader-option-label {
  display: flex;
  align-items: center;
  white-space: pre;
}
.semi-cascader-option-lists .semi-cascader-option-label-checkbox {
  margin-right: 8px;
}
.semi-cascader-option-lists .semi-cascader-option-hidden {
  display: none;
}
.semi-cascader-option-lists .semi-cascader-option-active {
  background-color: var(--semi-color-primary-light-default);
}
.semi-cascader-option-lists .semi-cascader-option-active:hover {
  background-color: var(--semi-color-primary-light-default);
}
.semi-cascader-option-lists .semi-cascader-option-select, .semi-cascader-option-lists .semi-cascader-option-label-highlight {
  font-weight: 600;
  color: var(--semi-color-primary);
}
.semi-cascader-option-lists .semi-cascader-option-disabled {
  cursor: not-allowed;
}
.semi-cascader-option-lists .semi-cascader-option-disabled:hover {
  background-color: transparent;
}
.semi-cascader-option-lists .semi-cascader-option-disabled:active {
  background-color: transparent;
}
.semi-cascader-option-lists .semi-cascader-option-disabled .semi-cascader-option-label {
  color: var(--semi-color-disabled-text);
}
.semi-cascader-option-lists .semi-cascader-option-flatten {
  padding-right: 64px;
}

.semi-cascader-borderless:not(:focus-within):not(:hover) {
  background-color: transparent;
  border-color: transparent;
}
.semi-cascader-borderless:not(:focus-within):not(:hover) .semi-cascader-arrow {
  opacity: 0;
}
.semi-cascader-borderless:focus-within:not(:active) {
  background-color: transparent;
}
.semi-cascader-borderless.semi-cascader-error:not(:focus-within) {
  border-color: var(--semi-color-danger);
}
.semi-cascader-borderless.semi-cascader-warning:not(:focus-within) {
  border-color: var(--semi-color-warning);
}
.semi-cascader-borderless.semi-cascader-error:focus-within {
  border-color: var(--semi-color-danger);
}
.semi-cascader-borderless.semi-cascader-warning:focus-within {
  border-color: var(--semi-color-warning);
}

.semi-rtl .semi-cascader,
.semi-portal-rtl .semi-cascader {
  direction: rtl;
}
.semi-rtl .semi-cascader-arrow, .semi-rtl .semi-cascader-clearbtn,
.semi-portal-rtl .semi-cascader-arrow,
.semi-portal-rtl .semi-cascader-clearbtn {
  margin-right: 0;
  margin-left: 12px;
}
.semi-rtl .semi-cascader-inset-label,
.semi-portal-rtl .semi-cascader-inset-label {
  margin-right: 12px;
  margin-left: 12px;
}
.semi-rtl .semi-cascader.semi-cascader-with-prefix .semi-cascader-selection,
.semi-portal-rtl .semi-cascader.semi-cascader-with-prefix .semi-cascader-selection {
  padding-left: auto;
  padding-right: 0;
}
.semi-rtl .semi-cascader.semi-cascader-with-suffix .semi-cascader-selection,
.semi-portal-rtl .semi-cascader.semi-cascader-with-suffix .semi-cascader-selection {
  padding-right: auto;
  padding-left: 0;
}
.semi-rtl .semi-cascader-selection-multiple,
.semi-portal-rtl .semi-cascader-selection-multiple {
  padding-right: 4px;
  padding-left: 4px;
}
.semi-rtl .semi-cascader-selection-tag:first-child,
.semi-portal-rtl .semi-cascader-selection-tag:first-child {
  margin-right: 0;
}
.semi-rtl .semi-cascader-selection-n,
.semi-portal-rtl .semi-cascader-selection-n {
  margin-right: 0;
  margin-left: 4px;
}
.semi-rtl .semi-cascader-selection .semi-cascader-tagInput-wrapper,
.semi-portal-rtl .semi-cascader-selection .semi-cascader-tagInput-wrapper {
  margin-left: 0;
  margin-right: -4px;
}
.semi-rtl .semi-cascader-selection .semi-tagInput .semi-input-wrapper,
.semi-portal-rtl .semi-cascader-selection .semi-tagInput .semi-input-wrapper {
  margin-left: 0;
  margin-right: 4px;
}
.semi-rtl .semi-cascader-selection .semi-tagInput .semi-input-wrapper .semi-input,
.semi-portal-rtl .semi-cascader-selection .semi-tagInput .semi-input-wrapper .semi-input {
  padding-right: 0;
}

.semi-cascader-option-label-checkbox {
  margin-right: 0;
  margin-left: 8px;
}

.semi-cascader-option-lists-rtl {
  direction: rtl;
}
.semi-cascader-option-lists .semi-cascader-option-lists-rtl ul > li {
  padding-right: 12px;
  padding-left: auto;
}
.semi-cascader-option-lists .semi-cascader-option-lists-rtl .semi-cascader-option-list {
  border-left: 0;
  border-right: 1px solid var(--semi-color-fill-0);
}
.semi-cascader-option-lists .semi-cascader-option-lists-rtl .semi-cascader-option-list:first-child {
  border-right: none;
}
.semi-cascader-option-lists .semi-cascader-option-lists-rtl .semi-cascader-option-icon-active, .semi-cascader-option-lists .semi-cascader-option-lists-rtl .semi-cascader-option-icon-empty {
  margin-right: 0;
  margin-left: 8px;
}
.semi-cascader-option-lists .semi-cascader-option-lists-rtl .semi-cascader-option-flatten {
  padding-right: 0;
  padding-left: 64px;
}
.semi-cascader-option-lists .semi-cascader-option-lists-rtl .semi-cascader-option .semi-icon-chevron_right {
  transform: scaleX(-1);
}