/* shadow */
/* sizing */
/* spacing */
.semi-switch {
  box-sizing: border-box;
  display: inline-block;
  border-radius: 12px;
  border: 1px transparent solid;
  position: relative;
  cursor: pointer;
  background-color: var(--semi-color-fill-0);
  transition: background-color 200ms var(--semi-transition_function-easeIn) var(--semi-transition_delay-none);
  width: 40px;
  height: 24px;
}
.semi-switch:hover {
  background-color: var(--semi-color-fill-1);
}
.semi-switch:active {
  border: 1px var(--semi-color-fill-2) solid;
}
.semi-switch:active .semi-switch-knob {
  width: 24px;
}
.semi-switch-focus {
  outline: 2px solid var(--semi-color-primary-light-active);
}
.semi-switch-checked {
  background-color: var(--semi-color-success);
}
.semi-switch-checked:hover {
  background-color: var(--semi-color-success-hover);
}
.semi-switch-checked .semi-switch-knob {
  transform: translateX(18px);
}
.semi-switch-checked:active .semi-switch-knob {
  transform: translateX(12px);
}
.semi-switch-active {
  background-color: var(--semi-color-success-active);
}
.semi-switch-disabled {
  cursor: not-allowed;
  background-color: transparent;
  border: 1px var(--semi-color-border) solid;
}
.semi-switch-disabled:hover {
  background-color: transparent;
}
.semi-switch-disabled:active {
  background-color: transparent;
}
.semi-switch-disabled .semi-switch-knob {
  cursor: not-allowed;
  box-shadow: none;
  border: 1px var(--semi-color-border) solid;
}
.semi-switch-disabled .semi-switch-native-control {
  pointer-events: none;
  cursor: not-allowed;
}
.semi-switch-disabled.semi-switch-checked {
  border-color: transparent;
  background-color: var(--semi-color-success-disabled);
}
.semi-switch-disabled.semi-switch-checked .semi-switch-knob {
  box-shadow: none;
  border: none;
}
.semi-switch-knob {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 0 1px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  border-radius: 9px;
  background-color: rgba(var(--semi-white), 1);
  box-sizing: border-box;
  position: absolute;
  left: 0px;
  right: auto;
  transition: transform 200ms ease-in-out, width 200ms ease-in-out;
  width: 18px;
  height: 18px;
  top: 2px;
  transform: translateX(2px);
}
.semi-switch-native-control {
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: inherit;
  pointer-events: auto;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
}
.semi-switch-native-control[type=checkbox] {
  width: inherit;
  height: inherit;
}
.semi-switch-checked-text, .semi-switch-unchecked-text {
  position: absolute;
  font-size: 12px;
  height: 100%;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.semi-switch-checked-text {
  color: var(--semi-color-white);
}
.semi-switch-unchecked-text {
  color: var(--semi-color-text-2);
  right: 0;
}
.semi-switch-loading {
  display: inline-flex;
  align-items: center;
  background-color: var(--semi-color-fill-1);
}
.semi-switch-loading-spin .semi-spin-wrapper {
  display: inline-flex;
  align-items: center;
  color: var(--semi-color-white);
}

.semi-switch-loading .semi-switch-loading-spin {
  transform: translateX(2px);
}
.semi-switch-loading .semi-switch-loading-spin > .semi-spin-wrapper > svg {
  width: 18px;
  height: 18px;
}
.semi-switch-loading.semi-switch-checked {
  background-color: var(--semi-color-success-hover);
}
.semi-switch-loading.semi-switch-checked .semi-switch-loading-spin {
  transform: translateX(16px);
}

.semi-switch-loading.semi-switch-small .semi-switch-loading-spin {
  transform: translateX(2px);
}
.semi-switch-loading.semi-switch-small .semi-switch-loading-spin > .semi-spin-wrapper > svg {
  width: 10px;
  height: 10px;
}
.semi-switch-loading.semi-switch-small.semi-switch-checked .semi-switch-loading-spin {
  transform: translateX(10px);
}

.semi-switch-loading.semi-switch-large .semi-switch-loading-spin {
  transform: translateX(2px);
}
.semi-switch-loading.semi-switch-large .semi-switch-loading-spin > .semi-spin-wrapper > svg {
  width: 28px;
  height: 28px;
}
.semi-switch-loading.semi-switch-large.semi-switch-checked .semi-switch-loading-spin {
  transform: translateX(22px);
}

.semi-switch-disabled.semi-switch-checked {
  background-color: var(--semi-color-success-disabled);
}

.semi-switch-large {
  width: 54px;
  height: 32px;
  border-radius: 16px;
}
.semi-switch-large .semi-switch-knob {
  width: 24px;
  height: 24px;
  top: 3px;
  border-radius: 12px;
  transform: translateX(3px);
}
.semi-switch-large.semi-switch-checked .semi-switch-knob {
  transform: translateX(26px);
}
.semi-switch-large.semi-switch-checked:active .semi-switch-knob {
  transform: translateX(16px);
}
.semi-switch-large:active .semi-switch-knob {
  width: 34px;
}
.semi-switch-large .semi-switch-checked-text,
.semi-switch-large .semi-switch-unchecked-text {
  width: 26px;
  font-size: 14px;
}

.semi-switch-small {
  width: 26px;
  height: 16px;
  border-radius: 8px;
}
.semi-switch-small .semi-switch-knob {
  width: 12px;
  height: 12px;
  top: 1px;
  border-radius: 6px;
  transform: translateX(1px);
}
.semi-switch-small.semi-switch-checked .semi-switch-knob {
  transform: translateX(11px);
}
.semi-switch-small.semi-switch-checked:active .semi-switch-knob {
  transform: translateX(9px);
}
.semi-switch-small:active .semi-switch-knob {
  width: 14px;
}

.semi-form .semi-switch-native-control {
  width: 100%;
  height: 100%;
}

.semi-rtl .semi-switch,
.semi-portal-rtl .semi-switch {
  direction: rtl;
}
.semi-rtl .semi-switch-checked .semi-switch-knob,
.semi-portal-rtl .semi-switch-checked .semi-switch-knob {
  transform: translateX(-18px);
}
.semi-rtl .semi-switch-checked:active .semi-switch-knob,
.semi-portal-rtl .semi-switch-checked:active .semi-switch-knob {
  transform: translateX(-12px);
}
.semi-rtl .semi-switch-knob,
.semi-portal-rtl .semi-switch-knob {
  right: 0;
  left: auto;
  transform: translateX(-2px);
}
.semi-rtl .semi-switch-native-control,
.semi-portal-rtl .semi-switch-native-control {
  right: 0;
}
.semi-rtl .semi-switch-unchecked-text,
.semi-portal-rtl .semi-switch-unchecked-text {
  left: 0;
}
.semi-rtl .semi-switch-loading .semi-switch-loading-spin,
.semi-portal-rtl .semi-switch-loading .semi-switch-loading-spin {
  transform: translateX(-2px);
}
.semi-rtl .semi-switch-loading.semi-switch-checked .semi-switch-loading-spin,
.semi-portal-rtl .semi-switch-loading.semi-switch-checked .semi-switch-loading-spin {
  transform: translateX(-16px);
}
.semi-rtl .semi-switch-loading.semi-switch-small .semi-switch-loading-spin,
.semi-portal-rtl .semi-switch-loading.semi-switch-small .semi-switch-loading-spin {
  transform: translateX(-2px);
}
.semi-rtl .semi-switch-loading.semi-switch-small.semi-switch-checked .semi-switch-loading-spin,
.semi-portal-rtl .semi-switch-loading.semi-switch-small.semi-switch-checked .semi-switch-loading-spin {
  transform: translateX(-10px);
}
.semi-rtl .semi-switch-loading.semi-switch-large .semi-switch-loading-spin,
.semi-portal-rtl .semi-switch-loading.semi-switch-large .semi-switch-loading-spin {
  transform: translateX(-2px);
}
.semi-rtl .semi-switch-loading.semi-switch-large.semi-switch-checked .semi-switch-loading-spin,
.semi-portal-rtl .semi-switch-loading.semi-switch-large.semi-switch-checked .semi-switch-loading-spin {
  transform: translateX(-22px);
}
.semi-rtl .semi-switch-large .semi-switch-knob,
.semi-portal-rtl .semi-switch-large .semi-switch-knob {
  transform: translateX(-3px);
}
.semi-rtl .semi-switch-large.semi-switch-checked .semi-switch-knob,
.semi-portal-rtl .semi-switch-large.semi-switch-checked .semi-switch-knob {
  transform: translateX(-26px);
}
.semi-rtl .semi-switch-large.semi-switch-checked:active .semi-switch-knob,
.semi-portal-rtl .semi-switch-large.semi-switch-checked:active .semi-switch-knob {
  transform: translateX(-16px);
}
.semi-rtl .semi-switch-small .semi-switch-knob,
.semi-portal-rtl .semi-switch-small .semi-switch-knob {
  transform: translateX(-1px);
}
.semi-rtl .semi-switch-small.semi-switch-checked .semi-switch-knob,
.semi-portal-rtl .semi-switch-small.semi-switch-checked .semi-switch-knob {
  transform: translateX(-11px);
}
.semi-rtl .semi-switch-small.semi-switch-checked:active .semi-switch-knob,
.semi-portal-rtl .semi-switch-small.semi-switch-checked:active .semi-switch-knob {
  transform: translateX(-9px);
}