/* shadow */
/* sizing */
/* spacing */
.semi-datepicker {
  box-sizing: border-box;
  display: inline-block;
}
.semi-datepicker .semi-scrolllist-body .semi-scrolllist-item {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.semi-datepicker .semi-scrolllist-body .semi-scrolllist-item::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
}
.semi-datepicker .semi-scrolllist-body .semi-scrolllist-list-outer {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.semi-datepicker .semi-scrolllist-body .semi-scrolllist-list-outer::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
}
.semi-datepicker-month-grid {
  user-select: none;
  display: flex;
}
.semi-datepicker-month-grid-left, .semi-datepicker-month-grid-right {
  position: relative;
  padding: 0;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
}
.semi-datepicker-month-grid[x-type=date] .semi-datepicker-yam, .semi-datepicker-month-grid[x-type=dateRange] .semi-datepicker-yam {
  height: 100%;
}
.semi-datepicker-month-grid[x-type=dateTime] .semi-datepicker-yam, .semi-datepicker-month-grid[x-type=dateTimeRange] .semi-datepicker-yam {
  height: calc(100% - 54px);
}
.semi-datepicker-month-grid .semi-datepicker-yam-showing {
  min-height: 378px;
  min-width: 284px;
}
.semi-datepicker-month-grid[x-type=date] .semi-datepicker-yam-showing {
  min-height: 325px;
}
.semi-datepicker-month-grid[x-insetinput=true] .semi-datepicker-month-grid-left[x-open-type=year],
.semi-datepicker-month-grid[x-insetinput=true] .semi-datepicker-month-grid-right[x-open-type=year] {
  min-height: 317px;
}
.semi-datepicker-month-grid[x-insetinput=true] .semi-datepicker-month-grid-left[x-open-type=time],
.semi-datepicker-month-grid[x-insetinput=true] .semi-datepicker-month-grid-right[x-open-type=time] {
  min-height: 317px;
}
.semi-datepicker-month-grid[x-insetinput=true] .semi-datepicker-navigation {
  padding-top: 8px;
  padding-bottom: 8px;
}
.semi-datepicker-month-grid[x-insetinput=true] .semi-datepicker-tpk {
  min-height: 100%;
}
.semi-datepicker-month-grid[x-insetinput=true][x-type=dateTime] .semi-datepicker-yam, .semi-datepicker-month-grid[x-insetinput=true][x-type=dateTimeRange] .semi-datepicker-yam {
  height: 100%;
}
.semi-datepicker-month-grid[x-preset-position=left][x-insetinput=false] .semi-datepicker-month, .semi-datepicker-month-grid[x-preset-position=right][x-insetinput=false] .semi-datepicker-month {
  height: 253px;
}
.semi-datepicker-month-grid .semi-datepicker-yearmonth-header {
  background: var(--semi-color-bg-3);
  padding: 12px 16px;
  border-bottom: 1px solid var(--semi-color-border);
  border-radius: var(--semi-border-radius-medium) var(--semi-border-radius-medium) 0 0;
  display: flex;
  align-items: center;
}
.semi-datepicker-month-grid .semi-scrolllist {
  background: var(--semi-color-bg-3);
  position: relative;
  box-shadow: none;
  height: auto;
  width: 100%;
  height: 100%;
  overflow: hidden;
  flex: 1;
}
.semi-datepicker-month-grid .semi-scrolllist-header, .semi-datepicker-month-grid .semi-scrolllist-body {
  box-sizing: border-box;
  width: 100%;
}
.semi-datepicker-month-grid .semi-scrolllist-header {
  border-bottom: 1px solid var(--semi-color-border);
  padding: 16px;
}
.semi-datepicker-month-grid .semi-scrolllist-body {
  height: 100%;
  overflow: hidden;
  padding: 0;
  flex: 1;
}
.semi-datepicker-month-grid .semi-scrolllist-line {
  display: none;
}
.semi-datepicker-month-grid .semi-scrolllist-header-title {
  padding: 0;
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  min-height: 24px;
  line-height: 24px;
}
.semi-datepicker-month-grid[x-panel-yearandmonth-open-type=left] .semi-datepicker-weeks, .semi-datepicker-month-grid[x-panel-yearandmonth-open-type=right] .semi-datepicker-weeks {
  min-height: 216px;
}
.semi-datepicker-panel-yam {
  max-width: 484px;
}
.semi-datepicker-panel-yam[x-type=monthRange] {
  max-width: 584px;
}
.semi-datepicker-panel-yam .semi-datepicker-yearmonth-body {
  display: flex;
}
.semi-datepicker-panel-yam .semi-datepicker-yearmonth-body .semi-scrolllist:nth-child(2) {
  border-left: 1px solid var(--semi-color-border);
}
.semi-datepicker-panel-yam .semi-scrolllist {
  box-shadow: none;
  height: 266px;
}
.semi-datepicker-panel-yam .semi-scrolllist-list-outer > ul > li {
  min-width: 64px;
}
.semi-datepicker-panel-yam .semi-scrolllist-item > ul > li {
  min-width: 82px;
}
.semi-datepicker-panel-yam .semi-scrolllist-body {
  padding: 0;
  overflow: hidden;
}
.semi-datepicker-panel-yam .semi-scrolllist-body .semi-scrolllist-item-wheel:not(#neverExistElement) {
  border: none;
}
.semi-datepicker-footer {
  padding-top: 10px;
  padding-right: 8px;
  padding-bottom: 10px;
  text-align: right;
  background-color: var(--semi-color-fill-0);
}
.semi-datepicker-footer .semi-button:first-of-type {
  margin-right: 12px;
}
.semi-datepicker-footer .semi-button:nth-of-type(2) {
  margin-right: 8px;
}
.semi-datepicker-yam {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.semi-datepicker-tpk {
  position: absolute;
  top: 0;
  height: calc(100% - 54px);
  width: 100%;
  display: flex;
  flex-direction: column;
}
.semi-datepicker-tpk .semi-datepicker-time {
  height: 100%;
}
.semi-datepicker-navigation {
  display: flex;
  align-items: center;
  box-sizing: content-box;
  height: 32px;
  padding: 12px 16px;
}
.semi-datepicker-navigation-left, .semi-datepicker-navigation-right {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.semi-datepicker-navigation .semi-button {
  color: var(--semi-color-text-2);
  min-width: 32px;
}
.semi-datepicker-navigation-month {
  font-size: 16px;
  line-height: 22px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  flex-grow: 1;
  text-align: center;
  font-weight: 600;
  color: var(--semi-color-text-0);
}
.semi-datepicker-navigation-month .semi-button {
  color: var(--semi-color-text-0);
}
.semi-datepicker-month {
  width: 252px;
  box-sizing: content-box;
  padding: 16px;
  padding-top: 0;
}
.semi-datepicker-weekday {
  font-size: 12px;
  line-height: 16px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  color: var(--semi-color-text-2);
  border-bottom: 1px solid var(--semi-color-border);
}
.semi-datepicker-weekday-item {
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  display: inline-block;
}
.semi-datepicker-weeks {
  color: var(--semi-color-text-0);
}
.semi-datepicker-week {
  display: flex;
  align-items: center;
}
.semi-datepicker-day {
  box-sizing: border-box;
  display: inline-block;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.semi-datepicker-day-main {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  box-sizing: border-box;
  border-radius: var(--semi-border-radius-small);
}
.semi-datepicker-day-main:hover {
  background-color: var(--semi-color-fill-0);
}
.semi-datepicker-day-main:active {
  background-color: var(--semi-color-fill-1);
}
.semi-datepicker-day-today .semi-datepicker-day-main {
  color: var(--semi-color-primary);
  background-color: var(--semi-color-fill-0);
  font-weight: 600;
}
.semi-datepicker-day-today.semi-datepicker-day-disabled .semi-datepicker-day-main {
  color: var(--semi-color-primary-disabled);
}
.semi-datepicker-day-inrange .semi-datepicker-day-main, .semi-datepicker-day-inhover .semi-datepicker-day-main, .semi-datepicker-day-inoffsetrange .semi-datepicker-day-main, .semi-datepicker-day-hoverday .semi-datepicker-day-main, .semi-datepicker-day-selectedrange-hover .semi-datepicker-day-main, .semi-datepicker-day-hoverday-around-singleselected .semi-datepicker-day-main {
  border-radius: 0;
  margin-left: 0;
  margin-right: 0;
  width: 36px;
}
.semi-datepicker-day-selected .semi-datepicker-day-main {
  border-radius: var(--semi-border-radius-small);
}
.semi-datepicker-day-selected-start.semi-datepicker-day-selected-end .semi-datepicker-day-main, .semi-datepicker-day-offsetrange-start.semi-datepicker-day-offsetrange-end .semi-datepicker-day-main {
  border-radius: var(--semi-border-radius-small);
}
.semi-datepicker-day-offsetrange-start .semi-datepicker-day-main {
  border-radius: var(--semi-border-radius-small) 0 0 var(--semi-border-radius-small);
}
.semi-datepicker-day-offsetrange-end .semi-datepicker-day-main {
  border-radius: 0 var(--semi-border-radius-small) var(--semi-border-radius-small) 0;
}
.semi-datepicker-day-hoverday-beforerange .semi-datepicker-day-main {
  border-radius: var(--semi-border-radius-small) 0 0 var(--semi-border-radius-small);
}
.semi-datepicker-day-hoverday-afterrange .semi-datepicker-day-main {
  border-radius: 0 var(--semi-border-radius-small) var(--semi-border-radius-small) 0;
}
.semi-datepicker-day-selected-start .semi-datepicker-day-main, .semi-datepicker-day-offsetrange-start .semi-datepicker-day-main {
  width: 34px;
  margin-left: 2px;
  margin-right: 0;
  border-radius: var(--semi-border-radius-small) 0 0 var(--semi-border-radius-small);
}
.semi-datepicker-day-selected-end .semi-datepicker-day-main, .semi-datepicker-day-offsetrange-end .semi-datepicker-day-main {
  width: 34px;
  margin-right: 2px;
  margin-left: 0;
  border-radius: 0 var(--semi-border-radius-small) var(--semi-border-radius-small) 0;
}
.semi-datepicker-day-hoverday-inrange .semi-datepicker-day-main {
  border-radius: 0;
}
.semi-datepicker-day-inoffsetrange .semi-datepicker-day-main, .semi-datepicker-day-offsetrange-start .semi-datepicker-day-main, .semi-datepicker-day-offsetrange-end .semi-datepicker-day-main {
  background-color: var(--semi-color-fill-0);
}
.semi-datepicker-day-hoverday .semi-datepicker-day-main, .semi-datepicker-day-hoverday-offset .semi-datepicker-day-main {
  background-color: var(--semi-color-fill-1);
}
.semi-datepicker-day-inrange .semi-datepicker-day-main, .semi-datepicker-day-inhover .semi-datepicker-day-main {
  background-color: var(--semi-color-primary-light-default);
}
.semi-datepicker-day-hoverday-inrange .semi-datepicker-day-main, .semi-datepicker-day-hoverday-beforerange .semi-datepicker-day-main, .semi-datepicker-day-hoverday-afterrange .semi-datepicker-day-main {
  background-color: var(--semi-color-primary-light-active);
}
.semi-datepicker-day-hoverday-around-singleselected .semi-datepicker-day-main {
  background-color: var(--semi-color-primary-light-active);
}
.semi-datepicker-day-selected .semi-datepicker-day-main, .semi-datepicker-day-selected-start .semi-datepicker-day-main, .semi-datepicker-day-selected-end .semi-datepicker-day-main {
  background-color: var(--semi-color-primary);
  color: var(--semi-color-white);
}
.semi-datepicker-day-selected .semi-datepicker-day-main:hover, .semi-datepicker-day-selected-start .semi-datepicker-day-main:hover, .semi-datepicker-day-selected-end .semi-datepicker-day-main:hover {
  background-color: var(--semi-color-primary);
}
.semi-datepicker-day-selectedrange-hover .semi-datepicker-day-main {
  background-color: var(--semi-color-primary-light-hover);
}
.semi-datepicker-day-selected-start-afterhover .semi-datepicker-day-main, .semi-datepicker-day-selected-end-beforehover .semi-datepicker-day-main {
  border: 1px solid var(--semi-color-primary-active);
}
.semi-datepicker-day-disabled {
  cursor: not-allowed;
  color: var(--semi-color-disabled-text);
}
.semi-datepicker-day-disabled .semi-datepicker-day-main:hover {
  background-color: transparent;
}
.semi-datepicker-day-disabled .semi-datepicker-day-main:active {
  background-color: transparent;
}
.semi-datepicker-day-disabled.semi-datepicker-day-selected .semi-datepicker-day-main, .semi-datepicker-day-disabled.semi-datepicker-day-selected-start .semi-datepicker-day-main, .semi-datepicker-day-disabled.semi-datepicker-day-selected-end .semi-datepicker-day-main, .semi-datepicker-day-disabled.semi-datepicker-day-inhover .semi-datepicker-day-main, .semi-datepicker-day-disabled.semi-datepicker-day-inrange .semi-datepicker-day-main, .semi-datepicker-day-disabled.semi-datepicker-day-inoffsetrange .semi-datepicker-day-main, .semi-datepicker-day-disabled.semi-datepicker-day-hoverday .semi-datepicker-day-main, .semi-datepicker-day-disabled.semi-datepicker-day-hoverday-offset .semi-datepicker-day-main, .semi-datepicker-day-disabled.semi-datepicker-day-offsetrange-start .semi-datepicker-day-main, .semi-datepicker-day-disabled.semi-datepicker-day-offsetrange-end .semi-datepicker-day-main, .semi-datepicker-day-disabled.semi-datepicker-day-selectedrange-hover .semi-datepicker-day-main {
  background-color: var(--semi-color-disabled-fill);
  color: var(--semi-color-disabled-text);
}
.semi-datepicker-switch {
  text-align: center;
  display: flex;
  border-top: 1px solid var(--semi-color-border);
  margin-top: auto;
}
.semi-datepicker-switch-date, .semi-datepicker-switch-time {
  width: 50%;
  cursor: pointer;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--semi-color-text-2);
}
.semi-datepicker-switch-date:not(.semi-datepicker-switch-time-disabled):hover, .semi-datepicker-switch-time:not(.semi-datepicker-switch-time-disabled):hover {
  background-color: var(--semi-color-fill-0);
}
.semi-datepicker-switch-date-active, .semi-datepicker-switch-time-active {
  color: var(--semi-color-text-0);
  font-weight: 600;
  cursor: auto;
}
.semi-datepicker-switch-date-active:hover, .semi-datepicker-switch-time-active:hover {
  background-color: inherit;
}
.semi-datepicker-switch-date-disabled, .semi-datepicker-switch-time-disabled {
  cursor: not-allowed;
}
.semi-datepicker-switch-text {
  padding-left: 8px;
}
.semi-datepicker-time .semi-jumperlist {
  box-shadow: none;
  border-radius: 0;
}
.semi-datepicker-quick-control {
  box-sizing: border-box;
  background-color: transparent;
}
.semi-datepicker-quick-control-header {
  padding: 18px 12px 0;
  font-weight: 600;
}
.semi-datepicker-quick-control-left {
  border-right: 1px solid var(--semi-color-border);
}
.semi-datepicker-quick-control-right {
  border-left: 1px solid var(--semi-color-border);
}
.semi-datepicker-quick-control-top {
  border-bottom: 1px solid var(--semi-color-border);
}
.semi-datepicker-quick-control-bottom {
  border-top: 1px solid var(--semi-color-border);
}
.semi-datepicker-quick-control-left-content-wrapper, .semi-datepicker-quick-control-right-content-wrapper {
  max-width: 200px;
  margin-top: 14px;
  overflow-y: auto;
}
.semi-datepicker-quick-control-top-content-wrapper, .semi-datepicker-quick-control-bottom-content-wrapper {
  overflow-y: auto;
  max-height: 100px;
}
.semi-datepicker-quick-control-left-content, .semi-datepicker-quick-control-right-content {
  box-sizing: border-box;
  display: grid;
  align-content: flex-start;
  grid-gap: 8px;
  grid-template-columns: repeat(2, minmax(76.5px, 84px));
  padding: 0 12px 12px;
}
.semi-datepicker-quick-control-left-content-item, .semi-datepicker-quick-control-right-content-item {
  max-width: 84px;
}
.semi-datepicker-quick-control-left-content-item-ellipsis, .semi-datepicker-quick-control-right-content-item-ellipsis {
  width: 68px;
  color: var(--semi-color-primary);
}
.semi-datepicker-quick-control-top-content, .semi-datepicker-quick-control-bottom-content {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(3, minmax(70.924px, 75.924px));
  align-content: flex-start;
  padding: 8px 20px;
}
.semi-datepicker-quick-control-top-content-item, .semi-datepicker-quick-control-bottom-content-item {
  max-width: 75.924px;
}
.semi-datepicker-quick-control-top-content-item-ellipsis, .semi-datepicker-quick-control-bottom-content-item-ellipsis {
  width: 59.924px;
  color: var(--semi-color-primary);
}
.semi-datepicker-quick-control-top-range-content, .semi-datepicker-quick-control-bottom-range-content {
  box-sizing: border-box;
  display: grid;
  align-content: flex-start;
  grid-template-columns: repeat(5, minmax(96.2px, 99.2px));
  grid-gap: 8px;
  padding: 8px 20px;
}
.semi-datepicker-quick-control-top-range-content-item, .semi-datepicker-quick-control-bottom-range-content-item {
  max-width: 99.2px;
}
.semi-datepicker-quick-control-top-range-content-item-ellipsis, .semi-datepicker-quick-control-bottom-range-content-item-ellipsis {
  width: 83.2px;
  color: var(--semi-color-primary);
}
.semi-datepicker-quick-control-top-month-content, .semi-datepicker-quick-control-bottom-month-content {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(2, minmax(51px, 58.5px));
  grid-gap: 8px;
  align-content: flex-start;
  padding: 8px 20px;
}
.semi-datepicker-quick-control-top-month-content-item, .semi-datepicker-quick-control-bottom-month-content-item {
  max-width: 58.5px;
}
.semi-datepicker-quick-control-top-month-content-item-ellipsis, .semi-datepicker-quick-control-bottom-month-content-item-ellipsis {
  max-width: 42.5px;
  color: var(--semi-color-primary);
}
.semi-datepicker-quick-control-month {
  max-width: 284px;
}
.semi-datepicker-quick-control-month[x-insetinput=true] .semi-datepicker-quick-control-right-content-wrapper,
.semi-datepicker-quick-control-month[x-insetinput=true] .semi-datepicker-quick-control-left-content-wrapper {
  max-height: 258px;
}
.semi-datepicker-quick-control-month .semi-datepicker-quick-control-right-content-wrapper,
.semi-datepicker-quick-control-month .semi-datepicker-quick-control-left-content-wrapper {
  max-height: 214px;
}
.semi-datepicker-quick-control-date {
  max-width: 284px;
}
.semi-datepicker-quick-control-date[x-insetinput=true] .semi-datepicker-quick-control-right-content-wrapper,
.semi-datepicker-quick-control-date[x-insetinput=true] .semi-datepicker-quick-control-left-content-wrapper {
  max-height: 309px;
}
.semi-datepicker-quick-control-date .semi-datepicker-quick-control-right-content-wrapper,
.semi-datepicker-quick-control-date .semi-datepicker-quick-control-left-content-wrapper {
  max-height: 273px;
}
.semi-datepicker-quick-control-dateTime {
  max-width: 284px;
}
.semi-datepicker-quick-control-dateTime[x-insetinput=true] .semi-datepicker-quick-control-right-content-wrapper,
.semi-datepicker-quick-control-dateTime[x-insetinput=true] .semi-datepicker-quick-control-left-content-wrapper {
  max-height: 309px;
}
.semi-datepicker-quick-control-dateTime .semi-datepicker-quick-control-right-content-wrapper,
.semi-datepicker-quick-control-dateTime .semi-datepicker-quick-control-left-content-wrapper {
  max-height: 326px;
}
.semi-datepicker-quick-control-dateRange {
  max-width: 568px;
}
.semi-datepicker-quick-control-dateRange[x-insetinput=true] .semi-datepicker-quick-control-right-content-wrapper,
.semi-datepicker-quick-control-dateRange[x-insetinput=true] .semi-datepicker-quick-control-left-content-wrapper {
  max-height: 309px;
}
.semi-datepicker-quick-control-dateRange .semi-datepicker-quick-control-right-content-wrapper,
.semi-datepicker-quick-control-dateRange .semi-datepicker-quick-control-left-content-wrapper {
  max-height: 273px;
}
.semi-datepicker-quick-control-dateTimeRange {
  max-width: 568px;
}
.semi-datepicker-quick-control-dateTimeRange[x-insetinput=true] .semi-datepicker-quick-control-right-content-wrapper,
.semi-datepicker-quick-control-dateTimeRange[x-insetinput=true] .semi-datepicker-quick-control-left-content-wrapper {
  max-height: 309px;
}
.semi-datepicker-quick-control-dateTimeRange .semi-datepicker-quick-control-right-content-wrapper,
.semi-datepicker-quick-control-dateTimeRange .semi-datepicker-quick-control-left-content-wrapper {
  max-height: 326px;
}
.semi-datepicker-topSlot {
  border-bottom: 1px solid var(--semi-color-border);
}
.semi-datepicker-bottomSlot {
  border-top: 1px solid var(--semi-color-border);
}
.semi-datepicker .semi-popover-trigger {
  width: 100%;
}
.semi-datepicker .semi-popover-trigger .semi-input-wrapper {
  font-size: 13px;
}
.semi-datepicker .semi-popover-trigger .semi-input-suffix {
  color: var(--semi-color-primary);
}
.semi-datepicker .semi-datepicker-input-readonly {
  cursor: pointer;
}
.semi-datepicker-inset-input-wrapper {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  box-sizing: border-box;
  column-gap: 8px;
  padding: 12px 16px;
  padding-bottom: 0;
  width: 284px;
}
.semi-datepicker-inset-input-wrapper[x-type=dateRange], .semi-datepicker-inset-input-wrapper[x-type=dateTimeRange] {
  width: 568px;
}
.semi-datepicker-inset-input-wrapper[x-type=month] {
  width: 165px;
}
.semi-datepicker-inset-input-wrapper[x-type=monthRange] {
  width: 331px;
}
.semi-datepicker-inset-input-wrapper .semi-input-wrapper {
  flex: 1;
  flex-shrink: 0;
}
.semi-datepicker-inset-input-separator {
  flex-grow: 0;
  flex-shrink: 0;
  height: 32px;
  line-height: 32px;
  padding: 0 4px;
  color: var(--semi-color-text-3);
}
.semi-datepicker-range-input {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-radius: var(--semi-border-radius-small);
  height: 32px;
  color: var(--semi-color-text-2);
  background-color: var(--semi-color-fill-0);
  border: 0px solid transparent;
}
.semi-datepicker-range-input:hover {
  border-color: transparent;
}
.semi-datepicker-range-input:active {
  border-color: transparent;
}
.semi-datepicker-range-input .semi-datepicker-monthRange-input {
  background-color: transparent;
}
.semi-datepicker-range-input-wrapper {
  box-sizing: border-box;
  background-color: transparent;
  padding: 0 8px;
  height: 100%;
  width: 100%;
  border: 1px solid transparent;
  border-radius: var(--semi-border-radius-small);
  display: flex;
  align-items: center;
}
.semi-datepicker-range-input-wrapper .semi-input-wrapper {
  background-color: transparent;
  border: none;
}
.semi-datepicker-range-input-wrapper-focus {
  border-color: transparent;
  background-color: var(--semi-color-fill-1);
  border-radius: var(--semi-border-radius-small);
  transition: background-color 0.16s ease-in-out;
}
.semi-datepicker-range-input-wrapper-focus ~ .semi-datepicker-range-input-clearbtn {
  display: flex;
}
.semi-datepicker-range-input-wrapper-focus ~ .semi-datepicker-range-input-clearbtn ~ .semi-datepicker-range-input-suffix {
  display: none;
}
.semi-datepicker-range-input-wrapper .semi-input {
  padding: 2px 4px;
}
.semi-datepicker-range-input-wrapper-icon {
  flex-shrink: 0;
}
.semi-datepicker-range-input-wrapper:hover {
  background-color: var(--semi-color-fill-1);
}
.semi-datepicker-range-input-wrapper-start {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.semi-datepicker-range-input-wrapper-start-with-prefix {
  border-radius: 0;
}
.semi-datepicker-range-input-wrapper-end {
  border-radius: 0;
}
.semi-datepicker-range-input-wrapper-active {
  border-color: var(--semi-color-focus-border);
  background-color: var(--semi-color-fill-1);
}
.semi-datepicker-range-input-separator {
  display: flex;
  align-items: center;
}
.semi-datepicker-range-input-separator-active {
  color: var(--semi-color-text-0);
}
.semi-datepicker-range-input-small {
  height: 24px;
}
.semi-datepicker-range-input-large {
  height: 40px;
}
.semi-datepicker-range-input-large .semi-input-default {
  font-size: 16px;
}
.semi-datepicker-range-input:hover .semi-datepicker-range-input-clearbtn {
  display: flex;
  cursor: pointer;
}
.semi-datepicker-range-input:hover .semi-datepicker-range-input-clearbtn ~ .semi-datepicker-range-input-suffix {
  display: none;
}
.semi-datepicker-range-input:hover .semi-datepicker-range-input-clearbtn:hover {
  color: var(--semi-color-primary-hover);
}
.semi-datepicker-range-input-disabled {
  cursor: not-allowed;
  color: var(--semi-color-disabled-text);
  background-color: var(--semi-color-disabled-fill);
}
.semi-datepicker-range-input-disabled .semi-datepicker-range-input-wrapper:hover {
  background-color: transparent;
}
.semi-datepicker-range-input-disabled:hover {
  background-color: var(--semi-color-disabled-fill);
}
.semi-datepicker-range-input-disabled .semi-datepicker-range-input-prefix,
.semi-datepicker-range-input-disabled .semi-datepicker-range-input-suffix,
.semi-datepicker-range-input-disabled .semi-datepicker-range-input-clearbtn {
  color: var(--semi-color-disabled-text);
}
.semi-datepicker-range-input-warning {
  background-color: var(--semi-color-warning-light-default);
}
.semi-datepicker-range-input-warning .semi-datepicker-range-input-wrapper:hover {
  background-color: var(--semi-color-warning-light-hover);
}
.semi-datepicker-range-input-warning .semi-datepicker-range-input-wrapper-active {
  border-color: var(--semi-color-warning);
  background-color: var(--semi-color-warning-light-hover);
}
.semi-datepicker-range-input-error {
  background-color: var(--semi-color-danger-light-default);
}
.semi-datepicker-range-input-error .semi-datepicker-range-input-wrapper:hover {
  background-color: var(--semi-color-danger-light-hover);
}
.semi-datepicker-range-input-error .semi-datepicker-range-input-wrapper-active {
  border-color: var(--semi-color-danger);
  background-color: var(--semi-color-danger-light-hover);
}
.semi-datepicker-range-input .semi-datepicker-range-input-clearbtn {
  display: none;
}
.semi-datepicker-range-input-prefix, .semi-datepicker-range-input-suffix, .semi-datepicker-range-input-clearbtn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  color: var(--semi-color-text-2);
}
.semi-datepicker-range-input-prefix {
  padding-left: 12px;
  padding-right: 8px;
}
.semi-datepicker-range-input-suffix {
  padding-left: 8px;
  padding-right: 12px;
}
.semi-datepicker-range-input-suffix-hidden {
  display: none;
}
.semi-datepicker-range-input-clearbtn {
  padding-left: 8px;
  padding-right: 12px;
}

/*
* 小尺寸 DatePicker
*/
.semi-datepicker-compact {
  font-size: 12px;
  line-height: 16px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 20px;
}
.semi-datepicker-compact .semi-datepicker-month-grid[x-type=dateTime] .semi-datepicker-yam, .semi-datepicker-compact .semi-datepicker-month-grid[x-type=dateTimeRange] .semi-datepicker-yam {
  height: calc(100% - 32px);
}
.semi-datepicker-compact .semi-datepicker-month-grid[x-type=dateRange] .semi-datepicker-month-grid-left, .semi-datepicker-compact .semi-datepicker-month-grid[x-type=dateTimeRange] .semi-datepicker-month-grid-left {
  border-right: 1px solid var(--semi-color-border);
}
.semi-datepicker-compact .semi-datepicker-month-grid .semi-datepicker-yam-showing {
  min-height: 288px;
  min-width: 216px;
}
.semi-datepicker-compact .semi-datepicker-month-grid[x-type=date] .semi-datepicker-yam-showing {
  min-height: 256px;
}
.semi-datepicker-compact .semi-datepicker-month-grid[x-preset-position=left][x-insetinput=false] .semi-datepicker-month, .semi-datepicker-compact .semi-datepicker-month-grid[x-preset-position=right][x-insetinput=false] .semi-datepicker-month {
  height: 220px;
}
.semi-datepicker-compact .semi-datepicker-month-grid .semi-datepicker-yearmonth-header {
  box-sizing: border-box;
  height: 48px;
  padding: 12px;
}
.semi-datepicker-compact .semi-datepicker-month-grid .semi-datepicker-yearmonth-body {
  display: flex;
}
.semi-datepicker-compact .semi-datepicker-month-grid .semi-datepicker-yearmonth-body .semi-scrolllist:nth-child(2) {
  border: 1px solid var(--semi-color-border);
}
.semi-datepicker-compact .semi-datepicker-month-grid .semi-scrolllist {
  font-size: 12px;
  line-height: 16px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 20px;
}
.semi-datepicker-compact .semi-datepicker-month-grid .semi-scrolllist-header {
  display: none;
}
.semi-datepicker-compact .semi-datepicker-month-grid .semi-scrolllist-list-outer > ul > li {
  height: 32px;
}
.semi-datepicker-compact .semi-datepicker-month-grid .semi-scrolllist-item-wheel {
  border-right: 0;
}
.semi-datepicker-compact .semi-datepicker-month-grid .semi-scrolllist-item-wheel .semi-scrolllist-selector {
  height: 32px;
}
.semi-datepicker-compact .semi-datepicker-month-grid .semi-scrolllist-item-wheel .semi-scrolllist-shade-pre {
  margin-top: -17px;
}
.semi-datepicker-compact .semi-datepicker-month-grid .semi-scrolllist-item-wheel .semi-scrolllist-shade-post {
  margin-top: 17px;
}
.semi-datepicker-compact .semi-datepicker-month-grid[x-panel-yearandmonth-open-type=left] .semi-datepicker-weeks, .semi-datepicker-compact .semi-datepicker-month-grid[x-panel-yearandmonth-open-type=right] .semi-datepicker-weeks {
  min-height: 168px;
}
.semi-datepicker-compact .semi-datepicker-month-grid[x-insetinput=true] .semi-datepicker-month-grid-left[x-open-type=year],
.semi-datepicker-compact .semi-datepicker-month-grid[x-insetinput=true] .semi-datepicker-month-grid-right[x-open-type=year] {
  min-height: 256px;
}
.semi-datepicker-compact .semi-datepicker-month-grid[x-insetinput=true] .semi-datepicker-month-grid-left[x-open-type=time],
.semi-datepicker-compact .semi-datepicker-month-grid[x-insetinput=true] .semi-datepicker-month-grid-right[x-open-type=time] {
  min-height: 256px;
}
.semi-datepicker-compact .semi-datepicker-month-grid[x-insetinput=true] .semi-datepicker-yam-showing {
  min-height: 256px;
}
.semi-datepicker-compact .semi-datepicker-month-grid[x-insetinput=true] .semi-datepicker-tpk {
  min-height: 100%;
}
.semi-datepicker-compact .semi-datepicker-month-grid[x-insetinput=true][x-type=dateTime] .semi-datepicker-yam, .semi-datepicker-compact .semi-datepicker-month-grid[x-insetinput=true][x-type=dateTimeRange] .semi-datepicker-yam {
  height: 100%;
}
.semi-datepicker-compact.semi-datepicker-panel-yam .semi-scrolllist {
  font-size: 12px;
  line-height: 16px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 20px;
}
.semi-datepicker-compact .semi-datepicker-tpk {
  height: calc(100% - 32px);
}
.semi-datepicker-compact .semi-datepicker-navigation {
  height: 24px;
  padding: 12px;
  padding-bottom: 0;
}
.semi-datepicker-compact .semi-datepicker-navigation-left, .semi-datepicker-compact .semi-datepicker-navigation-right {
  width: 24px;
  height: 24px;
}
.semi-datepicker-compact .semi-datepicker-navigation .semi-button {
  min-width: 24px;
  min-height: 24px;
}
.semi-datepicker-compact .semi-datepicker-navigation-month .semi-button {
  font-size: 12px;
  line-height: 16px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 20px;
}
.semi-datepicker-compact .semi-datepicker-month {
  width: 216px;
  padding: 0;
}
.semi-datepicker-compact .semi-datepicker-weeks {
  padding: 10px;
  padding-top: 6px;
}
.semi-datepicker-compact .semi-datepicker-weekday {
  box-sizing: border-box;
  height: 36px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 8px;
  border-bottom: 1px solid var(--semi-color-border);
}
.semi-datepicker-compact .semi-datepicker-weekday-item {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.semi-datepicker-compact .semi-datepicker-day {
  width: 28px;
  height: 28px;
}
.semi-datepicker-compact .semi-datepicker-day-main {
  width: 24px;
  height: 24px;
}
.semi-datepicker-compact .semi-datepicker-day-inrange .semi-datepicker-day-main, .semi-datepicker-compact .semi-datepicker-day-inhover .semi-datepicker-day-main, .semi-datepicker-compact .semi-datepicker-day-inoffsetrange .semi-datepicker-day-main, .semi-datepicker-compact .semi-datepicker-day-hoverday .semi-datepicker-day-main, .semi-datepicker-compact .semi-datepicker-day-selectedrange-hover .semi-datepicker-day-main {
  width: 28px;
}
.semi-datepicker-compact .semi-datepicker-day-selected-start.semi-datepicker-day-selected-end .semi-datepicker-day-main, .semi-datepicker-compact .semi-datepicker-day-offsetrange-start.semi-datepicker-day-offsetrange-end .semi-datepicker-day-main {
  border-radius: 4px;
}
.semi-datepicker-compact .semi-datepicker-day-offsetrange-start .semi-datepicker-day-main {
  border-radius: 4px 0 0 4px;
}
.semi-datepicker-compact .semi-datepicker-day-offsetrange-end .semi-datepicker-day-main {
  border-radius: 0 4px 4px 0;
}
.semi-datepicker-compact .semi-datepicker-day-hoverday-beforerange .semi-datepicker-day-main {
  border-radius: 4px 0 0 4px;
}
.semi-datepicker-compact .semi-datepicker-day-hoverday-afterrange .semi-datepicker-day-main {
  border-radius: 0 4px 4px 0;
}
.semi-datepicker-compact .semi-datepicker-day-selected-start .semi-datepicker-day-main, .semi-datepicker-compact .semi-datepicker-day-offsetrange-start .semi-datepicker-day-main {
  width: 34px;
  margin-left: 2px;
  margin-right: 0;
  border-radius: 4px 0 0 4px;
}
.semi-datepicker-compact .semi-datepicker-day-selected-end .semi-datepicker-day-main, .semi-datepicker-compact .semi-datepicker-day-offsetrange-end .semi-datepicker-day-main {
  width: 34px;
  margin-right: 2px;
  margin-left: 0;
  border-radius: 0 4px 4px 0;
}
.semi-datepicker-compact .semi-datepicker-switch {
  box-sizing: border-box;
  height: 32px;
}
.semi-datepicker-compact .semi-datepicker-switch-date, .semi-datepicker-compact .semi-datepicker-switch-time {
  padding-top: 6px;
  padding-bottom: 6px;
}
.semi-datepicker-compact .semi-datepicker-switch-date {
  border-right: 1px solid var(--semi-color-border);
}
.semi-datepicker-compact .semi-datepicker-switch-text {
  padding-left: 0;
}
.semi-datepicker-compact .semi-datepicker-quick-control-header {
  padding: 16px 12px 0;
  font-weight: 600;
}
.semi-datepicker-compact .semi-datepicker-quick-control-left-content-wrapper, .semi-datepicker-compact .semi-datepicker-quick-control-right-content-wrapper {
  margin-top: 12px;
}
.semi-datepicker-compact .semi-datepicker-quick-control-left-content, .semi-datepicker-compact .semi-datepicker-quick-control-right-content {
  box-sizing: border-box;
  display: grid;
  align-content: flex-start;
  grid-gap: 8px;
  grid-template-columns: repeat(2, minmax(76.5px, 84px));
  padding: 0 12px 12px;
}
.semi-datepicker-compact .semi-datepicker-quick-control-left-content-item, .semi-datepicker-compact .semi-datepicker-quick-control-right-content-item {
  max-width: 84px;
}
.semi-datepicker-compact .semi-datepicker-quick-control-left-content-item-ellipsis, .semi-datepicker-compact .semi-datepicker-quick-control-right-content-item-ellipsis {
  width: 68px;
  font-size: 12px;
  color: var(--semi-color-primary);
}
.semi-datepicker-compact .semi-datepicker-quick-control-top-content, .semi-datepicker-compact .semi-datepicker-quick-control-bottom-content {
  box-sizing: border-box;
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(3, minmax(54.94px, 59.94px));
  align-content: flex-start;
  padding: 8px 10px;
}
.semi-datepicker-compact .semi-datepicker-quick-control-top-content-item, .semi-datepicker-compact .semi-datepicker-quick-control-bottom-content-item {
  max-width: 59.94px;
}
.semi-datepicker-compact .semi-datepicker-quick-control-top-content-item-ellipsis, .semi-datepicker-compact .semi-datepicker-quick-control-bottom-content-item-ellipsis {
  width: 43.94px;
  font-size: 12px;
  color: var(--semi-color-primary);
}
.semi-datepicker-compact .semi-datepicker-quick-control-top-range-content, .semi-datepicker-compact .semi-datepicker-quick-control-bottom-range-content {
  display: grid;
  grid-template-columns: repeat(5, minmax(73px, 76px));
  grid-gap: 8px;
  align-content: flex-start;
  padding: 8px 10px;
}
.semi-datepicker-compact .semi-datepicker-quick-control-top-range-content-item, .semi-datepicker-compact .semi-datepicker-quick-control-bottom-range-content-item {
  max-width: 76px;
}
.semi-datepicker-compact .semi-datepicker-quick-control-top-range-content-item-ellipsis, .semi-datepicker-compact .semi-datepicker-quick-control-bottom-range-content-item-ellipsis {
  width: 60px;
  font-size: 12px;
  color: var(--semi-color-primary);
}
.semi-datepicker-compact .semi-datepicker-quick-control-top-month-content, .semi-datepicker-compact .semi-datepicker-quick-control-bottom-month-content {
  display: grid;
  grid-template-columns: repeat(2, minmax(61px, 68.5px));
  grid-gap: 8px;
  align-content: flex-start;
  padding: 8px 10px;
}
.semi-datepicker-compact .semi-datepicker-quick-control-top-month-content-item, .semi-datepicker-compact .semi-datepicker-quick-control-bottom-month-content-item {
  max-width: 68.5px;
}
.semi-datepicker-compact .semi-datepicker-quick-control-top-month-content-item-ellipsis, .semi-datepicker-compact .semi-datepicker-quick-control-bottom-month-content-item-ellipsis {
  max-width: 52.5px;
  font-size: 12px;
  color: var(--semi-color-primary);
}
.semi-datepicker-compact .semi-datepicker-quick-control-month {
  max-width: 216px;
}
.semi-datepicker-compact .semi-datepicker-quick-control-month[x-insetinput=true] .semi-datepicker-quick-control-right-content-wrapper,
.semi-datepicker-compact .semi-datepicker-quick-control-month[x-insetinput=true] .semi-datepicker-quick-control-left-content-wrapper {
  max-height: 254px;
}
.semi-datepicker-compact .semi-datepicker-quick-control-month .semi-datepicker-quick-control-right-content-wrapper,
.semi-datepicker-compact .semi-datepicker-quick-control-month .semi-datepicker-quick-control-left-content-wrapper {
  max-height: 218px;
}
.semi-datepicker-compact .semi-datepicker-quick-control-date {
  max-width: 216px;
}
.semi-datepicker-compact .semi-datepicker-quick-control-date[x-insetinput=true] .semi-datepicker-quick-control-right-content-wrapper,
.semi-datepicker-compact .semi-datepicker-quick-control-date[x-insetinput=true] .semi-datepicker-quick-control-left-content-wrapper {
  max-height: 248px;
}
.semi-datepicker-compact .semi-datepicker-quick-control-date .semi-datepicker-quick-control-right-content-wrapper,
.semi-datepicker-compact .semi-datepicker-quick-control-date .semi-datepicker-quick-control-left-content-wrapper {
  max-height: 208px;
}
.semi-datepicker-compact .semi-datepicker-quick-control-dateTime {
  max-width: 216px;
}
.semi-datepicker-compact .semi-datepicker-quick-control-dateTime[x-insetinput=true] .semi-datepicker-quick-control-right-content-wrapper,
.semi-datepicker-compact .semi-datepicker-quick-control-dateTime[x-insetinput=true] .semi-datepicker-quick-control-left-content-wrapper {
  max-height: 248px;
}
.semi-datepicker-compact .semi-datepicker-quick-control-dateTime .semi-datepicker-quick-control-right-content-wrapper,
.semi-datepicker-compact .semi-datepicker-quick-control-dateTime .semi-datepicker-quick-control-left-content-wrapper {
  max-height: 240px;
}
.semi-datepicker-compact .semi-datepicker-quick-control-dateRange {
  max-width: 432px;
}
.semi-datepicker-compact .semi-datepicker-quick-control-dateRange[x-insetinput=true] .semi-datepicker-quick-control-right-content-wrapper,
.semi-datepicker-compact .semi-datepicker-quick-control-dateRange[x-insetinput=true] .semi-datepicker-quick-control-left-content-wrapper {
  max-height: 248px;
}
.semi-datepicker-compact .semi-datepicker-quick-control-dateRange .semi-datepicker-quick-control-right-content-wrapper,
.semi-datepicker-compact .semi-datepicker-quick-control-dateRange .semi-datepicker-quick-control-left-content-wrapper {
  max-height: 208px;
}
.semi-datepicker-compact .semi-datepicker-quick-control-dateTimeRange {
  max-width: 432px;
}
.semi-datepicker-compact .semi-datepicker-quick-control-dateTimeRange[x-insetinput=true] .semi-datepicker-quick-control-right-content-wrapper,
.semi-datepicker-compact .semi-datepicker-quick-control-dateTimeRange[x-insetinput=true] .semi-datepicker-quick-control-left-content-wrapper {
  max-height: 248px;
}
.semi-datepicker-compact .semi-datepicker-quick-control-dateTimeRange .semi-datepicker-quick-control-right-content-wrapper,
.semi-datepicker-compact .semi-datepicker-quick-control-dateTimeRange .semi-datepicker-quick-control-left-content-wrapper {
  max-height: 240px;
}
.semi-datepicker-compact .semi-datepicker-footer {
  padding-top: 10px;
  padding-right: 8px;
  padding-bottom: 10px;
}
.semi-datepicker-compact .semi-datepicker-inset-input-wrapper {
  column-gap: 4px;
  padding: 8px 8px;
  padding-bottom: 0;
  width: 216px;
}
.semi-datepicker-compact .semi-datepicker-inset-input-wrapper[x-type=dateRange], .semi-datepicker-compact .semi-datepicker-inset-input-wrapper[x-type=dateTimeRange] {
  width: 432px;
  padding-top: 0;
}
.semi-datepicker-compact .semi-datepicker-inset-input-wrapper[x-type=dateRange] .semi-input-wrapper, .semi-datepicker-compact .semi-datepicker-inset-input-wrapper[x-type=dateTimeRange] .semi-input-wrapper {
  margin-top: 8px;
}
.semi-datepicker-compact .semi-datepicker-inset-input-wrapper[x-type=month] {
  width: 195px;
}
.semi-datepicker-compact .semi-datepicker-inset-input-wrapper .semi-input-wrapper {
  height: 28px;
  box-sizing: border-box;
}
.semi-datepicker-compact .semi-datepicker-inset-input-wrapper .semi-input-wrapper .semi-input {
  font-size: 12px;
  line-height: 26px;
  height: 26px;
  vertical-align: top;
}
.semi-datepicker-compact .semi-datepicker-inset-input-separator {
  border-left: 1px solid var(--semi-color-border);
  transform: translateX(50%);
  height: auto;
}

.semi-popover-wrapper[x-placement^=top] .semi-datepicker .semi-datepicker-weeks, .semi-popover-wrapper[x-placement=leftTop] .semi-datepicker .semi-datepicker-weeks, .semi-popover-wrapper[x-placement=rightTop] .semi-datepicker .semi-datepicker-weeks, .semi-popover-wrapper[x-placement*=BottomOver] .semi-datepicker .semi-datepicker-weeks {
  min-height: 216px;
}
.semi-popover-wrapper[x-placement^=top] .semi-datepicker-compact .semi-datepicker-weeks, .semi-popover-wrapper[x-placement=leftTop] .semi-datepicker-compact .semi-datepicker-weeks, .semi-popover-wrapper[x-placement=rightTop] .semi-datepicker-compact .semi-datepicker-weeks, .semi-popover-wrapper[x-placement*=BottomOver] .semi-datepicker-compact .semi-datepicker-weeks {
  min-height: 168px;
}

.semi-datepicker-borderless:not(:focus-within):not(:hover) {
  background-color: transparent;
  border-color: transparent;
}
.semi-datepicker-borderless:not(:focus-within):not(:hover) .semi-input-suffix, .semi-datepicker-borderless:not(:focus-within):not(:hover) .semi-datepicker-range-input-suffix {
  opacity: 0;
}
.semi-datepicker-borderless:focus-within:not(:active) {
  background-color: transparent;
}
.semi-datepicker-borderless.semi-datepicker-range-input-error .semi-datepicker-range-input-wrapper-active {
  border-color: transparent;
}
.semi-datepicker-borderless.semi-datepicker-range-input-error:hover {
  background-color: var(--semi-color-danger-light-default);
}
.semi-datepicker-borderless.semi-datepicker-range-input-error:focus-within {
  background-color: var(--semi-color-danger-light-default);
}
.semi-datepicker-borderless.semi-datepicker-range-input-error:not(:focus-within) {
  border-width: 1px;
  border-color: var(--semi-color-danger);
}
.semi-datepicker-borderless.semi-datepicker-range-input-error:focus-within {
  border-width: 1px;
  border-color: var(--semi-color-danger);
}
.semi-datepicker-borderless.semi-datepicker-range-input-warning .semi-datepicker-range-input-wrapper-active {
  border-color: transparent;
}
.semi-datepicker-borderless.semi-datepicker-range-input-warning:hover {
  background-color: var(--semi-color-warning-light-default);
}
.semi-datepicker-borderless.semi-datepicker-range-input-warning:focus-within {
  background-color: var(--semi-color-warning-light-default);
}
.semi-datepicker-borderless.semi-datepicker-range-input-warning:not(:focus-within) {
  border-width: 1px;
  border-color: var(--semi-color-warning);
}
.semi-datepicker-borderless.semi-datepicker-range-input-warning:focus-within {
  border-width: 1px;
  border-color: var(--semi-color-warning);
}

.semi-rtl,
.semi-portal-rtl {
  /*
  * 小尺寸 DatePicker
  */
}
.semi-rtl .semi-datepicker,
.semi-portal-rtl .semi-datepicker {
  direction: rtl;
}
.semi-rtl .semi-datepicker-footer,
.semi-portal-rtl .semi-datepicker-footer {
  padding-right: 0;
  padding-left: 8px;
  text-align: left;
}
.semi-rtl .semi-datepicker-footer .semi-button:first-of-type,
.semi-portal-rtl .semi-datepicker-footer .semi-button:first-of-type {
  margin-left: 0;
  margin-right: 0;
}
.semi-rtl .semi-datepicker-footer .semi-button:nth-of-type(2),
.semi-portal-rtl .semi-datepicker-footer .semi-button:nth-of-type(2) {
  margin-right: 12px;
  margin-left: 0;
}
.semi-rtl .semi-datepicker-day-offsetrange-start .semi-datepicker-day-main,
.semi-portal-rtl .semi-datepicker-day-offsetrange-start .semi-datepicker-day-main {
  border-radius: 0 var(--semi-border-radius-small) var(--semi-border-radius-small) 0;
}
.semi-rtl .semi-datepicker-day-offsetrange-end .semi-datepicker-day-main,
.semi-portal-rtl .semi-datepicker-day-offsetrange-end .semi-datepicker-day-main {
  border-radius: var(--semi-border-radius-small) 0 0 var(--semi-border-radius-small);
}
.semi-rtl .semi-datepicker-day-selected-start .semi-datepicker-day-main, .semi-rtl .semi-datepicker-day-hoverday-before-selectedrange .semi-datepicker-day-main, .semi-rtl .semi-datepicker-day-offsetrange-start .semi-datepicker-day-main,
.semi-portal-rtl .semi-datepicker-day-selected-start .semi-datepicker-day-main,
.semi-portal-rtl .semi-datepicker-day-hoverday-before-selectedrange .semi-datepicker-day-main,
.semi-portal-rtl .semi-datepicker-day-offsetrange-start .semi-datepicker-day-main {
  margin-right: 2px;
  margin-left: auto;
  border-radius: 0 var(--semi-border-radius-small) var(--semi-border-radius-small) 0;
}
.semi-rtl .semi-datepicker-day-selected-end .semi-datepicker-day-main, .semi-rtl .semi-datepicker-day-hoverday-after-selectedrange .semi-datepicker-day-main, .semi-rtl .semi-datepicker-day-offsetrange-end .semi-datepicker-day-main,
.semi-portal-rtl .semi-datepicker-day-selected-end .semi-datepicker-day-main,
.semi-portal-rtl .semi-datepicker-day-hoverday-after-selectedrange .semi-datepicker-day-main,
.semi-portal-rtl .semi-datepicker-day-offsetrange-end .semi-datepicker-day-main {
  margin-left: 2px;
  margin-right: auto;
  border-radius: var(--semi-border-radius-small) 0 0 var(--semi-border-radius-small);
}
.semi-rtl .semi-datepicker-switch-text,
.semi-portal-rtl .semi-datepicker-switch-text {
  padding-left: 0;
  padding-right: 8px;
}
.semi-rtl .semi-datepicker-navigation .semi-icon-chevron_left,
.semi-rtl .semi-datepicker-navigation .semi-icon-chevron_right,
.semi-rtl .semi-datepicker-navigation .semi-icon-double_chevron_left,
.semi-rtl .semi-datepicker-navigation .semi-icon-double_chevron_right, .semi-rtl .semi-datepicker-yam .semi-icon-chevron_left,
.semi-rtl .semi-datepicker-yam .semi-icon-chevron_right,
.semi-rtl .semi-datepicker-yam .semi-icon-double_chevron_left,
.semi-rtl .semi-datepicker-yam .semi-icon-double_chevron_right,
.semi-portal-rtl .semi-datepicker-navigation .semi-icon-chevron_left,
.semi-portal-rtl .semi-datepicker-navigation .semi-icon-chevron_right,
.semi-portal-rtl .semi-datepicker-navigation .semi-icon-double_chevron_left,
.semi-portal-rtl .semi-datepicker-navigation .semi-icon-double_chevron_right,
.semi-portal-rtl .semi-datepicker-yam .semi-icon-chevron_left,
.semi-portal-rtl .semi-datepicker-yam .semi-icon-chevron_right,
.semi-portal-rtl .semi-datepicker-yam .semi-icon-double_chevron_left,
.semi-portal-rtl .semi-datepicker-yam .semi-icon-double_chevron_right {
  transform: scaleX(-1);
}
.semi-rtl .semi-datepicker-range-input-prefix,
.semi-portal-rtl .semi-datepicker-range-input-prefix {
  padding-left: 8px;
  padding-right: 12px;
}
.semi-rtl .semi-datepicker-range-input-suffix,
.semi-portal-rtl .semi-datepicker-range-input-suffix {
  padding-left: 12px;
  padding-right: 8px;
}
.semi-rtl .semi-datepicker-range-input-suffix-hidden,
.semi-portal-rtl .semi-datepicker-range-input-suffix-hidden {
  display: none;
}
.semi-rtl .semi-datepicker-range-input-clearbtn,
.semi-portal-rtl .semi-datepicker-range-input-clearbtn {
  padding-left: 12px;
  padding-right: 8px;
}
.semi-rtl .semi-datepicker-compact .semi-datepicker-month-grid[x-type=dateRange] .semi-datepicker-month-grid-left, .semi-rtl .semi-datepicker-compact .semi-datepicker-month-grid[x-type=dateTimeRange] .semi-datepicker-month-grid-left,
.semi-portal-rtl .semi-datepicker-compact .semi-datepicker-month-grid[x-type=dateRange] .semi-datepicker-month-grid-left,
.semi-portal-rtl .semi-datepicker-compact .semi-datepicker-month-grid[x-type=dateTimeRange] .semi-datepicker-month-grid-left {
  border-left: 1px solid var(--semi-color-border);
  border-right: 0;
}
.semi-rtl .semi-datepicker-compact .semi-datepicker-month-grid .semi-scrolllist-item-wheel,
.semi-portal-rtl .semi-datepicker-compact .semi-datepicker-month-grid .semi-scrolllist-item-wheel {
  border-left: 0;
  border-right: 0;
}
.semi-rtl .semi-datepicker-compact .semi-datepicker-day-offsetrange-start .semi-datepicker-day-main,
.semi-portal-rtl .semi-datepicker-compact .semi-datepicker-day-offsetrange-start .semi-datepicker-day-main {
  border-radius: 0 4px 4px 0;
}
.semi-rtl .semi-datepicker-compact .semi-datepicker-day-offsetrange-end .semi-datepicker-day-main,
.semi-portal-rtl .semi-datepicker-compact .semi-datepicker-day-offsetrange-end .semi-datepicker-day-main {
  border-radius: 4px 0 0 4px;
}
.semi-rtl .semi-datepicker-compact .semi-datepicker-day-selected-start .semi-datepicker-day-main,
.semi-portal-rtl .semi-datepicker-compact .semi-datepicker-day-selected-start .semi-datepicker-day-main {
  margin-left: 0;
  margin-right: 2px;
  border-radius: 0 4px 4px 0;
}
.semi-rtl .semi-datepicker-compact .semi-datepicker-day-selected-end .semi-datepicker-day-main,
.semi-portal-rtl .semi-datepicker-compact .semi-datepicker-day-selected-end .semi-datepicker-day-main {
  margin-right: 0;
  margin-left: 2px;
  border-radius: 4px 0 0 4px;
}
.semi-rtl .semi-datepicker-compact .semi-datepicker-switch-date,
.semi-portal-rtl .semi-datepicker-compact .semi-datepicker-switch-date {
  border-left: 1px solid var(--semi-color-border);
  border-right: 0;
}
.semi-rtl .semi-datepicker-compact .semi-datepicker-switch-text,
.semi-portal-rtl .semi-datepicker-compact .semi-datepicker-switch-text {
  padding-left: auto;
  padding-right: 0;
}
.semi-rtl .semi-datepicker-compact .semi-datepicker-footer,
.semi-portal-rtl .semi-datepicker-compact .semi-datepicker-footer {
  padding-left: 8px;
  padding-right: auto;
}
.semi-rtl .semi-datepicker-compact .semi-datepicker-inset-input-separator,
.semi-portal-rtl .semi-datepicker-compact .semi-datepicker-inset-input-separator {
  border-right: 1px solid var(--semi-color-border);
  border-left: 0;
  transform: translateX(-50%);
}