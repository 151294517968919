/* shadow */
/* sizing */
/* spacing */
.semi-timeline {
  margin: 0;
  padding: 8px;
  width: 100%;
  list-style: none;
}
.semi-timeline-item {
  position: relative;
  margin: 0;
  padding: 0 0 24px 0;
  list-style: none;
}
.semi-timeline-item-tail {
  position: absolute;
  top: 20px;
  left: 4px;
  height: calc(100% - 20px);
  border-left: 1px solid var(--semi-color-text-3);
}
.semi-timeline-item-head {
  position: absolute;
  top: 5px;
  width: 9px;
  height: 9px;
  border-radius: var(--semi-border-radius-circle);
}
.semi-timeline-item-head-ongoing {
  background-color: var(--semi-color-primary);
}
.semi-timeline-item-head-default {
  background-color: var(--semi-color-tertiary-light-active);
}
.semi-timeline-item-head-success {
  background-color: var(--semi-color-success);
}
.semi-timeline-item-head-warning {
  background-color: var(--semi-color-warning);
}
.semi-timeline-item-head-error {
  background-color: var(--semi-color-danger);
}
.semi-timeline-item-head-custom {
  position: absolute;
  display: flex;
  align-self: center;
  top: 10px;
  left: 5px;
  width: auto;
  height: auto;
  border: 0;
  border-radius: 0;
  transform: translate(-50%, -50%);
}
.semi-timeline-item .semi-timeline-item-head-custom.semi-timeline-item-head-ongoing {
  background-color: transparent;
  color: var(--semi-color-primary);
}
.semi-timeline-item .semi-timeline-item-head-custom.semi-timeline-item-head-success {
  background-color: transparent;
  color: var(--semi-color-success);
}
.semi-timeline-item .semi-timeline-item-head-custom.semi-timeline-item-head-warning {
  background-color: transparent;
  color: var(--semi-color-warning);
}
.semi-timeline-item .semi-timeline-item-head-custom.semi-timeline-item-head-error {
  background-color: transparent;
  color: var(--semi-color-danger);
}
.semi-timeline-item .semi-timeline-item-head-custom.semi-timeline-item-head-default {
  background-color: transparent;
  color: var(--semi-color-tertiary-light-active);
}
.semi-timeline-item-content {
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  position: relative;
  margin: 0 0 0 25px;
  word-break: break-word;
  color: var(--semi-color-text-0);
}
.semi-timeline-item-content-extra, .semi-timeline-item-content-time {
  font-size: 12px;
  line-height: 16px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: var(--semi-color-text-2);
  margin-top: 4px;
}
.semi-timeline-item:last-child > .semi-timeline-item-tail {
  border-left: none;
}
.semi-timeline-alternate .semi-timeline-item-tail, .semi-timeline-alternate .semi-timeline-item-head, .semi-timeline-alternate .semi-timeline-item-head-custom, .semi-timeline-right .semi-timeline-item-tail, .semi-timeline-right .semi-timeline-item-head, .semi-timeline-right .semi-timeline-item-head-custom, .semi-timeline-center .semi-timeline-item-tail, .semi-timeline-center .semi-timeline-item-head, .semi-timeline-center .semi-timeline-item-head-custom {
  left: 50%;
}
.semi-timeline-alternate .semi-timeline-item-head.semi-timeline-item-head-custom, .semi-timeline-right .semi-timeline-item-head.semi-timeline-item-head-custom, .semi-timeline-center .semi-timeline-item-head.semi-timeline-item-head-custom {
  margin-left: 0;
}
.semi-timeline-alternate .semi-timeline-item-head, .semi-timeline-right .semi-timeline-item-head, .semi-timeline-center .semi-timeline-item-head {
  margin-left: -4px;
}
.semi-timeline-alternate .semi-timeline-item-left .semi-timeline-item-content, .semi-timeline-right .semi-timeline-item-left .semi-timeline-item-content, .semi-timeline-center .semi-timeline-item-left .semi-timeline-item-content {
  left: calc(50% - 4px);
  width: calc(50% - 14px);
  text-align: left;
}
.semi-timeline-alternate .semi-timeline-item-right .semi-timeline-item-content, .semi-timeline-right .semi-timeline-item-right .semi-timeline-item-content, .semi-timeline-center .semi-timeline-item-right .semi-timeline-item-content {
  width: calc(50% - 20px);
  margin: 0;
  text-align: right;
}
.semi-timeline-center .semi-timeline-item-content-time {
  position: absolute;
  top: -2px;
  margin-left: calc(-40px - 100%);
  width: 100%;
  text-align: right;
}
.semi-timeline-right .semi-timeline-item-right .semi-timeline-item-tail, .semi-timeline-right .semi-timeline-item-right .semi-timeline-item-head, .semi-timeline-right .semi-timeline-item-right .semi-timeline-item-head-custom {
  left: calc(100% - 9px);
}
.semi-timeline-right .semi-timeline-item-right .semi-timeline-item-content {
  width: calc(100% - 28px);
}

.semi-rtl .semi-timeline,
.semi-portal-rtl .semi-timeline {
  direction: rtl;
}
.semi-rtl .semi-timeline-item-tail,
.semi-portal-rtl .semi-timeline-item-tail {
  left: auto;
  right: 4px;
  border-left: 0;
  border-right: 1px solid var(--semi-color-text-3);
}
.semi-rtl .semi-timeline-item-head-custom,
.semi-portal-rtl .semi-timeline-item-head-custom {
  left: auto;
  right: 5px;
  transform: translate(50%, -50%);
}
.semi-rtl .semi-timeline-item-content,
.semi-portal-rtl .semi-timeline-item-content {
  margin: 0 25px 0 0;
}
.semi-rtl .semi-timeline-item:last-child .semi-timeline-item-tail,
.semi-portal-rtl .semi-timeline-item:last-child .semi-timeline-item-tail {
  border-right: none;
}
.semi-rtl .semi-timeline-alternate .semi-timeline-item-tail, .semi-rtl .semi-timeline-alternate .semi-timeline-item-head, .semi-rtl .semi-timeline-alternate .semi-timeline-item-head-custom, .semi-rtl .semi-timeline-right .semi-timeline-item-tail, .semi-rtl .semi-timeline-right .semi-timeline-item-head, .semi-rtl .semi-timeline-right .semi-timeline-item-head-custom, .semi-rtl .semi-timeline-center .semi-timeline-item-tail, .semi-rtl .semi-timeline-center .semi-timeline-item-head, .semi-rtl .semi-timeline-center .semi-timeline-item-head-custom,
.semi-portal-rtl .semi-timeline-alternate .semi-timeline-item-tail,
.semi-portal-rtl .semi-timeline-alternate .semi-timeline-item-head,
.semi-portal-rtl .semi-timeline-alternate .semi-timeline-item-head-custom,
.semi-portal-rtl .semi-timeline-right .semi-timeline-item-tail,
.semi-portal-rtl .semi-timeline-right .semi-timeline-item-head,
.semi-portal-rtl .semi-timeline-right .semi-timeline-item-head-custom,
.semi-portal-rtl .semi-timeline-center .semi-timeline-item-tail,
.semi-portal-rtl .semi-timeline-center .semi-timeline-item-head,
.semi-portal-rtl .semi-timeline-center .semi-timeline-item-head-custom {
  left: auto;
  right: 50%;
}
.semi-rtl .semi-timeline-alternate .semi-timeline-item-head, .semi-rtl .semi-timeline-right .semi-timeline-item-head, .semi-rtl .semi-timeline-center .semi-timeline-item-head,
.semi-portal-rtl .semi-timeline-alternate .semi-timeline-item-head,
.semi-portal-rtl .semi-timeline-right .semi-timeline-item-head,
.semi-portal-rtl .semi-timeline-center .semi-timeline-item-head {
  margin-left: 0;
  margin-right: -4px;
}
.semi-rtl .semi-timeline-alternate .semi-timeline-item-left .semi-timeline-item-content, .semi-rtl .semi-timeline-right .semi-timeline-item-left .semi-timeline-item-content, .semi-rtl .semi-timeline-center .semi-timeline-item-left .semi-timeline-item-content,
.semi-portal-rtl .semi-timeline-alternate .semi-timeline-item-left .semi-timeline-item-content,
.semi-portal-rtl .semi-timeline-right .semi-timeline-item-left .semi-timeline-item-content,
.semi-portal-rtl .semi-timeline-center .semi-timeline-item-left .semi-timeline-item-content {
  left: auto;
  right: calc(50% - 4px);
  text-align: right;
}
.semi-rtl .semi-timeline-alternate .semi-timeline-item-right .semi-timeline-item-content, .semi-rtl .semi-timeline-right .semi-timeline-item-right .semi-timeline-item-content, .semi-rtl .semi-timeline-center .semi-timeline-item-right .semi-timeline-item-content,
.semi-portal-rtl .semi-timeline-alternate .semi-timeline-item-right .semi-timeline-item-content,
.semi-portal-rtl .semi-timeline-right .semi-timeline-item-right .semi-timeline-item-content,
.semi-portal-rtl .semi-timeline-center .semi-timeline-item-right .semi-timeline-item-content {
  text-align: left;
}
.semi-rtl .semi-timeline-center .semi-timeline-item-content-time,
.semi-portal-rtl .semi-timeline-center .semi-timeline-item-content-time {
  margin-left: 0;
  margin-right: calc(-40px - 100%);
  text-align: left;
}
.semi-rtl .semi-timeline-right .semi-timeline-item-right .semi-timeline-item-tail, .semi-rtl .semi-timeline-right .semi-timeline-item-right .semi-timeline-item-head, .semi-rtl .semi-timeline-right .semi-timeline-item-right .semi-timeline-item-head-custom,
.semi-portal-rtl .semi-timeline-right .semi-timeline-item-right .semi-timeline-item-tail,
.semi-portal-rtl .semi-timeline-right .semi-timeline-item-right .semi-timeline-item-head,
.semi-portal-rtl .semi-timeline-right .semi-timeline-item-right .semi-timeline-item-head-custom {
  left: 0;
  right: calc(100% - 9px);
}