/* shadow */
/* sizing */
/* spacing */
.semi-input-textarea-wrapper {
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  width: 100%;
  border: 1px transparent solid;
  border-radius: var(--semi-border-radius-small);
  vertical-align: bottom;
  background-color: var(--semi-color-fill-0);
  transition: background-color var(--semi-transition_duration-none) var(--semi-transition_function-easeIn) var(--semi-transition_delay-none), border var(--semi-transition_duration-none) var(--semi-transition_function-easeIn) var(--semi-transition_delay-none);
}
.semi-input-textarea-wrapper:hover {
  background-color: var(--semi-color-fill-1);
}
.semi-input-textarea-wrapper-focus {
  background-color: var(--semi-color-fill-0);
  border: 1px var(--semi-color-focus-border) solid;
}
.semi-input-textarea-wrapper-focus:hover, .semi-input-textarea-wrapper-focus:active {
  background-color: var(--semi-color-fill-0);
}
.semi-input-textarea-wrapper:active {
  background-color: var(--semi-color-fill-2);
}
.semi-input-textarea-wrapper .semi-input-clearbtn {
  position: absolute;
  top: 0;
  min-width: 24px;
  color: var(--semi-color-text-2);
  right: 4px;
  height: 32px;
}
.semi-input-textarea-wrapper .semi-input-clearbtn > svg {
  pointer-events: none;
}
.semi-input-textarea-wrapper .semi-input-clearbtn:hover {
  cursor: pointer;
}
.semi-input-textarea-wrapper .semi-input-clearbtn:hover .semi-icon {
  color: var(--semi-color-primary-hover);
}
.semi-input-textarea-wrapper .semi-input-clearbtn-hidden {
  visibility: hidden;
}
.semi-input-textarea-wrapper-disabled, .semi-input-textarea-wrapper-readonly {
  cursor: not-allowed;
  color: var(--semi-color-disabled-text);
  background-color: var(--semi-color-disabled-fill);
}
.semi-input-textarea-wrapper-disabled:hover, .semi-input-textarea-wrapper-readonly:hover {
  background-color: var(--semi-color-disabled-fill);
}
.semi-input-textarea-wrapper-disabled::placeholder, .semi-input-textarea-wrapper-readonly::placeholder {
  color: var(--semi-color-disabled-text);
}
.semi-input-textarea-wrapper-readonly {
  cursor: text;
}
.semi-input-textarea-wrapper-error {
  background-color: var(--semi-color-danger-light-default);
  border-color: var(--semi-color-danger-light-default);
}
.semi-input-textarea-wrapper-error:hover {
  background-color: var(--semi-color-danger-light-hover);
  border-color: var(--semi-color-danger-light-hover);
}
.semi-input-textarea-wrapper-error.semi-input-textarea-wrapper-focus {
  background-color: var(--semi-color-danger-light-default);
  border-color: var(--semi-color-danger);
}
.semi-input-textarea-wrapper-error:active {
  background-color: var(--semi-color-danger-light-active);
  border-color: var(--semi-color-danger);
}
.semi-input-textarea-wrapper-warning {
  background-color: var(--semi-color-warning-light-default);
  border-color: var(--semi-color-warning-light-default);
}
.semi-input-textarea-wrapper-warning:hover {
  background-color: var(--semi-color-warning-light-hover);
  border-color: var(--semi-color-warning-light-hover);
}
.semi-input-textarea-wrapper-warning.semi-input-textarea-wrapper-focus {
  background-color: var(--semi-color-warning-light-default);
  border-color: var(--semi-color-warning);
}
.semi-input-textarea-wrapper-warning:active {
  background-color: var(--semi-color-warning-light-active);
  border-color: var(--semi-color-warning);
}

.semi-input-textarea {
  position: relative;
  resize: none;
  padding: 5px 12px;
  box-shadow: none;
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: transparent;
  border: 0 solid transparent;
  vertical-align: bottom;
  width: 100%;
  outline: none;
  cursor: text;
  box-sizing: border-box;
  color: var(--semi-color-text-0);
}
.semi-input-textarea:hover {
  border-color: transparent;
}
.semi-input-textarea::placeholder {
  color: var(--semi-color-text-2);
}
.semi-input-textarea-showClear {
  padding-right: 36px;
}
.semi-input-textarea-disabled, .semi-input-textarea-readonly {
  cursor: not-allowed;
  color: var(--semi-color-disabled-text);
  background-color: transparent;
}
.semi-input-textarea-disabled:hover, .semi-input-textarea-readonly:hover {
  background-color: transparent;
}
.semi-input-textarea-disabled::placeholder, .semi-input-textarea-readonly::placeholder {
  color: var(--semi-color-disabled-text);
}
.semi-input-textarea-readonly {
  cursor: text;
}
.semi-input-textarea-autosize {
  overflow: hidden;
}
.semi-input-textarea-counter {
  font-size: 12px;
  line-height: 16px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3px 12px 5px 12px;
  min-height: 24px;
  text-align: right;
  color: var(--semi-color-text-2);
}
.semi-input-textarea-counter-exceed {
  color: var(--semi-color-danger);
}

.semi-input-textarea-borderless:not(:focus-within):not(:hover) {
  background-color: transparent;
  border-color: transparent;
}
.semi-input-textarea-borderless:focus-within:not(:active) {
  background-color: transparent;
}
.semi-input-textarea-borderless.semi-input-textarea-wrapper-error:not(:focus-within) {
  border-color: var(--semi-color-danger);
}
.semi-input-textarea-borderless.semi-input-textarea-wrapper-warning:not(:focus-within) {
  border-color: var(--semi-color-warning);
}
.semi-input-textarea-borderless.semi-input-textarea-wrapper-error .semi-input-textarea-counter {
  color: var(--semi-color-danger);
}
.semi-input-textarea-borderless.semi-input-textarea-wrapper-warning .semi-input-textarea-counter {
  color: var(--semi-color-warning);
}

.semi-rtl .semi-input-wrapper,
.semi-portal-rtl .semi-input-wrapper {
  direction: rtl;
}
.semi-rtl .semi-input-wrapper__with-prefix .semi-input,
.semi-portal-rtl .semi-input-wrapper__with-prefix .semi-input {
  padding-left: auto;
  padding-right: 0;
}
.semi-rtl .semi-input-wrapper__with-suffix .semi-input,
.semi-portal-rtl .semi-input-wrapper__with-suffix .semi-input {
  padding-right: auto;
  padding-left: 0;
}
.semi-rtl .semi-input,
.semi-portal-rtl .semi-input {
  padding-left: 12px;
  padding-right: 12px;
}
.semi-rtl .semi-input-inset-label,
.semi-portal-rtl .semi-input-inset-label {
  margin-right: auto;
  margin-left: 12px;
}
.semi-rtl .semi-input-clearbtn + .semi-rtl .semi-input-suffix + .semi-input-suffix-text,
.semi-rtl .semi-input-clearbtn + .semi-portal-rtl .semi-input-suffix + .semi-input-suffix-text,
.semi-portal-rtl .semi-input-clearbtn + .semi-rtl .semi-input-suffix + .semi-input-suffix-text,
.semi-portal-rtl .semi-input-clearbtn + .semi-portal-rtl .semi-input-suffix + .semi-input-suffix-text {
  margin-left: auto;
  margin-right: 0;
}
.semi-rtl .semi-input-clearbtn + .semi-rtl .semi-input-suffix + .semi-input-suffix-icon,
.semi-rtl .semi-input-clearbtn + .semi-portal-rtl .semi-input-suffix + .semi-input-suffix-icon,
.semi-portal-rtl .semi-input-clearbtn + .semi-rtl .semi-input-suffix + .semi-input-suffix-icon,
.semi-portal-rtl .semi-input-clearbtn + .semi-portal-rtl .semi-input-suffix + .semi-input-suffix-icon {
  margin-left: auto;
  margin-right: 0;
}
.semi-rtl .semi-input-append,
.semi-portal-rtl .semi-input-append {
  border-left: 0;
  border-right: 1px transparent solid;
}
.semi-rtl .semi-input-prepend,
.semi-portal-rtl .semi-input-prepend {
  border-right: 0;
  border-left: 1px transparent solid;
}
.semi-rtl .semi-input-group .semi-select:not(:last-child)::after,
.semi-rtl .semi-input-group .semi-cascader:not(:last-child)::after,
.semi-rtl .semi-input-group .semi-tree-select:not(:last-child)::after, .semi-rtl .semi-input-group > .semi-input-wrapper:not(:last-child)::after,
.semi-portal-rtl .semi-input-group .semi-select:not(:last-child)::after,
.semi-portal-rtl .semi-input-group .semi-cascader:not(:last-child)::after,
.semi-portal-rtl .semi-input-group .semi-tree-select:not(:last-child)::after,
.semi-portal-rtl .semi-input-group > .semi-input-wrapper:not(:last-child)::after {
  right: auto;
  left: -1px;
}
.semi-rtl .semi-input-group .semi-input-number:not(:last-child)::after,
.semi-portal-rtl .semi-input-group .semi-input-number:not(:last-child)::after {
  right: auto;
  left: -1px;
}
.semi-rtl .semi-input-textarea-wrapper,
.semi-portal-rtl .semi-input-textarea-wrapper {
  direction: rtl;
}
.semi-rtl .semi-input-textarea-counter,
.semi-portal-rtl .semi-input-textarea-counter {
  text-align: left;
}
.semi-rtl .semi-input-textarea-showClear,
.semi-portal-rtl .semi-input-textarea-showClear {
  padding-right: 0;
  padding-left: 36px;
}