/* shadow */
/* sizing */
/* spacing */
.semi-progress {
  display: flex;
  align-items: center;
}
.semi-progress-track {
  background-color: var(--semi-color-fill-0);
  border-radius: var(--semi-border-radius-small);
}
.semi-progress-horizontal {
  height: 4px;
  margin-top: 4px;
  margin-bottom: 4px;
}
.semi-progress-horizontal.semi-progress-large {
  height: 6px;
}
.semi-progress-horizontal .semi-progress-track {
  height: 100%;
  width: 100%;
}
.semi-progress-horizontal .semi-progress-track-inner {
  height: 100%;
  background-color: var(--semi-color-success);
  border-radius: var(--semi-border-radius-small);
  transition: width 0.3s;
  transition-timing-function: cubic-bezier(0.62, 0.05, 0.36, 0.95);
}
.semi-progress-horizontal .semi-progress-line-text {
  min-width: 45px;
  font-weight: 600;
  margin-left: 16px;
  color: var(--semi-color-text-0);
}
.semi-progress-vertical {
  width: 4px;
  display: inline-flex;
  height: 100%;
  margin-left: 4px;
  margin-right: 4px;
  flex-direction: column;
}
.semi-progress-vertical.semi-progress-large {
  width: 6px;
}
.semi-progress-vertical .semi-progress-track {
  height: 100%;
  width: 100%;
}
.semi-progress-vertical .semi-progress-track-inner {
  background-color: var(--semi-color-success);
  border-radius: var(--semi-border-radius-small);
  width: 100%;
  transition: height 0.3s;
  transition-timing-function: cubic-bezier(0.62, 0.05, 0.36, 0.95);
}
.semi-progress-vertical .semi-progress-line-text {
  font-weight: 600;
  margin-top: 8px;
}
.semi-progress-circle {
  position: relative;
  display: inline-block;
}
.semi-progress-circle-ring {
  display: block;
}
.semi-progress-circle-ring-inner {
  transition: stroke-dashoffset 0.3s;
  transition-timing-function: cubic-bezier(0.62, 0.05, 0.36, 0.95);
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
.semi-progress-circle-text {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  text-align: center;
  transform: translate(-50%, -50%);
  user-select: none;
  color: var(--semi-color-mode-minor-text);
}

.semi-rtl .semi-progress,
.semi-portal-rtl .semi-progress {
  direction: rtl;
}
.semi-rtl .semi-progress-horizontal .semi-progress-line-text,
.semi-portal-rtl .semi-progress-horizontal .semi-progress-line-text {
  margin-left: 0;
  margin-right: 16px;
}
.semi-rtl .semi-progress-circle-ring-inner,
.semi-portal-rtl .semi-progress-circle-ring-inner {
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
.semi-rtl .semi-progress-circle-text,
.semi-portal-rtl .semi-progress-circle-text {
  left: auto;
  right: 50%;
  transform: translate(50%, -50%);
}