/* shadow */
/* sizing */
/* spacing */
.semi-card {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border-radius: var(--semi-border-radius-medium);
  overflow: hidden;
  background-color: var(--semi-color-bg-0);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
}
.semi-card-shadows {
  cursor: pointer;
  transition: box-shadow 300ms;
}
.semi-card-shadows-hover:hover {
  box-shadow: var(--semi-shadow-elevated);
  z-index: 1;
}
.semi-card-shadows-always {
  box-shadow: var(--semi-shadow-elevated);
}
.semi-card-bordered {
  border: 1px solid var(--semi-color-border);
}
.semi-card-header {
  padding: 20px;
}
.semi-card-header-bordered {
  border-bottom: 1px solid var(--semi-color-border);
}
.semi-card-header-wrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.semi-card-header-wrapper-spacing {
  margin-right: 20px;
}
.semi-card-header-wrapper-title {
  width: 100%;
  overflow: hidden;
}
.semi-card-header-wrapper-extra {
  flex-shrink: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
  color: var(--semi-color-text-0);
}
.semi-card-cover > * {
  display: block;
  width: 100%;
}
.semi-card-body {
  padding: 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--semi-color-text-1);
}
.semi-card-body-actions {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid var(--semi-color-border);
}
.semi-card-footer {
  padding: 20px;
}
.semi-card-footer-bordered {
  border-top: 1px solid var(--semi-color-border);
}
.semi-card-meta {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.semi-card-meta-avatar {
  margin-right: 12px;
}
.semi-card-meta-wrapper-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  color: var(--semi-color-text-0);
}
.semi-card-meta-wrapper-description {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--semi-color-text-2);
}

.semi-card-group-grid .semi-card {
  border-radius: 0;
}
.semi-card-group-grid .semi-card {
  margin-left: -1px;
  margin-top: -1px;
}

.semi-rtl .semi-card,
.semi-portal-rtl .semi-card {
  direction: rtl;
}
.semi-rtl .semi-card-header-wrapper,
.semi-portal-rtl .semi-card-header-wrapper {
  align-items: flex-end;
}
.semi-rtl .semi-card-header-wrapper-title,
.semi-portal-rtl .semi-card-header-wrapper-title {
  display: flex;
  align-items: flex-end;
}
.semi-rtl .semi-card-header-wrapper-spacing,
.semi-portal-rtl .semi-card-header-wrapper-spacing {
  margin-left: 20px;
  margin-right: 0;
}
.semi-rtl .semi-card-meta-avatar,
.semi-portal-rtl .semi-card-meta-avatar {
  margin-left: 12px;
  margin-right: 0;
}
.semi-rtl .semi-card-group,
.semi-portal-rtl .semi-card-group {
  direction: rtl;
}
.semi-rtl .semi-card-group-grid .semi-card,
.semi-portal-rtl .semi-card-group-grid .semi-card {
  margin-left: 0;
  margin-top: 0;
  margin-right: -1px;
  margin-bottom: -1px;
}