/* shadow */
/* sizing */
/* spacing */
.semi-timepicker {
  display: inline-block;
}
.semi-timepicker-panel .semi-scrolllist-body {
  height: 252px;
}
.semi-timepicker-panel .semi-scrolllist-body .semi-scrolllist-item-wheel .semi-scrolllist-list-outer-nocycle > ul:before,
.semi-timepicker-panel .semi-scrolllist-body .semi-scrolllist-item > ul:before {
  height: 108px;
}
.semi-timepicker-panel .semi-scrolllist-body .semi-scrolllist-item,
.semi-timepicker-panel .semi-scrolllist-body .semi-scrolllist-item-wheel .semi-scrolllist-list-outer {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.semi-timepicker-panel .semi-scrolllist-body .semi-scrolllist-item::-webkit-scrollbar,
.semi-timepicker-panel .semi-scrolllist-body .semi-scrolllist-item-wheel .semi-scrolllist-list-outer::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
}
.semi-timepicker-panel .semi-scrolllist-body .semi-scrolllist-item > ul,
.semi-timepicker-panel .semi-scrolllist-body .semi-scrolllist-item-wheel .semi-scrolllist-list-outer > ul {
  padding-bottom: 108px;
}
.semi-timepicker-panel .semi-scrolllist-body .semi-scrolllist-item {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.semi-timepicker-panel .semi-scrolllist-body .semi-scrolllist-item::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
}
.semi-timepicker-panel .semi-scrolllist-item,
.semi-timepicker-panel .semi-scrolllist-item-wheel {
  flex: none;
}
.semi-timepicker-panel .semi-scrolllist-item-wheel,
.semi-timepicker-panel .semi-scrolllist-item-wheel:not(:last-child) {
  border: none;
}
.semi-timepicker-panel-list-ampm {
  width: 72px;
}
.semi-timepicker-panel-list-hour {
  width: 64px;
}
.semi-timepicker-panel-list-minute {
  width: 64px;
}
.semi-timepicker-panel-list-second {
  width: 64px;
}
.semi-timepicker-range-panel .semi-timepicker-lists {
  display: flex;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: var(--semi-border-radius-medium);
}
.semi-timepicker-range-panel .semi-timepicker-lists .semi-scrolllist:first-of-type {
  border-radius: var(--semi-border-radius-medium) 0 0 var(--semi-border-radius-medium);
}
.semi-timepicker-range-panel .semi-timepicker-lists .semi-scrolllist:last-of-type {
  border-radius: 0 var(--semi-border-radius-medium) var(--semi-border-radius-medium) 0;
}
.semi-timepicker-range-panel .semi-timepicker-lists > .semi-scrolllist:not(:last-child) .semi-scrolllist-body {
  border-right: 2px solid var(--semi-color-border);
}
.semi-timepicker-range-panel .semi-timepicker-lists > .semi-scrolllist {
  box-shadow: none;
}
.semi-timepicker-range-panel .semi-timepicker-lists > .semi-scrolllist .semi-scrolllist-body, .semi-timepicker-range-panel .semi-timepicker-lists > .semi-scrolllist .semi-scrolllist-header {
  padding: 0;
}
.semi-timepicker .semi-timepicker-input {
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  border-radius: var(--semi-border-radius-small);
  align-items: center;
}
.semi-timepicker .semi-input-readonly {
  cursor: pointer;
}

.semi-timepicker .semi-input-borderless:not(:focus-within):not(:hover) .semi-input-suffix {
  opacity: 0;
}

.semi-rtl .semi-timepicker,
.semi-portal-rtl .semi-timepicker {
  direction: rtl;
}
.semi-rtl .semi-timepicker-panel,
.semi-portal-rtl .semi-timepicker-panel {
  direction: rtl;
}
.semi-rtl .semi-timepicker-range,
.semi-portal-rtl .semi-timepicker-range {
  direction: rtl;
}
.semi-rtl .semi-timepicker-range-panel .semi-timepicker-lists > .semi-scrolllist:not(:last-child) .semi-scrolllist-body,
.semi-portal-rtl .semi-timepicker-range-panel .semi-timepicker-lists > .semi-scrolllist:not(:last-child) .semi-scrolllist-body {
  border-right: 0;
  border-left: 2px solid var(--semi-color-border);
}