/* shadow */
/* sizing */
/* spacing */
.semi-scrolllist {
  width: 100%;
  height: 100%;
  background: var(--semi-color-bg-3);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3), 0 4px 14px rgba(0, 0, 0, 0.1);
  border-radius: var(--semi-border-radius-medium);
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  user-select: none;
  display: flex;
  flex-direction: column;
}
.semi-scrolllist-header {
  text-align: center;
  padding: 0 16px;
}
.semi-scrolllist-header-title {
  padding: 16px 0;
  font-weight: 600;
  color: var(--semi-color-text-0);
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.semi-scrolllist-body {
  display: flex;
  height: 300px;
  padding: 0 16px;
  overflow: hidden;
}
.semi-scrolllist-body .semi-scrolllist-item-wheel .semi-scrolllist-list-outer-nocycle > ul:before,
.semi-scrolllist-body .semi-scrolllist-item > ul:before {
  content: "";
  display: block;
  width: 100%;
  height: 132px;
}
.semi-scrolllist-body .semi-scrolllist-item {
  position: relative;
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
}
.semi-scrolllist-body .semi-scrolllist-item:not(:last-child) {
  border-right: 1px solid var(--semi-color-border);
}
.semi-scrolllist-body .semi-scrolllist-item > ul,
.semi-scrolllist-body .semi-scrolllist-item-wheel .semi-scrolllist-list-outer > ul {
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 0;
  padding-bottom: 132px;
  list-style: none;
}
.semi-scrolllist-body .semi-scrolllist-item > ul > li,
.semi-scrolllist-body .semi-scrolllist-item-wheel .semi-scrolllist-list-outer > ul > li {
  list-style: none;
  height: 36px;
  box-sizing: border-box;
  color: var(--semi-color-text-0);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: transparent;
  transition: background-color var(--semi-transition_duration-none) var(--semi-transition_function-easeOut) 0ms;
}
.semi-scrolllist-body .semi-scrolllist-item > ul > li.semi-scrolllist-item-sel,
.semi-scrolllist-body .semi-scrolllist-item-wheel .semi-scrolllist-list-outer > ul > li.semi-scrolllist-item-sel {
  background: var(--semi-color-primary-light-default);
  color: var(--semi-color-text-0);
}
.semi-scrolllist-body .semi-scrolllist-item > ul > li.semi-scrolllist-item-sel > svg,
.semi-scrolllist-body .semi-scrolllist-item-wheel .semi-scrolllist-list-outer > ul > li.semi-scrolllist-item-sel > svg {
  color: var(--semi-color-text-2);
  width: 16px;
  height: 16px;
  margin-right: 12px;
}
.semi-scrolllist-body .semi-scrolllist-item > ul > li:not(.disabled):not(.semi-scrolllist-item-disabled):not(.semi-scrolllist-item-sel):not(.semi-scrolllist-item-selected),
.semi-scrolllist-body .semi-scrolllist-item-wheel .semi-scrolllist-list-outer > ul > li:not(.disabled):not(.semi-scrolllist-item-disabled):not(.semi-scrolllist-item-sel):not(.semi-scrolllist-item-selected) {
  cursor: pointer;
}
.semi-scrolllist-body .semi-scrolllist-item > ul > li:not(.disabled):not(.semi-scrolllist-item-disabled):not(.semi-scrolllist-item-sel):not(.semi-scrolllist-item-selected):hover,
.semi-scrolllist-body .semi-scrolllist-item-wheel .semi-scrolllist-list-outer > ul > li:not(.disabled):not(.semi-scrolllist-item-disabled):not(.semi-scrolllist-item-sel):not(.semi-scrolllist-item-selected):hover {
  background-color: var(--semi-color-fill-1);
}
.semi-scrolllist-body .semi-scrolllist-item > ul > li:not(.disabled):not(.semi-scrolllist-item-disabled):not(.semi-scrolllist-item-sel):not(.semi-scrolllist-item-selected):active,
.semi-scrolllist-body .semi-scrolllist-item-wheel .semi-scrolllist-list-outer > ul > li:not(.disabled):not(.semi-scrolllist-item-disabled):not(.semi-scrolllist-item-sel):not(.semi-scrolllist-item-selected):active {
  background-color: var(--semi-color-fill-2);
}
.semi-scrolllist-body .semi-scrolllist-item > ul > li.semi-scrolllist-item-disabled,
.semi-scrolllist-body .semi-scrolllist-item-wheel .semi-scrolllist-list-outer > ul > li.semi-scrolllist-item-disabled {
  color: var(--semi-color-disabled-text);
  cursor: not-allowed;
}
.semi-scrolllist-body .semi-scrolllist-item-wheel {
  position: relative;
  flex: 1;
  overflow: hidden;
}
.semi-scrolllist-body .semi-scrolllist-item-wheel:not(:last-child) {
  border-right: 1px solid var(--semi-color-border);
}
.semi-scrolllist-body .semi-scrolllist-item-wheel .semi-scrolllist-item-selected {
  font-weight: 600;
  color: var(--semi-color-primary) !important;
}
.semi-scrolllist-body .semi-scrolllist-item-wheel .semi-scrolllist-list-outer {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  padding-right: 18px;
}
.semi-scrolllist-body .semi-scrolllist-item-wheel .semi-scrolllist-shade {
  width: 100%;
  height: 50%;
  pointer-events: none;
  position: absolute;
}
.semi-scrolllist-body .semi-scrolllist-item-wheel .semi-scrolllist-shade-pre {
  top: 0;
  margin-top: -19px;
  background: var(--semi-color-bg-3);
  opacity: 0.5;
}
.semi-scrolllist-body .semi-scrolllist-item-wheel .semi-scrolllist-shade-post {
  top: 50%;
  margin-top: 19px;
  background: var(--semi-color-bg-3);
  opacity: 0.5;
}
.semi-scrolllist-body .semi-scrolllist-item-wheel .semi-scrolllist-selector {
  pointer-events: none;
  position: absolute;
  top: 50%;
  border-top: 1px solid var(--semi-color-border);
  border-bottom: 1px solid var(--semi-color-border);
  height: 36px;
  width: 100%;
  transform: translateY(-50%);
}
.semi-scrolllist .semi-scrolllist-footer {
  border-top: 1px solid var(--semi-color-border);
  padding: 10px;
}
.semi-scrolllist .semi-scrolllist-line {
  background: var(--semi-color-border);
  height: 1px;
}

.semi-rtl .semi-scrolllist,
.semi-portal-rtl .semi-scrolllist {
  direction: rtl;
}
.semi-rtl .semi-scrolllist-body .semi-scrolllist-item:not(:last-child),
.semi-portal-rtl .semi-scrolllist-body .semi-scrolllist-item:not(:last-child) {
  border-right: 0;
  border-left: 1px solid var(--semi-color-border);
}
.semi-rtl .semi-scrolllist-body .semi-scrolllist-item > ul > li.semi-scrolllist-item-sel > svg,
.semi-rtl .semi-scrolllist-body .semi-scrolllist-item-wheel .semi-scrolllist-list-outer > ul > li.semi-scrolllist-item-sel > svg,
.semi-portal-rtl .semi-scrolllist-body .semi-scrolllist-item > ul > li.semi-scrolllist-item-sel > svg,
.semi-portal-rtl .semi-scrolllist-body .semi-scrolllist-item-wheel .semi-scrolllist-list-outer > ul > li.semi-scrolllist-item-sel > svg {
  margin-right: 0;
  margin-left: 12px;
}
.semi-rtl .semi-scrolllist-body .semi-scrolllist-item-wheel:not(:last-child),
.semi-portal-rtl .semi-scrolllist-body .semi-scrolllist-item-wheel:not(:last-child) {
  border-right: 0;
  border-left: 1px solid var(--semi-color-border);
}
.semi-rtl .semi-scrolllist-body .semi-scrolllist-item-wheel .semi-scrolllist-list-outer,
.semi-portal-rtl .semi-scrolllist-body .semi-scrolllist-item-wheel .semi-scrolllist-list-outer {
  padding-right: 0;
  padding-left: 18px;
}