/* shadow */
/* sizing */
/* spacing */
.semi-space {
  display: inline-flex;
}
.semi-space-vertical {
  flex-direction: column;
}
.semi-space-horizontal {
  flex-direction: row;
}
.semi-space-align-center {
  align-items: center;
}
.semi-space-align-end {
  align-items: flex-end;
}
.semi-space-align-start {
  align-items: flex-start;
}
.semi-space-align-baseline {
  align-items: baseline;
}
.semi-space-wrap {
  flex-wrap: wrap;
}
.semi-space-tight-horizontal {
  column-gap: 8px;
}
.semi-space-tight-vertical {
  row-gap: 8px;
}
.semi-space-medium-horizontal {
  column-gap: 16px;
}
.semi-space-medium-vertical {
  row-gap: 16px;
}
.semi-space-loose-horizontal {
  column-gap: 24px;
}
.semi-space-loose-vertical {
  row-gap: 24px;
}

.semi-rtl .semi-space,
.semi-portal-rtl .semi-space {
  direction: rtl;
}