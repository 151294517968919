/* shadow */
/* sizing */
/* spacing */
.semi-modal {
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  position: relative;
  margin: 80px auto;
  color: var(--semi-color-text-0);
}
.semi-modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: var(--semi-color-overlay-bg);
  height: 100%;
  z-index: 1000;
}
.semi-modal-mask-hidden {
  display: none;
}
.semi-modal-icon-wrapper {
  display: inline-flex;
  margin-right: 12px;
  width: 24px;
}
.semi-modal-wrap {
  position: fixed;
  overflow: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}
.semi-modal-wrap-center {
  display: flex;
  align-items: center;
}
.semi-modal-title {
  display: inline-flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin: 0;
}
.semi-modal-content {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  background-color: var(--semi-color-bg-2);
  border: 1px solid var(--semi-color-border);
  border-radius: var(--semi-border-radius-large);
  padding: 0 24px;
  background-clip: padding-box;
  overflow: hidden;
  box-shadow: var(--semi-shadow-elevated);
}
.semi-modal-content-fullScreen {
  border-radius: 0;
  border: none;
  top: 0px;
}
.semi-modal-header {
  display: flex;
  align-items: flex-start;
  margin: 24px 0;
  padding: 0 0;
  font-size: 14px;
  font-weight: 600;
  background-color: transparent;
  color: var(--semi-color-text-0);
  border-bottom: 0 solid transparent;
}
.semi-modal-body-wrapper {
  display: flex;
  align-items: flex-start;
  margin: 24px 0;
}
.semi-modal-body {
  flex: 1 1 auto;
  margin: 0;
  padding: 0;
}
.semi-modal-withIcon {
  margin-left: 36px;
}
.semi-modal-footer {
  margin: 24px 0;
  padding: 0 0;
  text-align: right;
  border-radius: 0 0 5px 5px;
  border-top: 0 solid transparent;
  background-color: transparent;
}
.semi-modal-footer .semi-button {
  margin-left: 12px;
  margin-right: 0;
}
.semi-modal-confirm .semi-modal-header {
  margin-bottom: 8px;
}
.semi-modal-confirm-icon-wrapper {
  display: inline-flex;
  margin-right: 12px;
  width: 24px;
}
.semi-modal-confirm-icon {
  display: inline-flex;
  color: var(--semi-color-primary);
}
.semi-modal-info-icon {
  color: var(--semi-color-info);
}
.semi-modal-success-icon {
  color: var(--semi-color-success);
}
.semi-modal-error-icon {
  color: var(--semi-color-danger);
}
.semi-modal-warning-icon {
  color: var(--semi-color-warning);
}
.semi-modal-small {
  width: 448px;
}
.semi-modal-medium {
  width: 684px;
}
.semi-modal-large {
  width: 920px;
}
.semi-modal-full-width {
  width: calc(100vw - 64px);
}

.semi-modal-centered {
  margin: 0 auto;
}

.semi-modal-popup .semi-modal-mask,
.semi-modal-popup .semi-modal-wrap {
  position: absolute;
  overflow: hidden;
}

.semi-modal-fixed .semi-modal-mask,
.semi-modal-fixed .semi-modal-wrap {
  position: fixed;
  overflow: hidden;
}

.semi-modal-displayNone {
  display: none;
}

.semi-modal-content-animate-show {
  animation: 120ms semi-modal-content-keyframe-show cubic-bezier(0.215, 0.61, 0.355, 1) 0ms forwards;
  animation-fill-mode: forwards;
}

.semi-modal-content-animate-hide {
  animation: 120ms semi-modal-content-keyframe-hide cubic-bezier(0.215, 0.61, 0.355, 1) 0ms forwards;
  animation-fill-mode: forwards;
}

.semi-modal-mask-animate-show {
  animation: 90ms semi-modal-mask-keyframe-show cubic-bezier(0.215, 0.61, 0.355, 1) 0ms forwards;
  animation-fill-mode: forwards;
}

.semi-modal-mask-animate-hide {
  animation: 90ms semi-modal-mask-keyframe-hide cubic-bezier(0.215, 0.61, 0.355, 1) 0ms forwards;
  animation-fill-mode: forwards;
}

@keyframes semi-modal-content-keyframe-show {
  0% {
    opacity: 0;
    transform: scale(0.7);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes semi-modal-content-keyframe-hide {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.7);
  }
}
@keyframes semi-modal-mask-keyframe-show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes semi-modal-mask-keyframe-hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.semi-modal-rtl {
  direction: rtl;
}
.semi-modal-rtl .semi-modal-icon-wrapper, .semi-modal-confirm-rtl .semi-modal-icon-wrapper {
  margin-right: 0;
  margin-left: 12px;
}
.semi-modal-rtl .semi-modal-withIcon, .semi-modal-confirm-rtl .semi-modal-withIcon {
  margin-left: 0;
  margin-right: 36px;
}
.semi-modal-rtl .semi-modal-footer, .semi-modal-confirm-rtl .semi-modal-footer {
  text-align: left;
}
.semi-modal-rtl .semi-modal-footer .semi-button, .semi-modal-confirm-rtl .semi-modal-footer .semi-button {
  margin-left: 0;
  margin-right: 12px;
}
.semi-modal-confirm-rtl {
  direction: rtl;
}
.semi-modal-confirm .semi-modal-confirm-rtl .semi-button {
  margin-left: 0;
  margin-right: 12px;
}