/* shadow */
/* sizing */
/* spacing */
.semi-rating {
  display: inline-block;
  margin: 0px;
  padding: 0px;
  color: rgba(var(--semi-yellow-5), 1);
  list-style: none;
  outline: none;
  border-radius: 3px;
}
.semi-rating-focus {
  outline: 2px solid var(--semi-color-primary-light-active);
}
.semi-rating-no-focus {
  outline: none;
}
.semi-rating-disabled .semi-rating-star {
  cursor: default;
}
.semi-rating-disabled .semi-rating-star:hover {
  transform: scale(1);
}
.semi-rating-star {
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 0;
  color: inherit;
  cursor: pointer;
  transition: all 0.5s;
  transform: scale(var(--semi-transform_scale-none));
}
.semi-rating-star:not(:last-child) {
  margin-right: 6px;
}
.semi-rating-star > div:hover, .semi-rating-star > div:focus {
  transform: scale(1.1);
}
.semi-rating-star > div.semi-rating-star-disabled {
  transform: none;
}
.semi-rating-star-small {
  width: 16px;
  height: 16px;
  font-size: 16px;
}
.semi-rating-star-default {
  width: 24px;
  height: 24px;
  font-size: 24px;
}
.semi-rating-star-wrapper {
  position: relative;
  overflow: hidden;
  border-radius: 3px;
  width: 100%;
  height: 100%;
}
.semi-rating-star-first, .semi-rating-star-second {
  transition: color var(--semi-transition_duration-none) var(--semi-transition_function-easeIn) var(--semi-transition_delay-none);
  color: var(--semi-color-fill-0);
  user-select: none;
}
.semi-rating-star-first {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
}
.semi-rating-star-half .semi-rating-star-first, .semi-rating-star-half .semi-rating-star-second {
  opacity: 1;
}
.semi-rating-star-half .semi-rating-star-first, .semi-rating-star-full .semi-rating-star-second {
  color: inherit;
}

.semi-rtl .semi-rating,
.semi-portal-rtl .semi-rating {
  direction: rtl;
}
.semi-rtl .semi-rating-star:not(:last-child),
.semi-portal-rtl .semi-rating-star:not(:last-child) {
  margin-right: 0;
  margin-left: 6px;
}
.semi-rtl .semi-rating-star-first,
.semi-portal-rtl .semi-rating-star-first {
  left: auto;
  right: 0;
}