/* shadow */
/* sizing */
/* spacing */
.semi-select-option {
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  word-break: break-all;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  color: var(--semi-color-text-0);
  border-radius: 0px;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  transition: background-color var(--semi-transition_duration-none) var(--semi-transition_function-easeIn) var(--semi-transition_delay-none);
}
.semi-select-option-icon {
  width: 12px;
  color: transparent;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-content: center;
}
.semi-select-option-text {
  display: flex;
  flex-wrap: wrap;
  white-space: pre;
}
.semi-select-option-keyword {
  color: var(--semi-color-primary);
  background-color: inherit;
  font-weight: 600;
}
.semi-select-option:active {
  background-color: var(--semi-color-fill-1);
}
.semi-select-option-empty {
  cursor: not-allowed;
  color: var(--semi-color-disabled-text);
  justify-content: center;
}
.semi-select-option-empty:hover {
  background-color: inherit;
}
.semi-select-option-empty:active {
  background-color: inherit;
}
.semi-select-option-disabled {
  color: var(--semi-color-disabled-text);
  cursor: not-allowed;
}
.semi-select-option-disabled:hover {
  background-color: var(--semi-color-fill-0);
}
.semi-select-option-selected {
  font-weight: 600;
}
.semi-select-option-selected .semi-select-option-icon {
  color: var(--semi-color-text-2);
}
.semi-select-option-focused {
  background-color: var(--semi-color-fill-0);
}

.semi-select {
  box-sizing: border-box;
  border-radius: var(--semi-border-radius-small);
  border: 1px solid transparent;
  height: 32px;
  font-weight: 400;
  background-color: var(--semi-color-fill-0);
  display: inline-flex;
  vertical-align: middle;
  position: relative;
  outline: none;
  cursor: pointer;
  transition: background-color var(--semi-transition_duration-none) var(--semi-transition_function-easeIn) var(--semi-transition_delay-none), border var(--semi-transition_duration-none) var(--semi-transition_function-easeIn) var(--semi-transition_delay-none);
  transform: scale(var(--semi-transform_scale-none));
  max-height: 300px;
  overflow-y: auto;
}
.semi-select:hover {
  background-color: var(--semi-color-fill-1);
  border: 1px solid transparent;
}
.semi-select:focus {
  border: 1px solid var(--semi-color-focus-border);
  background-color: var(--semi-color-fill-0);
  outline: 0;
}
.semi-select:active {
  background-color: var(--semi-color-fill-2);
}
.semi-select-small {
  height: 24px;
  line-height: 24px;
}
.semi-select-large {
  min-height: 40px;
  line-height: 40px;
}
.semi-select-large .semi-select-selection {
  font-size: 16px;
  line-height: 22px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.semi-select-open, .semi-select-focus {
  border: 1px solid var(--semi-color-focus-border);
  outline: 0;
}
.semi-select-open:hover, .semi-select-focus:hover {
  background-color: var(--semi-color-fill-0);
  border: 1px solid var(--semi-color-focus-border);
}
.semi-select-open:active, .semi-select-focus:active {
  background-color: var(--semi-color-fill-2);
  border: 1px solid var(--semi-color-focus-border);
}
.semi-select-warning {
  background-color: var(--semi-color-warning-light-default);
  border-color: var(--semi-color-warning-light-default);
}
.semi-select-warning:hover {
  background-color: var(--semi-color-warning-light-hover);
  border-color: var(--semi-color-warning-light-hover);
}
.semi-select-warning:focus {
  background-color: var(--semi-color-warning-light-default);
  border-color: var(--semi-color-warning);
}
.semi-select-warning:active {
  background-color: var(--semi-color-warning-light-active);
  border-color: var(--semi-color-warning-light-active);
}
.semi-select-error {
  background-color: var(--semi-color-danger-light-default);
  border-color: var(--semi-color-danger-light-default);
}
.semi-select-error:hover {
  background-color: var(--semi-color-danger-light-hover);
  border-color: var(--semi-color-danger-light-hover);
}
.semi-select-error:focus {
  background-color: var(--semi-color-danger-light-default);
  border-color: var(--semi-color-danger);
}
.semi-select-error:active {
  background-color: var(--semi-color-danger-light-active);
  border-color: var(--semi-color-danger-light-active);
}
.semi-select-disabled {
  cursor: not-allowed;
  background-color: var(--semi-color-disabled-fill);
}
.semi-select-disabled:hover {
  background-color: var(--semi-color-disabled-fill);
}
.semi-select-disabled:focus {
  border: 1px solid transparent;
}
.semi-select-disabled .semi-select-selection,
.semi-select-disabled .semi-select-selection-placeholder {
  color: var(--semi-color-disabled-text);
  cursor: not-allowed;
}
.semi-select-disabled .semi-select-arrow,
.semi-select-disabled .semi-select-prefix,
.semi-select-disabled .semi-select-suffix {
  color: var(--semi-color-disabled-text);
}
.semi-select-disabled .semi-tag {
  color: var(--semi-color-disabled-text);
  background-color: transparent;
}
.semi-select-selection {
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  height: 100%;
  display: flex;
  align-items: center;
  flex-grow: 1;
  overflow: hidden;
  margin-left: 12px;
  cursor: pointer;
  color: var(--semi-color-text-0);
}
.semi-select-selection-text {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.semi-select-selection-text-inactive {
  display: flex;
  opacity: 0.4;
}
.semi-select-selection-text-hide {
  display: none;
}
.semi-select-selection-placeholder {
  color: var(--semi-color-text-2);
}
.semi-select-selection .semi-tag {
  margin-top: 1px;
  margin-right: 4px;
  margin-bottom: 1px;
}
.semi-select-selection .semi-tag:nth-of-type(1) {
  margin-left: 0;
}
.semi-select-selection .semi-tag-group {
  height: inherit;
}
.semi-select-selection .semi-tag-group .semi-tag {
  margin-top: 1px;
  margin-right: 4px;
  margin-bottom: 1px;
}
.semi-select-content-wrapper {
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  align-items: center;
  height: 100%;
}
.semi-select-content-wrapper-collapse {
  display: inline-flex;
  flex-shrink: 0;
  width: 100%;
}
.semi-select-content-wrapper-collapse .semi-overflow-list-overflow {
  max-width: 100%;
  min-width: 50px;
}
.semi-select-content-wrapper-collapse > .semi-select-content-wrapper-collapse-tag {
  background-color: transparent;
}
.semi-select-content-wrapper-collapse > .semi-select-content-wrapper-collapse-N {
  background-color: transparent;
  padding: 4px;
  color: var(--semi-color-text-0);
  font-size: 12px;
}
.semi-select-multiple {
  height: auto;
}
.semi-select-multiple .semi-select-selection {
  margin-left: 4px;
}
.semi-select-multiple .semi-select-content-wrapper {
  width: 100%;
  min-height: 30px;
  flex-wrap: wrap;
}
.semi-select-multiple .semi-select-content-wrapper-empty {
  margin-left: 8px;
}
.semi-select-multiple .semi-select-content-wrapper .semi-tag-group {
  display: flex;
  align-items: center;
}
.semi-select-multiple .semi-select-content-wrapper-one-line {
  flex-wrap: nowrap;
}
.semi-select-multiple .semi-select-content-wrapper-one-line .semi-tag-group {
  flex-wrap: nowrap;
  justify-content: flex-start;
  overflow: hidden;
  flex-shrink: 0;
}
.semi-select-multiple .semi-select-inline-label-wrapper {
  flex-shrink: 0;
}
.semi-select-multiple.semi-select-large .semi-select-content-wrapper {
  min-height: 38px;
}
.semi-select-multiple.semi-select-small .semi-select-content-wrapper {
  min-height: 22px;
}
.semi-select-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  color: var(--semi-color-text-2);
  flex-shrink: 0;
  transform: rotate(var(--semi-transform-rotate-none));
}
.semi-select-arrow-empty {
  display: flex;
  width: 12px;
}
.semi-select-prefix, .semi-select-suffix {
  display: flex;
  justify-content: center;
  align-items: center;
}
.semi-select-prefix-text, .semi-select-suffix-text {
  margin: 0px 12px;
}
.semi-select-prefix-icon, .semi-select-suffix-icon {
  color: var(--semi-color-text-2);
  margin: 0px 8px;
}
.semi-select-suffix {
  display: flex;
  justify-content: center;
  align-items: center;
}
.semi-select-clear {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  color: var(--semi-color-text-2);
  flex-shrink: 0;
}
.semi-select-clear:hover {
  color: var(--semi-color-primary);
}
.semi-select-inset-label-wrapper {
  display: inline;
}
.semi-select-inset-label {
  margin-right: 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: var(--semi-color-text-2);
  flex-shrink: 0;
  white-space: nowrap;
}
.semi-select-create-tips {
  color: var(--semi-color-text-2);
  margin-right: 4px;
}

.semi-select-with-prefix .semi-select-selection {
  margin-left: 0;
}

.semi-select-single.semi-select-filterable .semi-select-content-wrapper {
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.semi-select-single.semi-select-filterable .semi-input-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border: none;
  background-color: transparent;
}
.semi-select-single.semi-select-filterable .semi-input-wrapper-focus {
  border: none;
}
.semi-select-single.semi-select-filterable .semi-input {
  padding-left: 0;
  padding-right: 0;
  height: 100%;
}

.semi-select-multiple.semi-select-filterable .semi-select-content-wrapper {
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.semi-select-multiple.semi-select-filterable .semi-select-content-wrapper-empty .semi-input-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.semi-select-multiple.semi-select-filterable .semi-input-wrapper {
  height: 100%;
  width: 100%;
  border: none;
  background-color: transparent;
}
.semi-select-multiple.semi-select-filterable .semi-input-wrapper-focus {
  border: none;
}
.semi-select-multiple.semi-select-filterable .semi-input {
  padding-left: 0;
  padding-right: 0;
}

.semi-select-option-list-wrapper {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 0px;
  padding-right: 0px;
}

.semi-select-option-list {
  overflow-x: hidden;
  overflow-y: auto;
}
.semi-select-option-list-chosen .semi-select-option-icon {
  display: flex;
}

.semi-select-group {
  color: var(--semi-color-text-2);
  padding-top: 12px;
  margin-top: 4px;
  padding-bottom: 4px;
  padding-left: 32px;
  padding-right: 16px;
  font-size: 12px;
  line-height: 16px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  cursor: default;
}
.semi-select-group:not(:nth-of-type(1)) {
  border-top: 1px solid var(--semi-color-border);
}

.semi-select-loading-wrapper {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: not-allowed;
  height: 20px;
}

.semi-select-borderless:not(:focus-within):not(:hover) {
  background-color: transparent;
  border-color: transparent;
}
.semi-select-borderless:not(:focus-within):not(:hover) .semi-select-arrow {
  opacity: 0;
}
.semi-select-borderless:focus-within:not(:active) {
  background-color: transparent;
}
.semi-select-borderless.semi-select-error:not(:focus-within) {
  border-color: var(--semi-color-danger);
}
.semi-select-borderless.semi-select-warning:not(:focus-within) {
  border-color: var(--semi-color-warning);
}
.semi-select-borderless.semi-select-error:focus-within {
  border-color: var(--semi-color-danger);
}
.semi-select-borderless.semi-select-warning:focus-within {
  border-color: var(--semi-color-warning);
}

.semi-rtl .semi-select,
.semi-portal-rtl .semi-select {
  direction: rtl;
}
.semi-rtl .semi-select-selection,
.semi-portal-rtl .semi-select-selection {
  margin-left: 0;
  margin-right: 12px;
}
.semi-rtl .semi-select-selection .semi-tag:nth-of-type(1),
.semi-portal-rtl .semi-select-selection .semi-tag:nth-of-type(1) {
  margin-right: 0;
}
.semi-rtl .semi-select-selection .semi-tag-group .semi-tag,
.semi-portal-rtl .semi-select-selection .semi-tag-group .semi-tag {
  margin-left: 4px;
  margin-right: 0;
}
.semi-rtl .semi-select-multiple .semi-select-selection,
.semi-portal-rtl .semi-select-multiple .semi-select-selection {
  margin-left: 0;
  margin-right: 4px;
}
.semi-rtl .semi-select-multiple .semi-select-content-wrapper-empty,
.semi-portal-rtl .semi-select-multiple .semi-select-content-wrapper-empty {
  margin-left: 0;
  margin-right: 8px;
}
.semi-rtl .semi-select-inset-label,
.semi-portal-rtl .semi-select-inset-label {
  margin-left: 12px;
}
.semi-rtl .semi-select-create-tips,
.semi-portal-rtl .semi-select-create-tips {
  margin-right: 0;
  margin-left: 4px;
}
.semi-rtl .semi-select-with-prefix .semi-select-selection,
.semi-portal-rtl .semi-select-with-prefix .semi-select-selection {
  margin-left: auto;
  margin-right: 0;
}
.semi-rtl .semi-select-single.semi-select-filterable .semi-input-wrapper,
.semi-portal-rtl .semi-select-single.semi-select-filterable .semi-input-wrapper {
  left: auto;
  right: 0;
}
.semi-rtl .semi-select-multiple.semi-select-filterable .semi-select-content-wrapper-empty .semi-input-wrapper,
.semi-portal-rtl .semi-select-multiple.semi-select-filterable .semi-select-content-wrapper-empty .semi-input-wrapper {
  left: auto;
  right: 0;
}
.semi-rtl .semi-select-group,
.semi-portal-rtl .semi-select-group {
  padding-left: 32px;
  padding-right: 16px;
}
.semi-rtl .semi-select-option-icon,
.semi-portal-rtl .semi-select-option-icon {
  margin-right: 0;
  margin-left: 8px;
}