/* shadow */
/* sizing */
/* spacing */
.semi-tagInput {
  background-color: var(--semi-color-fill-0);
  border: 1px transparent solid;
  border-radius: var(--semi-border-radius-small);
  display: inline-flex;
  font-weight: 400;
  width: 100%;
  min-height: 32px;
  box-sizing: border-box;
  transition: background-color var(--semi-transition_duration-none) var(--semi-transition_function-easeIn) var(--semi-transition_delay-none), border var(--semi-transition_duration-none) var(--semi-transition_function-easeIn) var(--semi-transition_delay-none);
}
.semi-tagInput-small {
  min-height: 24px;
}
.semi-tagInput-large {
  min-height: 40px;
}
.semi-tagInput-drag-item {
  display: flex;
  align-items: center;
}
.semi-tagInput-drag-item-move {
  z-index: 2000;
}
.semi-tagInput-drag-handler {
  color: var(--semi-color-text-2);
  margin-right: 4px;
  cursor: move;
}
.semi-tagInput-hover {
  background-color: var(--semi-color-fill-1);
  border: 1px transparent solid;
}
.semi-tagInput-focus {
  background-color: var(--semi-color-fill-0);
  border: 1px var(--semi-color-focus-border) solid;
}
.semi-tagInput-warning {
  background-color: var(--semi-color-warning-light-default);
  border-color: var(--semi-color-warning-light-default);
}
.semi-tagInput-warning:hover {
  background-color: var(--semi-color-warning-light-hover);
  border-color: var(--semi-color-warning-light-hover);
}
.semi-tagInput-warning.semi-tagInput-focus {
  background-color: var(--semi-color-warning-light-default);
  border-color: var(--semi-color-warning);
}
.semi-tagInput-error {
  background-color: var(--semi-color-danger-light-default);
  border-color: var(--semi-color-danger-light-default);
}
.semi-tagInput-error:hover {
  background-color: var(--semi-color-danger-light-hover);
  border-color: var(--semi-color-danger-light-hover);
}
.semi-tagInput-error.semi-tagInput-focus {
  background-color: var(--semi-color-danger-light-default);
  border-color: var(--semi-color-danger);
}
.semi-tagInput-disabled {
  cursor: not-allowed;
}
.semi-tagInput-disabled .semi-tagInput-clearBtn,
.semi-tagInput-disabled .semi-tagInput-prefix,
.semi-tagInput-disabled .semi-tagInput-suffix {
  color: var(--semi-color-disabled-text);
}
.semi-tagInput-disabled .semi-tagInput-wrapper {
  cursor: not-allowed;
}
.semi-tagInput-disabled .semi-tagInput-wrapper-tag {
  color: var(--semi-color-disabled-text);
  background-color: transparent;
}
.semi-tagInput-disabled .semi-tagInput-wrapper-input {
  cursor: not-allowed;
}
.semi-tagInput-disabled .semi-tagInput-wrapper-input::placeholder {
  color: var(--semi-color-disabled-text);
}
.semi-tagInput-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  align-items: center;
  padding-left: 4px;
  padding-right: 4px;
  overflow: hidden;
}
.semi-tagInput-wrapper-tag {
  margin-right: 4px;
  white-space: pre;
}
.semi-tagInput-wrapper-tag-size-small {
  margin-top: 1px;
  margin-bottom: 1px;
}
.semi-tagInput-wrapper-tag-size-default {
  margin-top: 2px;
  margin-bottom: 2px;
}
.semi-tagInput-wrapper-tag-size-large {
  margin-top: 3px;
  margin-bottom: 3px;
}
.semi-tagInput-wrapper-tag-icon {
  padding-left: 4px;
}
.semi-tagInput-wrapper-typo {
  font-size: 12px;
}
.semi-tagInput-wrapper-typo-disabled {
  color: var(--semi-color-disabled-text);
}
.semi-tagInput-wrapper-n {
  cursor: pointer;
  font-size: 12px;
  margin-right: 4px;
  color: var(--semi-color-text-0);
  padding-left: 8px;
  padding-right: 8px;
}
.semi-tagInput-wrapper-n-disabled {
  cursor: not-allowed;
  color: var(--semi-color-disabled-text);
}
.semi-tagInput-wrapper .semi-tagInput-wrapper-input {
  flex-grow: 1;
  width: min-content;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 14px;
}
.semi-tagInput-wrapper .semi-tagInput-wrapper-input:hover {
  background-color: transparent;
}
.semi-tagInput-wrapper .semi-tagInput-wrapper-input:not(:first-child) > input {
  padding-left: 0;
}
.semi-tagInput-wrapper .semi-tagInput-wrapper-input-small {
  height: 20px;
  margin-top: 1px;
  margin-bottom: 1px;
  line-height: 20px;
}
.semi-tagInput-wrapper .semi-tagInput-wrapper-input-small .semi-input-small {
  height: 20px;
  line-height: 20px;
}
.semi-tagInput-wrapper .semi-tagInput-wrapper-input-default {
  height: 24px;
  margin-top: 2px;
  margin-bottom: 2px;
  line-height: 24px;
}
.semi-tagInput-wrapper .semi-tagInput-wrapper-input-default .semi-input-default {
  height: 24px;
  line-height: 24px;
}
.semi-tagInput-wrapper .semi-tagInput-wrapper-input-large {
  height: 24px;
  margin-top: 3px;
  margin-bottom: 3px;
  line-height: 24px;
}
.semi-tagInput-wrapper .semi-tagInput-wrapper-input-large .semi-input-large {
  height: 24px;
  line-height: 24px;
}
.semi-tagInput-clearBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  flex-shrink: 0;
  color: var(--semi-color-text-2);
}
.semi-tagInput-clearBtn:hover {
  cursor: pointer;
  color: var(--semi-color-primary-hover);
}
.semi-tagInput-clearBtn-invisible {
  visibility: hidden;
}
.semi-tagInput-prefix, .semi-tagInput-suffix {
  display: flex;
  justify-content: center;
  align-items: center;
}
.semi-tagInput-prefix-text, .semi-tagInput-suffix-text {
  margin: 0 12px;
  font-weight: 600;
  white-space: nowrap;
}
.semi-tagInput-prefix-icon, .semi-tagInput-suffix-icon {
  color: var(--semi-color-text-2);
  margin: 0 8px;
}
.semi-tagInput-prefix-text {
  color: var(--semi-color-text-2);
}
.semi-tagInput-suffix-text {
  color: var(--semi-color-text-2);
}
.semi-tagInput-tag-content-wrapper {
  display: flex;
  align-items: center;
}
.semi-tagInput-sortable-list {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}

.semi-rtl .semi-tagInput,
.semi-portal-rtl .semi-tagInput {
  direction: rtl;
}
.semi-rtl .semi-tagInput-wrapper-tag,
.semi-portal-rtl .semi-tagInput-wrapper-tag {
  margin-left: 4px;
  margin-right: 0;
}
.semi-rtl .semi-tagInput-wrapper-input:not(:first-child) > input,
.semi-portal-rtl .semi-tagInput-wrapper-input:not(:first-child) > input {
  padding-right: 0;
}